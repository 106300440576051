import Environment from '../../constants/environment';
import React, { forwardRef } from "react";
import Reflux from "reflux";
import axios from 'axios';
import memoize from 'memoize-one';
import client from "../../api/client";
import { validateSpanishId, spainIdType } from 'spain-id';
import {
    Card, Container, Row, CardTitle, CardBody, Label,
    FormGroup,
    Input, FormText,
    Form, Col, Button,
    UncontrolledTooltip, CardHeader, Collapse
} from "reactstrap";
import {
    Link,
    useRouteMatch
} from 'react-router-dom';
import { CSVLink, CSVDownload } from "react-csv";

// core components
import Header from "../../components/Headers/Header";

import { withApollo } from "react-apollo";
import moment from "moment";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReactBSAlert from "react-bootstrap-sweetalert";

import GetUserData from "../../components/User/GetUserData";
import { UserStore } from "../../store/UserStore";
import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

import CircularProgress from '@material-ui/core/CircularProgress';
import { saveAs } from 'file-saver';
import SipsOption from "../../components/Sips/SipsOption";
import SipsGasOption from "../../components/SipsGas/SipsGasOption";
import { LIST_DICMUNICIPIOS } from "../../queries/options/dic-option.graphql";

const ibantools = require('ibantools');

var DatePicker = require("reactstrap-date-picker");

const FileDownload = require('js-file-download');
const formato = require('xml-formatter');
const spanishDayLabels = ['Dom', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sab'];
const spanishMonthLabels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

const notify = () => toast.success(
    "Producto actualizado correctamente",
    {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
const notifyError = () => toast.error(
    "Solo puedes escoger un cliente",
    {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
const notifyErrorRep = () => toast.error(
    "Debes completar los datos del cliente",
    {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
const notifyErrorDB = () => toast.error(
    "Se ha producido un error en la BBDD. Si persite ponte en contacto con soporte",
    {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

const sortIcon = <ArrowDownward />;

const columnsDatosClientes = [

    { selector: row => row.datosResumen.fecinicioperiodo, name: 'FECHA DESDE', sortable: true, width: '180px', center: true, format: (row => capitalize(row.datosResumen.fecinicioperiodo)) },
    { selector: row => row.datosResumen.fecfinperiodo, name: 'FECHA HASTA', sortable: true, width: '180px', center: true, format: (row => capitalize(row.datosResumen.fecfinperiodo)) },
    { selector: row => row.uuid, name: 'ID REMESA', sortable: true, width: '140px', format: (row => capitalize(row.uuid)) },

    {
        name: 'ENV. ERP',
        center: true, width: '100px',
        cell: row => estado(row.procesoFactura)

    },
    { selector: row => row.datosResumen.importeTotalFactura, name: 'IMPORTE', sortable: true, width: '140px', center: true, format: (row => Number(row.datosResumen.importeTotalFactura).toLocaleString("es-ES", {maximumFractionDigits: 2})) },
    { selector: row => row.cups, name: 'CUPS', sortable: true, width: '220px', format: (row => capitalize(row.cups)) },
    { selector: row => row.apellido1, name: 'APELLIDO/RAZON SOCIAL', sortable: true, width: '240px', center: true, format: (row => capitalize(row.apellido1)) },
    { selector: row => row.numdocumento, name: 'CIF', sortable: true, width: '140px', format: (row => capitalize(row.numdocumento)) },
    { selector: row => row.nombreEmpresaEmisora, name: 'DISTRIBUIDORA', sortable: true, width: '240px', center: true, format: (row => capitalize(row.nombreEmpresaEmisora)) },
];



const columnsDatosConceptosClientes = [

    { selector: row => row.codconcepto, name: 'COD. CONCEPTO', sortable: true, width: '140px', format: (row => capitalize(row.codconcepto)) },
    { selector: row => row.desconcepto, name: 'CONCEPTO', sortable: true, width: '260px', center: true, format: (row => capitalize(row.desconcepto)) },
    { selector: row => row.fecdesde, name: 'FECHA DESDE', sortable: true, width: '180px', center: true, format: (row => capitalize(row.fecdesde)) },
    { selector: row => row.fechasta, name: 'FECHA HASTA', sortable: true, width: '180px', center: true, format: (row => capitalize(row.fechasta)) },
    { selector: row => row.importe, name: 'IMPORTE', sortable: true, width: '220px', format: (row => capitalize(row.importe)) },

];

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}

const estado = (s) => {
    if (s !== true) return <i className="fas fa-exclamation-circle fa-2x" style={{ color: "#ff1900" }} ></i>
    return <i className="far fa-check-circle fa-2x" style={{ color: "#7ee06e" }} ></i>
}


const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};


const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>
);





class facturasCliente extends Reflux.Component<any, any> {
    public maxFileSize = 3;
    public fileInput: any = null;
    public lecturasRef: any = [];
    public inputEl: any = null;
    public formRef: any = null;
    public validFileExtensions = ["jpg", "jpeg", "png"];
    lastFilters = {};
    state = {
        filters: {
            razonSocial: "",
            remesaSearch: "",
            cifClienteSearch: "",
            cifEmisorSearch: "",
            tipoEstadoSearch: "",
            facturaDesde: "",
            facturaHasta: ""
        },
        UserStore: {},
        nombreFichero: "",
        isLoading: false,
        copiedText: null,
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        contrato: "",
        tipocliente: "",
        facturaDetailTotal: [],
        facturaSeleccionada: false,
        idCliente: '',
        facturaDetail: {
            cups: "",
            codigoEmpresaEmisora: "",
            nombreEmpresaEmisora: "",
            fechacomunic: null,
            numdocumento: "",
            nombre: "",
            apellido1: "",
            tipofactura: "",
            clasefact: "",
            fecfactura: null,
            numfactura: "",
            tipofacturacion: "",
            tipopeaje: "",
            importetotal: null,
            tipopenalizacion: "",
            metodofacturacion: "",
            psrltvimporte: null,
            psrltvcodconcepto: "",
            psrltvdesconcepto: "",
            psrltfimporte: null,
            psrltfcodconcepto: "",
            psrltfdesconcepto: "",
            psrttvimporte: null,
            psrttvcodconcepto: "",
            psrttvdesconcepto: "",
            psrttfimporte: null,
            psrttfcodconcepto: "",
            psrttfdesconcepto: "",
            ocrtfimporte: null,
            ocrtfcodconcepto: "",
            ocrtfdesconcepto: "",
            ctfimporte: null,
            ctfcodconcepto: "",
            ctfdesconcepto: "",
            cgtsimporte: null,
            cgtscodconcepto: "",
            cgtsdesconcepto: "",
            tcnmcimporte: null,
            tcnmccodconcepto: "",
            tcnmcdesconcepto: "",
            ifaimporte: null,
            ifacodconcepto: "",
            ifadesconcepto: "",
            ibiimporte: null,
            ibicodconcepto: "",
            ibidesconcepto: "",
            iiimporte: null,
            iicodconcepto: "",
            iidesconcepto: "",
            itfimporte: null,
            itfcodconcepto: "",
            itfdesconcepto: "",
            tfpimporte: null,
            tfpcodconcepto: "",
            tfpdesconcepto: "",
            tvpimporte: null,
            tvpcodconcepto: "",
            tvpdesconcepto: "",
            ipimporte: null,
            ipicodconcepto: "",
            ipidesconcepto: "",
            procesoFactura: false,
            uuidFactura: "",
            fechAlta: null,
            factork: null,
            consumo: null,
            fecinicioperiodo: null,
            fecfinperiodo: null,
            consumoperiodo: null,
            uuid: "",
            conceptos:[{
                    codconcepto:"",
                    desconcepto:"",
                    fecdesde:"",
                    fechasta:"",
                    importe:""}
            ],
            datosResumen:{
                ptvcmibgas:"",
                ptvcfactork:"",
                consumo:"",
                consumokwh:""

                    }

        },
        formFactura: {
            cups: "",
            codigoEmpresaEmisora: "",
            nombreEmpresaEmisora: "",
            fechacomunic: null,
            numdocumento: "",
            nombre: "",
            apellido1: "",
            tipofactura: "",
            clasefact: "",
            fecfactura: null,
            numfactura: "",
            tipofacturacion: "",
            tipopeaje: "",
            importetotal: null,
            tipopenalizacion: "",
            metodofacturacion: "",
            psrltvimporte: null,
            psrltvcodconcepto: "",
            psrltvdesconcepto: "",
            psrltfimporte: null,
            psrltfcodconcepto: "",
            psrltfdesconcepto: "",
            psrttvimporte: null,
            psrttvcodconcepto: "",
            psrttvdesconcepto: "",
            psrttfimporte: null,
            psrttfcodconcepto: "",
            psrttfdesconcepto: "",
            ocrtfimporte: null,
            ocrtfcodconcepto: "",
            ocrtfdesconcepto: "",
            ctfimporte: null,
            ctfcodconcepto: "",
            ctfdesconcepto: "",
            cgtsimporte: null,
            cgtscodconcepto: "",
            cgtsdesconcepto: "",
            tcnmcimporte: null,
            tcnmccodconcepto: "",
            tcnmcdesconcepto: "",
            ifaimporte: null,
            ifacodconcepto: "",
            ifadesconcepto: "",
            ibiimporte: null,
            ibicodconcepto: "",
            ibidesconcepto: "",
            iiimporte: null,
            iicodconcepto: "",
            iidesconcepto: "",
            itfimporte: null,
            itfcodconcepto: "",
            itfdesconcepto: "",
            tfpimporte: null,
            tfpcodconcepto: "",
            tfpdesconcepto: "",
            tvpimporte: null,
            tvpcodconcepto: "",
            tvpdesconcepto: "",
            ipimporte: null,
            ipicodconcepto: "",
            ipidesconcepto: "",
            procesoFactura: false,
            uuidFactura: "",
            fechAlta: null,
            factork: null,
            consumo: null,
            fecinicioperiodo: null,
            fecfinperiodo: null,
            consumoperiodo: null,
            datosResumen:{
                ptvcmibgas:"",
                ptvcfactork:"",
                consumo:"",
                consumokwh:""

                    }

        },

        companyIdUser: '',
        alert: null,
        companyNameGlobal: '',
        userGroupId: 1000,
        userId: '',
        idCard: "",
        esAgencia: false,
        esComercializadora: false,
        esAdFincas: false,
        saveDataCliente: false,
        updateDataCliente: false,
        saveClienteButtonDisabled: true,
        updateClienteButtonDisabled: true,
        saveXMLButtonDisabled: true,
        buscarButtonDisabled: true,
        CIFClienteError: false,
        show: false,
        imgCollection: [],
        facturaButtonDisabled: true,
        showFilters: true,
        toggledClearRows: false,
        cupsError: false,
        cifError: false,
        cifErrorEmisor: false,
        locale: 'es',
        inputIdDisabled: true,
        inputDisabled: true,
        facturaDesde: '',
        facturaHasta: '',
        clienteHasta: '',
        createdAt: '',
        updatedAt: '',
        periodoValidezHasta: '',
        periodoValidezDesde: '',
        CodigoPostalTitularError: false,
        apellido1TitularError: false,
        emailError: false,
        nombreTitularError: false,
        NIFError: false,
        DireccionFiscalError: false,
        TelefonoFijoError: false,
        TelefonMovilError: false,
        dicMunicipios: [],
        cnaeAux: "",
        ibanError: false

    };

    sipsCSVData: any = [];
    viewSipsConsumoAnualCSVData: any = [];
    sipsConsumosCSVData: any = [];
    sipstotal: any = [];





    constructor(props) {
        super(props);
        this.store = UserStore;
        this.formRef = React.createRef();

    }


    componentWillReceiveProps() {

        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        console.log('VALORES DE USUARIO EN componentWillReceiveProps')
        console.log(usuario)

        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;

        const userGroupId = usuario.user_group_id
        const userId = usuario.id
        const esComercializadora = usuario.comercializadora
        const esAgencia = usuario.agencia
        const esAdFincas = usuario.adFincas
        const idCard = usuario.email



        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };

        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId,
            esComercializadora,
            esAgencia,
            esAdFincas,
            idCard
        });
    }



    componentDidMount() {
        this.fetchItems();
    }


    fetchItems = async () => {

        await client.query({
            query: LIST_DICMUNICIPIOS,
            variables: {}
        })
            .then((result) => {
                let data = result.data.listDicMunicipios;
                this.setState({ dicMunicipios: data })
            });
    }


    onChangeFieldSearch = (event) => {
        event.persist()
        var filtersData = { ...this.state.filters };
        const name = event.target.name;
        let value = event.target.value;
        const filters = this.state.filters;
        console.log(event)
        console.log(name)
        console.log(value)



        var filtersData = { ...this.state.filters };
        filters[name] = value;
        /*if (event.target.type === "select-one" && value === "0") {
           delete filters[name];
         }

         if (value === "" || value === null) {
           delete filters[name];
         }
*/


        if (event.target.name === 'razonSocial' && event.target.value.length > 0) {
            console.log('entra en razon social')

            this.setState({
                buscarButtonDisabled: false

            })

        }

        if (event.target.name === 'remesaSearch' && event.target.value.length > 0) {

            this.setState({
                buscarButtonDisabled: false

            })

        }


        if (event.target.name === 'cifClienteSearch' && event.target.value.length == 0) {

            this.setState({

                cifError: false,
                buscarButtonDisabled: true,
            })
        } else if (event.target.name === 'cifClienteSearch' && event.target.value.length > 0) {

            const result = validateSpanishId(event.target.value)

            if (result === true) {
                this.setState({
                    cifError: false,
                    buscarButtonDisabled: false,
                })

            } else {
                this.setState({
                    cifError: true,
                    buscarButtonDisabled: true,
                })
            }
        }


        if (event.target.name === 'cifEmisorSearch' && event.target.value.length == 0) {

            this.setState({

                cifErrorEmision: false,
                buscarButtonDisabled: true,
            })
        } else if (event.target.name === 'cifEmisorSearch' && event.target.value.length > 0) {
            console.log('entra en if....')

            const result = validateSpanishId(event.target.value)
            console.log('valor de result en if ', result)
            if (result === true) {
                console.log('1')
                this.setState({
                    cifErrorEmisor: false,
                    buscarButtonDisabled: false,
                })

            } else {
                console.log('2')
                this.setState({
                    cifErrorEmisor: true,
                    buscarButtonDisabled: true,
                })
            }
        }



        if (event.target.name === 'tipoEstadoSearch' && event.target.value == 'Selecciona...') {
            value = null


        } else if (event.target.name === 'tipoEstadoSearch' && event.target.value != 'Selecciona...') {
            this.setState({
                buscarButtonDisabled: true,
            })

        }

        filtersData[name] = value;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ filters: filtersData });
    }



    onChangeFieldFactura = (event) => {
        /*event.persist()

        var formData = { ...this.state.formFactura };
        var target = event.target;
        var value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        const name = event.target.name;
        console.log('Valor de event name: ', event.target.name)
        console.log('Valoe de event value: ', event.target.value)

        const nameFilters = ['idCliente', 'idTipoTitular', 'descIdTipoTitular', 'nombreTitular', 'apellido1Titular', 'apellido2Titular', 'codigoProvinciaTitular', 'desProvinciaTitular', 'codigoPostalTitular', 'municipioTitular', 'desMunicipioTitular', 'tipoViaTitular', 'viaTitular', 'numFincaTitular', 'portalTitular', 'escaleraTitular', 'pisoTitular', 'puertaTitular', 'esViviendaHabitual', 'CNAE', 'actividadCNAE', 'CIF', 'codigoAutonomiaPS', 'desAutonomiaPS', 'idBanco', 'descBanco', 'iban', 'createdAt', 'updatedAt', 'createdBy', 'updatedBy', 'idCompany', 'correoElectronico', 'telefonoFijo', 'telefonoMovil'];



        if (this.state.facturaSeleccionada) {

            if (nameFilters.indexOf(event.target.name) != -1) {
                this.setState({ updateDataCliente: true });
                this.setState({
                    saveClienteButtonDisabled: true,
                    updateClienteButtonDisabled: false
                })
            }
            if(event.target.name =='nombreTitular' && event.target.value.length > 0 ){

                this.setState ({updateDataCliente : true});
                this.setState({saveClienteButtonDisabled:true,
                              updateClienteButtonDisabled:false})
            }
            if(event.target.name =='apellido1Titular' && event.target.value.length > 0 ){

                this.setState ({updateDataCliente : true});
                this.setState({saveClienteButtonDisabled:true,
                              updateClienteButtonDisabled:false})
            }
            if(event.target.name =='nombreTitular' && event.target.value.length > 0 ){
                //console.log('VALOR NOMBRE REPRESENTANTE ------> ', value, event.target.name)
                //this.setState ({representanteDetail.nombreRepresentante : event.target.value});
                this.setState ({updateDataCliente : true});
                this.setState({saveClienteButtonDisabled:true,
                              updateClienteButtonDisabled:false})
            }

            if (event.target.name === 'CIF' && event.target.value.length == 0)   {
                this.setState({CIFClienteError: true})
                } else if(event.target.name === 'CIF' && event.target.value.length > 0){
                    const result = validateSpanishId(event.target.value)
                        if (result === true) {
                            this.setState ({updateDataCliente : true,
                                            CIFClienteError: false});
                            this.setState({saveClienteButtonDisabled:true,
                                          updateClienteButtonDisabled:false})
                            const result = spainIdType(event.target.value)
                            formData['descIdTipoTitular'] = result.toUpperCase();
                            this.setState({ formFactura: formData });
                        } else {
                            this.setState({
                                CIFClienteError: true,
                                updateDataCliente : false,
                                bupdateClienteButtonDisabled:true
                             })
                        }
                }


                    if (event.target.name === 'correoElectronico') {


                      const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
                      //const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
                      const result = pattern.test(event.target.value);

                      if (result === true) {
                        this.setState({
                          emailError: false,
                        })
                      } else {
                        this.setState({
                          emailError: true
                        })
                      }
                    }

                    if (event.target.name === 'iban') {

                      const resultIban = ibantools.isValidIBAN(event.target.value);

                      if (resultIban === true) {
                        this.setState({
                          ibanError: false,
                        })
                      } else {
                        this.setState({
                          ibanError: true
                        })
                      }
                    }

                    if (name == 'desMunicipioTitular') {

                      const municipio: any = this.state.dicMunicipios
                      const resultado = municipio.find(municipio => municipio?.Nombre === value);

                      //console.log('VALOR DE FIND -------------------->', resultado.DescProvincia, resultado.DescAutonomia);

                      //const filtered = municipio.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.includes('Barromán')));

                      //console.log(filtered);
                      formData['desProvinciaTitular'] = resultado.DescProvincia
                      formData['desAutonomiaPS'] = resultado.DescAutonomia

                    }


                    if (name == 'nombreMunicipioPS') {
                      const municipio:any  = this.state.dicMunicipios
                      const resultado = municipio.find(municipio => municipio?.Nombre === value);
                      const filtered = municipio.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.includes('Barromán')));
                      formData['nombreProvinciaPS'] = resultado.DescProvincia
                      formData['desAutonomiaPS'] = resultado.DescAutonomia
                    }



            if (event.target.name == 'CNAE') {
                //this.setState ({facturaDetail.nombreOferta : event.target.value});
                this.setState({ updateDataCliente: true });
                this.setState({
                    saveClienteButtonDisabled: true,
                    updateClienteButtonDisabled: false
                })
                const descripcionCNAE = event.target.value
                console.log('valores de target.selectedOptions ----- ',event.target.selectedOptions[0] )
                console.log('valores de target.selectedOptions ----- ',event.target.selectedOptions[0].innerText )
                value = event.target.selectedOptions[0].innerText
                console.log(formData)
                formData['actividadCNAE'] = descripcionCNAE;
                //if (value === "" || value === null) {
                //    formData[name] = 0;
                //}
                this.setState({ formFactura: formData,
                                cnaeAux: descripcionCNAE });
            }

            if (event.target.name === 'esEmitida' && event.target.value == 'Selecciona...') {
                value = null

            }

        }

        formData[name] = value;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ formFactura: formData });
*/

    };


    showFiltersToggle = () => {
        const showFilters = !this.state.showFilters;

        this.setState({ showFilters });
    };

    getFilterIcon = () => {
        return this.state.showFilters ? "ni-bold-up" : "ni-bold-down";
    };



    clearForm = () => {
        const filters = {};
        this.setState({
            filters: {
                remesaSearch: '',
                cifClienteSearch: '',
                idSearch: ''
            },
            inputIdDisabled: false,
            inputDisabled: false,
            buscarButtonDisabled: true,
            facturaSeleccionada: false,
            facturaDetailTotal: [],
            formFactura: {
                CIF: "",
                CNAE: "",
                actividadCNAE: "",
                apellido1Titular: "",
                apellido2Titular: "",
                codigoAutonomiaPS: "",
                codigoPostalTitular: "",
                codigoProvinciaTitular: "",
                correoElectronico: "",
                createdAt: null,
                createdBy: "",
                desAutonomiaPS: "",
                desMunicipioTitular: "",
                desProvinciaTitular: "",
                descBanco: "",
                descIdTipoTitular: "",
                esEmitida: true,
                esViviendaHabitual: "",
                escaleraTitular: "",
                iban: "",
                idBanco: "",
                idCliente: "",
                idCompany: 0,
                idTipoTitular: "",
                municipioTitular: "",
                nombreTitular: "",
                numFincaTitular: "",
                pisoTitular: "",
                portalTitular: "",
                puertaTitular: "",
                telefonoFijo: "",
                telefonoMovil: "",
                tipoViaTitular: "",
                updatedAt: null,
                updatedBy: "",
                viaTitular: ""
            },
            toggledClearRows: !this.state.toggledClearRows,
            saveClienteButtonDisabled: true,
            updateClienteButtonDisabled: true,
            createdAt: '',
            updatedAt: '',
            inputDisabledP3456: false,
            inputDisabledE456: false
        });

        this.formRef.current.reset();

    };


    buscarFacturas = async () => {

        console.log('VALOR DE this.state.companyIdUser', this.state)


        var facturaDesdeFormated = ""
        var facturaHastaFormated = ""

        if(this.state.filters.facturaDesde){
            facturaDesdeFormated = this.state.filters.facturaDesde.split("-").reverse().join("-");
        }

        if(this.state.filters.facturaDesde && !this.state.filters.facturaHasta ){

            let today = new Date();
            facturaHastaFormated = today.toISOString().split('T')[0];

        } else if ( this.state.filters.facturaDesde && this.state.filters.facturaHasta ){

        facturaHastaFormated = this.state.filters.facturaHasta.split("-").reverse().join("-");

            }


        var dataFilters = {
            'companyId': this.state.companyIdUser,
            'apellido1': this.state.filters.razonSocial.toUpperCase(),
            'uuid': this.state.filters.remesaSearch.toUpperCase(),
            'numdocumento': this.state.filters.cifClienteSearch.toUpperCase(),
            'numdocumentoEmpresaEmisora': this.state.filters.cifEmisorSearch.toUpperCase(),
            'facturadesde': facturaDesdeFormated,
            'itffechasta': facturaHastaFormated,
            'procesoFactura': this.state.filters.tipoEstadoSearch
        };

        const valores = JSON.stringify(dataFilters)
        console.log('VALOR DE dataFilters: ', dataFilters)
        console.log('VALOR DE dataFilters: ', valores)

        var idCard = this.state.idCard;
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };
        let direccionFile = "https://datapi.psgestion.es/facturas/emitidas/listadoadministrador?id=" + this.state.companyIdUser+ "&idcard=" + idCard;

        axios.get(direccionFile, { headers, params: { valores } }).
            then(result => {
                console.log('resultado del listados')
                console.log(result)
                if(result.data.rows.length>0){
                this.setState({ facturaDetailTotal: result.data.rows })
            } else {
            this.warningAlertFacturas()
            };

            }).catch(function() {
                console.log('Failure')
                notifyErrorDB();
            });

    };





    showTable = () => {
        if (this.state.show === false) {
            this.setState({
                show: true
            });

            const reftp = document.getElementById("tablaLecturas")
            console.log('VALORES DE REFTP', reftp)
            reftp ?.scrollIntoView({ behavior: "smooth" })

} else {

            this.setState({
                show: false
            });
        }
    }

    warningAlertFacturas = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="No existen registros que cumplan los requisitos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los requisitos introducidos
     </ReactBSAlert>
            )
        });
    };

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    updateHandler(props) {

        if (this.state.updateDataCliente && this.state.formFactura.numdocumento) {

            this.onUpdate(props)

        } else {
            notifyErrorRep();
        }
    };


    onUpdate = async (modalData) => {

    };


    handleChangeDate = (value, formattedValue, id) => {

        console.log('VALORES DE FECHA DE ALATA')
        console.log(value)
        console.log(formattedValue)
        console.log(id)

        var formData = { ...this.state.formFactura };


        const name = id;
        var valueDay = formattedValue




        if (name == 'facturaDesde') {
            //console.log('VALOR NOMBRE REPRESENTANTE ------> ', value, event.target.name)
            //this.setState ({facturaDetail.nombreOferta : event.target.value});
            this.setState({ facturaDesde: value });

        }

        if (name == 'clienteHasta') {
            //this.setState ({facturaDetail.nombreOferta : event.target.value});
            this.setState({ clienteHasta: value });
        }


        if (name == 'createdAt') {
            //console.log('VALOR NOMBRE REPRESENTANTE ------> ', value, event.target.name)
            //this.setState ({facturaDetail.nombreOferta : event.target.value});
            this.setState({ createdAt: value });

        }

        if (name == 'updatedAt') {
            //this.setState ({facturaDetail.nombreOferta : event.target.value});
            this.setState({ updatedAt: value });
        }

        if (name == 'periodoValidezDesde') {
            //console.log('VALOR NOMBRE REPRESENTANTE ------> ', value, event.target.name)
            //this.setState ({facturaDetail.nombreOferta : event.target.value});
            this.setState({ periodoValidezDesde: value });

        }

        if (name == 'periodoValidezHasta') {
            //this.setState ({facturaDetail.nombreOferta : event.target.value});
            this.setState({ periodoValidezHasta: value });
        }


        console.log(formData)
        formData[name] = valueDay;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ filters: formData });
    }

    handleChangeDateFilter = (value, formattedValue, id) => {

        console.log('VALORES DE FECHA DE ALATA')
        console.log(value)
        console.log(formattedValue)
        console.log(id)

        var formData = { ...this.state.filters };


        const name = id;
        var valueDay = formattedValue


        if (name == 'facturaDesde') {
            //console.log('VALOR NOMBRE REPRESENTANTE ------> ', value, event.target.name)
            //this.setState ({facturaDetail.nombreOferta : event.target.value});
            this.setState({ facturaDesde: value });
            this.setState({
                buscarButtonDisabled: false,
            })

        }

        if (name == 'facturaHasta') {
            //this.setState ({facturaDetail.nombreOferta : event.target.value});
            this.setState({ facturaHasta: value });
            this.setState({
                buscarButtonDisabled: false,
            })
        }


        console.log(formData)
        formData[name] = valueDay;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ filters: formData });
    }

    handleChange = state => {
        // eslint-disable-next-line no-console
        //console.log('state', state.selectedRows[0]);
        //console.log(state.selectedCount)

        if (state.selectedCount === 1) {
            var existeFactura = ''
            var factura = state.selectedRows[0].uuid;
            console.log(factura)
            this.setState({ facturaDetail: state.selectedRows[0] });
            //let existeFechaAlta = state.selectedRows[0].createdAt
            //let existeFechaBaja = state.selectedRows[0].updatedAt
            existeFactura = state.selectedRows[0].uuid




            if (existeFactura) {
                console.log('VALORE DE facturaDetail  ------> ', this.state.facturaDetail)
                this.setState({ idFactura: this.state.facturaDetail.uuid });
                var formData = { ...state.selectedRows[0] };


                this.setState({ formFactura: formData });

                this.setState({
                    facturaSeleccionada: true,
                    inputDisabled: false
                });

            } else {
                console.log('ENTRA EN NO existeFactura')
                this.setState({
                    facturaSeleccionada: false,
                    formFactura: {
                        cups: "",
                        codigoEmpresaEmisora: "",
                        nombreEmpresaEmisora: "",
                        fechacomunic: "",
                        numdocumento: "",
                        nombre: "",
                        apellido1: "",
                        tipofactura: "",
                        clasefact: "",
                        fecfactura: null,
                        numfactura: "",
                        tipofacturacion: "",
                        tipopeaje: "",
                        importetotal: null,
                        tipopenalizacion: "",
                        metodofacturacion: "",
                        psrltvimporte: null,
                        psrltvcodconcepto: "",
                        psrltvdesconcepto: "",
                        psrltfimporte: null,
                        psrltfcodconcepto: "",
                        psrltfdesconcepto: "",
                        psrttvimporte: null,
                        psrttvcodconcepto: "",
                        psrttvdesconcepto: "",
                        psrttfimporte: null,
                        psrttfcodconcepto: "",
                        psrttfdesconcepto: "",
                        ocrtfimporte: null,
                        ocrtfcodconcepto: "",
                        ocrtfdesconcepto: "",
                        ctfimporte: null,
                        ctfcodconcepto: "",
                        ctfdesconcepto: "",
                        cgtsimporte: null,
                        cgtscodconcepto: "",
                        cgtsdesconcepto: "",
                        tcnmcimporte: null,
                        tcnmccodconcepto: "",
                        tcnmcdesconcepto: "",
                        ifaimporte: null,
                        ifacodconcepto: "",
                        ifadesconcepto: "",
                        ibiimporte: null,
                        ibicodconcepto: "",
                        ibidesconcepto: "",
                        iiimporte: null,
                        iicodconcepto: "",
                        iidesconcepto: "",
                        itfimporte: null,
                        itfcodconcepto: "",
                        itfdesconcepto: "",
                        tfpimporte: null,
                        tfpcodconcepto: "",
                        tfpdesconcepto: "",
                        tvpimporte: null,
                        tvpcodconcepto: "",
                        tvpdesconcepto: "",
                        ipimporte: null,
                        ipicodconcepto: "",
                        ipidesconcepto: "",
                        procesoFactura: false,
                        uuidFactura: "",
                        fechAlta: null,
                        factork: null,
                        consumo: null,
                        fecinicioperiodo: null,
                        fecfinperiodo: null,
                        consumoperiodo: null
                    }
                });
            }
        } else {

            this.setState({
                facturaSeleccionada: false,
                inputDisabled: false,
                updateClienteButtonDisabled: true,
                formFactura: {
                    cups: "",
                    codigoEmpresaEmisora: "",
                    nombreEmpresaEmisora: "",
                    fechacomunic: "",
                    numdocumento: "",
                    nombre: "",
                    apellido1: "",
                    tipofactura: "",
                    clasefact: "",
                    fecfactura: null,
                    numfactura: "",
                    tipofacturacion: "",
                    tipopeaje: "",
                    importetotal: null,
                    tipopenalizacion: "",
                    metodofacturacion: "",
                    psrltvimporte: null,
                    psrltvcodconcepto: "",
                    psrltvdesconcepto: "",
                    psrltfimporte: null,
                    psrltfcodconcepto: "",
                    psrltfdesconcepto: "",
                    psrttvimporte: null,
                    psrttvcodconcepto: "",
                    psrttvdesconcepto: "",
                    psrttfimporte: null,
                    psrttfcodconcepto: "",
                    psrttfdesconcepto: "",
                    ocrtfimporte: null,
                    ocrtfcodconcepto: "",
                    ocrtfdesconcepto: "",
                    ctfimporte: null,
                    ctfcodconcepto: "",
                    ctfdesconcepto: "",
                    cgtsimporte: null,
                    cgtscodconcepto: "",
                    cgtsdesconcepto: "",
                    tcnmcimporte: null,
                    tcnmccodconcepto: "",
                    tcnmcdesconcepto: "",
                    ifaimporte: null,
                    ifacodconcepto: "",
                    ifadesconcepto: "",
                    ibiimporte: null,
                    ibicodconcepto: "",
                    ibidesconcepto: "",
                    iiimporte: null,
                    iicodconcepto: "",
                    iidesconcepto: "",
                    itfimporte: null,
                    itfcodconcepto: "",
                    itfdesconcepto: "",
                    tfpimporte: null,
                    tfpcodconcepto: "",
                    tfpdesconcepto: "",
                    tvpimporte: null,
                    tvpcodconcepto: "",
                    tvpdesconcepto: "",
                    ipimporte: null,
                    ipicodconcepto: "",
                    ipidesconcepto: "",
                    procesoFactura: false,
                    uuidFactura: "",
                    fechAlta: null,
                    factork: null,
                    consumo: null,
                    fecinicioperiodo: null,
                    fecfinperiodo: null,
                    consumoperiodo: null
                }
            });



        };


    }



    handleClearRows = () => {
        this.setState({ toggledClearRows: !this.state.toggledClearRows })
    }


    render() {

        const formFactura: any = this.state.formFactura;
        console.log('VALOR DE THIS:STATE EN RENDER SIPDETAILSCONTRATOS ------> ', this.state)
        const createdAt = this.state.createdAt
        const updatedAt = this.state.updatedAt

        const facturaDesde = this.state.facturaDesde
        const facturaHasta = this.state.facturaHasta
        const clienteHasta = this.state.clienteHasta
        const facturaDetailConceptos = this.state.facturaDetail.conceptos


        return (
            <>
                <Header title={'Información Facturas Emitidas - Cliente'} />
                <Container className=" mt--7" fluid>

                    <Card className="mb-4">
                        <CardHeader>
                            <Button
                                onClick={this.showFiltersToggle}
                                size="sm"
                                className="float-right"
                            >
                                <i className={"ni " + this.getFilterIcon()} />
                            </Button>
                            <h3 className="mb-0">Filtrar</h3>
                        </CardHeader>
                        <Collapse isOpen={this.state.showFilters}>
                            <CardBody>
                                <Form innerRef={this.formRef}>
                                    <Row>
                                        <Col md="12" lg="3">

                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="clienteRef">
                                                    APELLIDOS/RAZON SOCIAL
                                            </Label>
                                                <Input
                                                    id="razonSocial"
                                                    placeholder="Ingresar Apellidos/Razón Social del cliente"
                                                    name="razonSocial"
                                                    maxLength={80}
                                                    onChange={this.onChangeFieldSearch}
                                                    value={this.state.filters.razonSocial}
                                                    type="text"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="cifClienteSearch">
                                                    DNI/CIF CLIENTE
                                            </Label>
                                                <Input
                                                    id="cifClienteSearch"
                                                    placeholder="Ingresar CIF del cliente"
                                                    name="cifClienteSearch"
                                                    maxLength={16}
                                                    onChange={this.onChangeFieldSearch}
                                                    value={this.state.filters.cifClienteSearch}
                                                    type="text"

                                                />
                                                {this.state.cifError ? <FormText color="red">Comprobar CIF/DNI</FormText> : ''}
                                            </FormGroup>
                                        </Col>

                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label">Desde</Label>
                                                <DatePicker id="facturaDesde"
                                                    value={facturaDesde}
                                                    onChange={(v, f) => this.handleChangeDateFilter(v, f, "facturaDesde")}
                                                    instanceCount={1}
                                                    dateFormat={"DD-MM-YYYY"}
                                                    dayLabels={spanishDayLabels}
                                                    monthLabels={spanishMonthLabels}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label">Hasta</Label>
                                                <DatePicker id="facturaHasta"
                                                    value={facturaHasta}
                                                    onChange={(v, f) => this.handleChangeDateFilter(v, f, "facturaHasta")}
                                                    instanceCount={2}
                                                    dateFormat={"DD-MM-YYYY"}
                                                    dayLabels={spanishDayLabels}
                                                    monthLabels={spanishMonthLabels}
                                                />
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    <Row>

                                        <Col lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="cifEmisorSearch">
                                                    DNI/CIF DISTRIBUIDORA
                                            </Label>
                                                <Input
                                                    id="cifEmisorSearch"
                                                    placeholder="Ingresar CIF del emisor"
                                                    name="cifEmisorSearch"
                                                    maxLength={16}
                                                    onChange={this.onChangeFieldSearch}
                                                    value={this.state.filters.cifEmisorSearch}
                                                    type="text"

                                                />
                                                {this.state.cifErrorEmisor ? <FormText color="red">Comprobar CIF/DNI</FormText> : ''}
                                            </FormGroup>
                                        </Col>

                                        <Col md="12" lg="3">

                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="clienteRef">
                                                    ID. REMESA
                                              </Label>
                                                <Input
                                                    id="remesaSearch"
                                                    placeholder="Ingresar identificador de remesa"
                                                    name="remesaSearch"
                                                    maxLength={12}
                                                    onChange={this.onChangeFieldSearch}
                                                    value={this.state.filters.remesaSearch}
                                                    type="text"
                                                />
                                            </FormGroup>
                                        </Col>


                                        <Col md="2" lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" for="tipoEstadoProducto">FACTURADO</Label>
                                                <Input type="select" name="tipoEstadoSearch" id="tipoEstadoSearch"
                                                    onChange={this.onChangeFieldSearch}
                                                    value={this.state.filters.tipoEstadoSearch}>
                                                    <option selected>Selecciona...</option>
                                                    <option value="true">FACTURADO CLIENTE</option>
                                                    <option value="false">SIN FACTURAR</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>

                                        <Col className="text-right">
                                            <Button onClick={this.clearForm}>
                                                <i className={"ni ni-fat-remove"} /> Limpiar
                                    </Button>
                                            <Button
                                                color="primary"
                                                onClick={this.buscarFacturas}
                                                disabled={this.state.buscarButtonDisabled}
                                            >
                                                <i className={"bi bi-search"}/> Buscar
                                    </Button>
                                        </Col>
                                    </Row>

                                    <Card className="shadow">
                                        <DataTable
                                            title="LISTADO DE FACTURAS GENERADAS - CLIENTES"
                                            columns={columnsDatosClientes}
                                            data={this.state.facturaDetailTotal}
                                            selectableRows
                                            //selectableRowsComponent={radio} // Pass the function only
                                            selectableRowsComponentProps={{ type: 'radio' }}// optionally, pass Material Ui supported props down to our custom checkbox
                                            selectableRowsSingle
                                            sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                            noDataComponent={"Sin datos para mostrar"}
                                            pagination
                                            paginationPerPage={10}
                                            paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
                                            onSelectedRowsChange={this.handleChange}
                                            clearSelectedRows={this.state.toggledClearRows}
                                            highlightOnHover
                                            striped
                                            pointerOnHover
                                            selectableRowsHighlight
                                            customStyles={customStyles}
                                            contextMessage={{ singular: 'producto', plural: 'productos', message: 'seleccionado' }}
                                            subHeader
                                            subHeaderComponent={
                                                <div style={{ display: 'flex', alignItems: 'center' }}>

                                                    <CSVLink
                                                        data={this.state.facturaDetailTotal}
                                                        filename={"FacturasClientes.csv"}
                                                        className="btn btn-default btn-sm btn-circle"
                                                        color="default"
                                                        target="_blank"
                                                        separator={";"}
                                                    >

                                                        <span className="btn-inner--icon">
                                                            <i className={"bi bi-file-earmark-spreadsheet"} style={{ fontSize: 25 }}></i>
                                                        </span>
                                                        <span className="btn-inner--text">Fichero CSV</span>

                                                    </CSVLink>
                                                </div>

                                            }
                                            progressComponent={<Circular />}
                                        />
                                    </Card>

                                </Form>
                            </CardBody>
                        </Collapse>
                    </Card>




                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <button
                                                className="btn btn-sm btn-primary"
                                                onClick={this.props.history.goBack}
                                            >
                                                Atrás
                                            </button>
                                        </Col>
                                        <FormGroup>
                                            <Col>

                                            </Col>
                                        </FormGroup>

                                    </Row>
                                    <div>
                                        <CardTitle>DATOS GENERALES FACTURA CLIENTE</CardTitle>
                                    </div>
                                    <Row>
                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="codigoEmpresaEmisora">
                                                    Cod. Distribuidora
                                                        </Label>
                                                <Input
                                                    id="codigoEmpresaEmisora"
                                                    placeholder=""
                                                    name="codigoEmpresaEmisora"
                                                    maxLength={10}
                                                    onChange={this.onChangeFieldFactura}
                                                    value={formFactura.codigoEmpresaEmisora}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="nombreEmpresaEmisora">
                                                    Distribuidora
                                                        </Label>
                                                <Input
                                                    id="nombreEmpresaEmisora"
                                                    placeholder=""
                                                    name="nombreEmpresaEmisora"
                                                    maxLength={40}
                                                    onChange={this.onChangeFieldFactura}
                                                    value={formFactura.nombreEmpresaEmisora}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="2" >
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="fechacomunic">
                                                    Fecha Comunicación
                                                        </Label>
                                                <Input
                                                    id="fechacomunic"
                                                    placeholder=""
                                                    name="fechacomunic"
                                                    maxLength={40}
                                                    onChange={this.onChangeFieldFactura}
                                                    value={formFactura.fechacomunic || ''}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />

                                            </FormGroup>
                                        </Col>

                                    </Row>


                                    <Row>
                                        <Col>
                                            <FormGroup>

                                                <Col>
                                                    <FormGroup>
                                                        <Label className="form-control-label" htmlFor="cups">
                                                            CUPS
                                                        </Label>
                                                        <Input
                                                            id="cups"
                                                            placeholder=""
                                                            name="cups"
                                                            maxLength={24}
                                                            onChange={this.onChangeFieldFactura}
                                                            value={formFactura.cups}
                                                            type="text"
                                                            disabled={this.state.inputDisabled}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Label className="form-control-label" htmlFor="nombre">
                                                    Nombre Cliente
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="nombre"
                                                    placeholder=""
                                                    name="nombre"
                                                    maxLength={40}
                                                    onChange={this.onChangeFieldFactura}
                                                    value={formFactura.nombre}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="apellido1">
                                                    Primer Apellido/Razón Social
                                                </Label>
                                                <Input
                                                    id="apellido1"
                                                    placeholder=""
                                                    name="apellido1"
                                                    maxLength={50}
                                                    onChange={this.onChangeFieldFactura}
                                                    value={formFactura.apellido1}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>


                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="numdocumento">
                                                    DNI/CIF
                                                </Label>
                                                <Input
                                                    id="numdocumento"
                                                    placeholder=""
                                                    name="numdocumento"
                                                    maxLength={50}
                                                    onChange={this.onChangeFieldFactura}
                                                    value={formFactura.numdocumento}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="tipofactura">
                                                    Tipo Factura
                                    </Label>
                                                <Input
                                                    id="tipofactura"
                                                    placeholder=""
                                                    name="tipofactura"
                                                    maxLength={12}
                                                    onChange={this.onChangeFieldFactura}
                                                    value={formFactura.tipofactura}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="clasefact">
                                                    Clase Factura
                                    </Label>
                                                <Input
                                                    id="clasefact"
                                                    placeholder=""
                                                    name="clasefact"
                                                    maxLength={12}
                                                    onChange={this.onChangeFieldFactura}
                                                    value={formFactura.clasefact}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="fecfactura">
                                                    Fecha Factura
                                    </Label>
                                                <Input
                                                    id="fecfactura"
                                                    placeholder=""
                                                    name="fecfactura"
                                                    maxLength={12}
                                                    onChange={this.onChangeFieldFactura}
                                                    value={formFactura.fecfactura || ''}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="numfactura">
                                                    Número Factura
                                    </Label>
                                                <Input
                                                    id="numfactura"
                                                    placeholder=""
                                                    name="numfactura"
                                                    maxLength={12}
                                                    onChange={this.onChangeFieldFactura}
                                                    value={formFactura.numfactura}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                    </Row>



                                    <Row>
                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="tipofacturacion">
                                                    Tipo Facturación
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="tipofacturacion"
                                                    placeholder=""
                                                    name="tipofacturacion"
                                                    maxLength={20}
                                                    onChange={this.onChangeFieldFactura}
                                                    value={formFactura.tipofacturacion}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="tipopeaje">
                                                    Tipo Peaje
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="tipopeaje"
                                                    placeholder=""
                                                    name="tipopeaje"
                                                    maxLength={20}
                                                    onChange={this.onChangeFieldFactura}
                                                    value={formFactura.tipopeaje}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="tipopenalizacion">
                                                    Tipo Penalización
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="tipopenalizacion"
                                                    placeholder=""
                                                    name="tipopenalizacion"
                                                    maxLength={20}
                                                    onChange={this.onChangeFieldFactura}
                                                    value={formFactura.tipopenalizacion}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="metodofacturacion">
                                                    Método Facturación
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="metodofacturacion"
                                                    placeholder=""
                                                    name="metodofacturacion"
                                                    maxLength={20}
                                                    onChange={this.onChangeFieldFactura}
                                                    value={formFactura.metodofacturacion}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="importetotal">
                                                    Importe Total Factura
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="importetotal"
                                                    placeholder=""
                                                    name="importetotal"
                                                    maxLength={20}
                                                    onChange={this.onChangeFieldFactura}
                                                    value={formFactura.importetotal || ''}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>



                                    </Row>

                                    <Row>
                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="tipofactura">
                                                    Tipo Factura
                                    </Label>
                                                <Input
                                                    id="tipofactura"
                                                    placeholder=""
                                                    name="tipofactura"
                                                    maxLength={12}
                                                    onChange={this.onChangeFieldFactura}
                                                    value={formFactura.tipofactura}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="clasefact">
                                                    Clase Factura
                                    </Label>
                                                <Input
                                                    id="clasefact"
                                                    placeholder=""
                                                    name="clasefact"
                                                    maxLength={12}
                                                    onChange={this.onChangeFieldFactura}
                                                    value={formFactura.clasefact}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="fecfactura">
                                                    Fecha Factura
                                    </Label>
                                                <Input
                                                    id="fecfactura"
                                                    placeholder=""
                                                    name="fecfactura"
                                                    maxLength={12}
                                                    onChange={this.onChangeFieldFactura}
                                                    value={formFactura.fecfactura || ''}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="numfactura">
                                                    Número Factura
                                    </Label>
                                                <Input
                                                    id="numfactura"
                                                    placeholder=""
                                                    name="numfactura"
                                                    maxLength={12}
                                                    onChange={this.onChangeFieldFactura}
                                                    value={formFactura.numfactura}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                    </Row>



                                    <Row>
                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="mibgas">
                                                    Precio MIB GAS
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="mibgas"
                                                    placeholder=""
                                                    name="mibgas"
                                                    maxLength={20}
                                                    onChange={this.onChangeFieldFactura}
                                                    value={formFactura.datosResumen.ptvcmibgas||""}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="ptvcfactork">
                                                    Coeficiente Tarifa
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="ptvcfactork"
                                                    placeholder=""
                                                    name="ptvcfactork"
                                                    maxLength={20}
                                                    onChange={this.onChangeFieldFactura}
                                                    value={formFactura.datosResumen.ptvcfactork}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="consumo">
                                                    Consumo
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="consumo"
                                                    placeholder=""
                                                    name="consumo"
                                                    maxLength={20}
                                                    onChange={this.onChangeFieldFactura}
                                                    value={formFactura.datosResumen.consumo}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="consumokwh">
                                                    consumokwh
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="consumokwh"
                                                    placeholder=""
                                                    name="consumokwh"
                                                    maxLength={20}
                                                    onChange={this.onChangeFieldFactura}
                                                    value={formFactura.datosResumen.consumokwh}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>



                                    </Row>






                                </CardBody>
                            </Card>

                            <Card className="shadow">

                                        <DataTable
                                            title="CONCEPTOS FACTURACION - CLIENTES"
                                            columns={columnsDatosConceptosClientes}
                                            data={facturaDetailConceptos}
                                            sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                            noDataComponent={"Sin datos para mostrar"}
                                            pagination
                                            paginationPerPage={15}
                                            paginationRowsPerPageOptions={[15, 20, 25]}
                                            highlightOnHover
                                            striped
                                            pointerOnHover
                                            selectableRowsHighlight
                                            customStyles={customStyles}
                                            subHeader
                                            subHeaderComponent={
                                                <div style={{ display: 'flex', alignItems: 'center' }}>

                                                    <CSVLink
                                                        data={this.state.facturaDetailTotal}
                                                        filename={"FacturasClientes.csv"}
                                                        className="btn btn-default btn-sm btn-circle"
                                                        color="default"
                                                        target="_blank"
                                                        separator={";"}
                                                    >

                                                        <span className="btn-inner--icon">
                                                            <i className={"bi bi-file-earmark-spreadsheet"} style={{ fontSize: 25 }}></i>
                                                        </span>
                                                        <span className="btn-inner--text">Fichero CSV</span>

                                                    </CSVLink>
                                                </div>

                                            }
                                            progressComponent={<Circular />}
                                        />



                            </Card>


                        </div>
                    </Row>

    {this.state.alert}

                </Container>

            </>
        );
    }
}


export default withApollo(facturasCliente);
