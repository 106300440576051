import React from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col} from "reactstrap";
import { withApollo } from "react-apollo";
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from "moment";
import 'moment/locale/es';
import Reflux from "reflux";
import { UserStore } from "../../store/UserStore";

import { GET_COMERCIAL_DETAIL } from "../../queries/comercial/detail";
import { GET_USER_DATA} from "../../queries/users/users.queries";
import numberWithPoints from "../../utils/numberWithPoints";
import {ThunderboltFilled,FireFilled} from '@ant-design/icons';
import listaSubmin from "../../assets/img/icons/custom/lista_submin.svg";
import listaSubminGas from "../../assets/img/icons/custom/lista_submin_gas.svg";
import listaEmpresas from "../../assets/img/icons/custom/lista_empresas.svg";
import listadoPS from "../../assets/img/icons/custom/listadoPS.svg";
import propuestaMulti from "../../assets/img/icons/custom/propuestaMulti.svg";
import gestionPropuestas from "../../assets/img/icons/custom/gestion_propuestas.svg";
import gestionContratos from "../../assets/img/icons/custom/gestion_contratos.svg";

class Header extends Reflux.Component<any, any> {
  state = {
UserStore: {},
companyIdUser: '',
userName:'',
companyNameUser: '',
userGroupId: 1000,
userId: '',
idCard: "",
first_name1: "",
last_name1: "",
showIndexButton: false,
showFreeMarketButton: false,
    isLoading: false,
    dataResumenContratos: {
        totalAceptados: 0,
        totalContratos: 0,
        totalEnEspera: 0,
        ultimaFecha: ''
    },
    dataResumenContratosFirmados:{
        totalFirmaContrato: 0,
        totalContratos: 0,
        totalEnEspera: 0,
        totalContratosGeneradoEnviado: 0,
totalFechaContrato:0,
        totalFechaActivacion:0
    },
isLoading2: true,
    data: {
      supplier_id: 0,
      num_sites: 0,
      kwhanual: 0,
      supplier_name: "",
      supplier_code_ree: "",
      persona_fisica_total_sites: 0,
      persona_fisica_sumkwhanual: 0,
      persona_juridica_total_sites: 0,
      persona_juridica_sumkwhanual: 0,
    },
  };


  constructor(props) {
      super(props);
      this.store = UserStore;
  }


  componentDidMount() {


      this.getUserData();
      this.fetchItems();
     // this.QueryResumenContratos();
     // this.QueryResumenContratosFirmados();

  };

  async getUserData() {

    const resp = await this.props.client.query({
      query: GET_USER_DATA,
    });

    const user = resp.data.userDataAdministrator;

    this.setState({

      first_name1: user.first_name,
      last_name1: user.last_name,
    });
    this.QueryResumenContratosAlt(user.email,user.companyId,user.id);
    this.QueryResumenContratosFirmadosAlt(user.email,user.companyId,user.id);
  }



  componentWillReceiveProps() {


      const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

      const companyNameGlobal = usuario.company;
      const companyIdUser = usuario.companyId;

      const userGroupId = usuario.user_group_id
      const userId = usuario.id
      const userName = usuario.first_name +" "+usuario.last_name
      const idCard = usuario.email

      let showIndexButton = usuario.indexada;
      let showFreeMarketButton = usuario.mercadolibre;
      let companyNameUser = usuario.company;

      if (userGroupId === 1000) {
          this.setState({
              activeUser: true
          })
      };

      this.setState({
          companyNameGlobal,
          companyIdUser,
          userGroupId,
          userId,
          showIndexButton,
          userName,
          showFreeMarketButton,
          companyNameUser,
          idCard

      });
  }

  UNSAFE_componentWillMount() {


      var userFromStorageTemp = localStorage.getItem("user");
      var parsedTemp = JSON.parse(userFromStorageTemp || '{}');
      var userIdTemp = parsedTemp.id;
      var companyIdUserTemp = parsedTemp.companyId;
      var userGroupId = parsedTemp.user_group_id;
      var company = parsedTemp.company;
      var idCard = parsedTemp.email;

      this.setState({

          companyIdUser: companyIdUserTemp,
          userId: userIdTemp,
          userGroupId: userGroupId,
          companyNameUser: company,
          idCard: idCard

      });

  };

///////////////////////////////////////////////////////////////////////////////////////////

fetchItems = async () => {
  let isLoading = true;
  this.setState({ isLoading });

  const sips = await this.props.client.query({
    query: GET_COMERCIAL_DETAIL,
  });
  const data = sips.data.getComercialDetail;

  isLoading = false;
  this.setState({ data, isLoading });
};

  //////////////////////////////////////////////////////////////////////////////////////

  QueryResumenContratosAlt = async (idcard1,companyIdUser1,userId1) => {

let isLoading = true;
const isSearching = true;
const companyId = companyIdUser1.toString()
const userId = '-1'


var userIdAux = userId1.toString();
var userQuery = userId1.toString();
var idCard = idcard1;
//const IdOferta = this.state.contrato;
//
      //const IdOferta = this.state.contrato;
      //
      const headers = {
          'Authorization': 'Bearer my-token',
          'My-Custom-Header': 'foobar',
          'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
      };
      let direccionFile = "https://datapi.psgestion.es/propuesta/resumenadministrador?id=" + companyId + "&user=" + userIdAux+ "&idcard=" + idCard;

      await axios.get(direccionFile, { headers }).
          then(result => {

              let data = result.data.rows[0]

              this.setState({
                  dataResumenContratos: data,
                  isLoading2: false
              });
          }).catch((error) => {
              console.log('Failure ', error)
          });

  };



////////////////////////////////////////////////////////////////////////////////////////////


QueryResumenContratosFirmadosAlt = async (idcard1,companyIdUser1,userId1) => {

    let isLoading = true;
    const isSearching = true;
    const companyId = companyIdUser1.toString()
    const userId = '-1'


    var userIdAux = userId1.toString();
    var userQuery = userId1.toString();
    var idCard = idcard1;

const headers = {
        'Authorization': 'Bearer my-token',
        'My-Custom-Header': 'foobar',
        'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
    };
    let direccionFile = "https://datapi.psgestion.es/contrato/resumenadministrador?id=" + companyId + "&user=" + userIdAux + "&idcard=" + idCard;

    await axios.get(direccionFile, { headers }).
        then(result => {

            let data = result.data.rows[0]

            this.setState({
                dataResumenContratosFirmados: data
            });
        }).catch((error) => {
            console.log('Failure ', error)
        });

};

///////////////////////////////////////////////////////////////////////////////////////////

  render() {




    return (
      <>
        <div className="header bg-gradient-info pb-8 pt-5 pt-lg-5">

            <Container fluid>
<div className="header-body pb-4 pt-1 ">
            <Row>

           <h2 className="font-weight-bold mb-0">BIENVENIDO! {this.state.first_name1} </h2 >
            </Row>
</div>
            </Container>

        <Container fluid className="mb-4">

            <div className="header-body">
              <Row>

                <Col  md="6" xl="3">
                  <Link  to={{ pathname: "GestionPropuesta"}} id = "gestio-propuesta">
                  <Card className="card-stats mb-4 mb-xl-0 h-100 btn p-1">
                    <CardBody className="p-0">
                      <Row>
                        <ThunderboltFilled style={{ fontSize:'14px',color:'#ffffff'}} className="col-auto ml-auto mr-3 align-self-end badge rounded-circle bg-yellow p-1"/>
                      </Row>
                      <Row>
                        <Col className="col col-8 m-auto text-center">
                          <h1><img src={gestionPropuestas}/></h1>
                        </Col>
                      </Row>
                      <Row className="m-auto">
                        <div className="col">
                          <CardTitle
                            className="text-center mb-0"
                          >
                            Gestión propuestas
                          </CardTitle>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                  </Link>
                </Col>

                <Col  md="6" xl="3">
                  <Link  to={{ pathname: "GestionContratos"}} id = "gestio-contrato">
                  <Card className="card-stats mb-4 mb-xl-0 h-100 btn p-1">
                    <CardBody className="p-0">
                      <Row>
                        <ThunderboltFilled style={{ fontSize:'14px',color:'#ffffff'}} className="col-auto ml-auto mr-3 align-self-end badge rounded-circle bg-yellow p-1"/>
                      </Row>
                      <Row>
                        <Col className="col col-8 m-auto text-center">
                          <h1><img src={gestionContratos}/></h1>
                        </Col>
                      </Row>
                      <Row className="m-auto">
                        <div className="col">
                          <CardTitle
                            className="text-center mb-0"
                          >
                            Gestión contratos
                          </CardTitle>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                  </Link>
                </Col>


              </Row>


            </div>
          </Container>

          <Container fluid>
<div className="header-body pb-2 pt-1 ">
          <Row>
                      <h2 className="font-weight-bold mb-0">DATOS RESUMEN PROPUESTAS </h2 >
          </Row>
</div>
          </Container>
          <Container fluid>
              <Row className="margin-reset w-100">

                  <Col md="6" xl="3">
                      <Card className="card-stats">
                          <CardBody>
                              <Row>
                                  <div className="col">
                                      <CardTitle
                                          tag="h5"
                                          className="text-uppercase text-muted mb-0"
                                      >
                                          TOTAL PROPUESTAS
    </CardTitle>
                                      <span className="h2 font-weight-bold mb-0">
                                          {this.state.dataResumenContratos.totalContratos || 0}
                                      </span>
                                  </div>
                                  <Col className="col-auto">
                                      <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                          <i className="bi bi-files" />
                                      </div>
                                  </Col>
                              </Row>
                              <p className="mt-3 mb-0 text-sm">
                                  <span className="text-nowrap">Propuesta totales realizadas</span>
                              </p>
                          </CardBody>
                      </Card>
                  </Col>
                  <Col md="6" xl="3">
                      <Card className="card-stats">
                          <CardBody>
                              <Row>
                                  <div className="col">
                                      <CardTitle
                                          tag="h5"
                                          className="text-uppercase text-muted mb-0"
                                      >
                                          ACEPTADOS
                                    </CardTitle>
                                      <span className="h2 font-weight-bold mb-0">
                                          {this.state.dataResumenContratos.totalAceptados || 0}
                                      </span>
                                  </div>
                                  <Col className="col-auto">
                                      <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                          <i className="bi bi-check2-circle" />
                                      </div>
                                  </Col>
                              </Row>
                              <p className="mt-3 mb-0 text-sm">
                                  {" "}
                                  <span className="text-nowrap">Propuestas aceptadas en el trimestre</span>
                              </p>
                          </CardBody>
                      </Card>
                  </Col>

                  <Col md="6" xl="3">
                      <Card className="card-stats">
                          <CardBody>
                              <Row>
                                  <div className="col">
                                      <CardTitle
                                          tag="h5"
                                          className="text-uppercase text-muted mb-0"
                                      >
                                          EN ESPERA
                                        </CardTitle>
                                      <span className="h2 font-weight-bold mb-0">
                                          {this.state.dataResumenContratos.totalEnEspera || 0}
                                      </span>
                                  </div>
                                  <Col className="col-auto">
                                      <div className="icon icon-shape bg-gradient-blue text-white rounded-circle shadow">
                                          <i className="bi bi-clock-history" />
                                      </div>
                                  </Col>
                              </Row>
                              <p className="mt-3 mb-0 text-sm">
                                  {" "}
                                  <span className="text-nowrap">Propuestas a la espera de aceptación</span>
                              </p>
                          </CardBody>
                      </Card>
                  </Col>


                  <Col md="6" xl="3">
                      <Card className="card-stats">
                          <CardBody>
                              <Row>
                                  <div className="col">
                                      <CardTitle
                                          tag="h5"
                                          className="text-uppercase text-muted mb-0"
                                      >
                                          FECHA ULT. PROPUESTA
                                    </CardTitle>
                                      <span className="h2 font-weight-bold mb-0">
                                          {this.state.dataResumenContratos.ultimaFecha || "SIN DATOS"}
                                      </span>
                                  </div>
                                  <Col className="col-auto">
                                      <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                          <i className="bi bi-calendar-event" />
                                      </div>
                                  </Col>
                              </Row>
                              <p className="mt-3 mb-0 text-sm">
                                  {" "}
                                  <span className="text-nowrap">Fecha última propuesta</span>
                              </p>
                          </CardBody>
                      </Card>
                  </Col>

              </Row>
          </Container>

          <Container fluid>
<div className="header-body pb-2 pt-1 ">
          <Row>
                      <h2 className="font-weight-bold mb-0">DATOS RESUMEN CONTRATOS </h2 >
          </Row>
</div>
          </Container>

          <Container fluid>
              <Row className="margin-reset w-100">

                  <Col Col md="6" xl="3">
                      <Card className="card-stats">
                          <CardBody>
                              <Row>
                                  <div className="col">
                                      <CardTitle
                                          tag="h5"
                                          className="text-uppercase text-muted mb-0"
                                      >
                                          TOTAL CONTRATOS
              </CardTitle>
                                      <span className="h2 font-weight-bold mb-0">
                                          {this.state.dataResumenContratosFirmados.totalContratos || 0}
                                      </span>
                                  </div>
                                  <Col className="col-auto">
                                      <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                          <i className="bi bi-files" />
                                      </div>
                                  </Col>
                              </Row>
                              <p className="mt-3 mb-0 text-sm">
                                  <span className="text-nowrap">Contratos totales últimos 3 meses</span>
                              </p>
                          </CardBody>
                      </Card>
                  </Col>
                  <Col Col md="6" xl="3">
                      <Card className="card-stats">
                          <CardBody>
                              <Row>
                                  <div className="col">
                                      <CardTitle
                                          tag="h5"
                                          className="text-uppercase text-muted mb-0"
                                      >
                                          ACEPTADOS
              </CardTitle>
                                      <span className="h2 font-weight-bold mb-0">
                                          {this.state.dataResumenContratosFirmados.totalFechaContrato || 0}
                                      </span>
                                  </div>
                                  <Col className="col-auto">
                                      <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                          <i className="bi bi-check2-circle" />
                                      </div>
                                  </Col>
                              </Row>
                              <p className="mt-3 mb-0 text-sm">
                                  {" "}
                                  <span className="text-nowrap">Contratos aceptados en los últimos 3 meses</span>
                              </p>
                          </CardBody>
                      </Card>
                  </Col>

                  <Col Col md="6" xl="3">
                      <Card className="card-stats">
                          <CardBody>
                              <Row>
                                  <div className="col">
                                      <CardTitle
                                          tag="h5"
                                          className="text-uppercase text-muted mb-0"
                                      >
                                          FIRMADOS
                  </CardTitle>
                                      <span className="h2 font-weight-bold mb-0">
                                          {this.state.dataResumenContratosFirmados.totalFirmaContrato || 0}
                                      </span>
                                  </div>
                                  <Col className="col-auto">
                                      <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                          <i className="bi bi-file-medical" />
                                      </div>
                                  </Col>
                              </Row>
                              <p className="mt-3 mb-0 text-sm">
                                  {" "}
                                  <span className="text-nowrap">Contratos firmados en los últimos 3 meses</span>
                              </p>
                          </CardBody>
                      </Card>
                  </Col>

                  <Col Col md="6" xl="3">
                      <Card className="card-stats">
                          <CardBody>
                              <Row>
                                  <div className="col">
                                      <CardTitle
                                          tag="h5"
                                          className="text-uppercase text-muted mb-0"
                                      >
                                          FECHA DE ACTIVACION
              </CardTitle>
                                      <span className="h2 font-weight-bold mb-0">
                                          {this.state.dataResumenContratosFirmados.totalFechaActivacion || 0}
                                      </span>
                                  </div>
                                  <Col className="col-auto">
                                      <div className="icon icon-shape bg-gradient-blue text-white rounded-circle shadow">
                                          <i className="bi bi-lightning" />
                                      </div>
                                  </Col>
                              </Row>
                              <p className="mt-3 mb-0 text-sm">
                                  {" "}
                                  <span className="text-nowrap">Contratos con fecha de activación asignada</span>
                              </p>
                          </CardBody>
                      </Card>
                  </Col>

              </Row>
</Container>


        </div>
      </>
    );
  }
}

export default withApollo(Header);
