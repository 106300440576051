import Environment from '../../constants/environment';
import ContratoRepresentanteFreeModal from "./ContratoRepresentanteModal";
import React, { forwardRef } from "react";
import Reflux from "reflux";
import axios from 'axios';
import memoize from 'memoize-one';
import connectorNodeV1 from '@opuscapita/react-filemanager-connector-node-v1';

//import DatePicker from "react-datepicker";


import { validateSpanishId, spainIdType } from 'spain-id'
//import FormData from 'form-data';
// reactstrap components
import {
    Card, Container, Row, CardTitle, CardBody, Label,
    FormGroup,
    Input, FormText,
    Form, Col, Button,
    UncontrolledTooltip, CardHeader, Collapse
} from "reactstrap";
import {
    Link,
    useRouteMatch
} from 'react-router-dom';
import LoadingOverlay from "react-loading-overlay";
import { CSVLink, CSVDownload } from "react-csv";

// core components
import Header from "../../components/Headers/Header";

import { withApollo } from "react-apollo";
import client from "../../api/client";

import moment from "moment";
import 'moment/locale/es'

import ReactBSAlert from "react-bootstrap-sweetalert";
import SipsOption from "../../components/Sips/SipsOption";
import {LIST_ESTADOS_PROPUESTAS} from "../../components/Sips/SipsOption.graphql";


import { ExternalDropZone, Upload } from "@progress/kendo-react-upload";
import GetUserData from "../../components/User/GetUserData";
import { UserStore } from "../../store/UserStore";
import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
//import memoize from 'memoize-one';
import CircularProgress from '@material-ui/core/CircularProgress';
import { parse, isValid, format } from 'date-fns';
import { es } from 'date-fns/locale';
import { saveAs } from 'file-saver';


var DatePicker = require("reactstrap-date-picker");
const FileDownload = require('js-file-download');

const spanishDayLabels = ['Dom', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sab'];
const spanishMonthLabels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];


const fileStatuses = [
    "UploadFailed",
    "Initial",
    "Selected",
    "Uploading",
    "Uploaded",
    "RemoveFailed",
    "Removing",
];





const uploadRef = React.createRef<Upload>();

const hint = <span>My custom hint</span>;
const note = <span>My custom note</span>;
const sortIcon = <ArrowDownward />;

const columnsFiles = memoize(clickHandler => [
    {
        selector: row => row.Key, name: 'NOMBRE DEL FICHERO', sortable: true, width: '550px', style: {
            color: 'rgba(0,0,0,.54)',
        },
    },

    {
        // eslint-disable-next-line react/button-has-type
        cell: (row) => <Button onClick={clickHandler} color="info" size="sm" className="float-right" id={row.Key} value={row.Key}>Ver</Button>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
    },
    { selector: row => row.LastModified, name: 'Ultima Modificación', sortable: true, width: '180px', center: false },
    { selector: row => row.Size, name: 'Tamaño en Bytes', sortable: true, width: '150px', center: false },

]);

const columnsEstados = memoize(clickHandler => [
    { selector: row => row.accion, name: 'ESTADO', sortable: true, width: '140px', center: true,  style: {
        color: 'rgba(0,0,0,.54)'
    },format: (row => capitalize(row.accion)) },
    { selector: row => row.fecha, name: 'FECHA', sortable: true, width: '120px', center: true, format: (row => moment(row.fecha, 'YYYY-MM-DD', true).isValid() ? moment(row.fecha, 'YYYY-MM-DD').format('DD-MM-YYYY') : "")},
    { selector: row => row.hora, name: 'HORA', sortable: true, width: '120px', center: true, format: (row => capitalize(row.hora)) },
    { selector: row => row.observacion, name: 'OBSERVACIONES', sortable: true, width: '250px', center: true, format: (row => capitalize(row.observacion)) },
    { selector: row => row.nombreUsuario, name: 'AGENTE', sortable: true, width: '160px', center: true, format: (row => capitalize(row.nombreUsuario)) }
    ]);


const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}

const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};



class SipDetallesPropuestas extends Reflux.Component<any, any> {
    public maxFileSize = 3;
    public fileInput: any = null;
    public lecturasRef: any = [];
    public inputEl: any = null;
    public formRef: any = null;
    public validFileExtensions = ["jpg", "jpeg", "png"];
    lastFilters = {};
    alert: null;
    state = {
        filters: {
            cupsSearch: "",
            contratoSearch: "",
            cifSearch: ""
        },
        UserStore: {},
        nombreFichero: "",
        isLoading: false,
        copiedText: null,
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        contrato: "",
        tipoContrato: "",
        page: 1,
        limit: 10,
        sipsConsumosTotal: [],
        sipsConsumos: [],
        sipsDetail: [],
        contratoDetail: {
            fechaDenegacion: "",
            fechaFirmaContrato: '',
            fechaVencimientoContrato: '',
            CIF: '',
            desMunicipioTitular: '',
            tipoViaTitular: '',
            viaTitular: '',
            numFincaTitular: '',
            portalTitular: '',
            escaleraTitular: '',
            pisoTitular: '',
            puertaTitular: '',
            cups: '',
            idContrato: '',
            idTarifaPeaje: '',
            nombreProducto: '',
            precioTEP1: 0,
            precioTEP3: 0,
            nombreTitular: '',
            apellido1Titular: '',
            apellido2Titular: '',
            desProvinciaTitular: '',
            iban: '',
            idTipoContrato: '',
            idRepresentante: '',
            datosContrato: '',
            CNAE: '',
            fechaInicioContrato: ''

        },
        contratoDetailTotal: [],
        isLoadingSips: true,
        isLoadingConsumos: true,
        isLoadingConsumosTotales: true,
        changeLogoLoading: false,
        updateProfileLoading: false,
        paginatedData: {
            items: [],
        },
        files: [],
        events: [],
        filePreviews: {},
        filesContrato: [],
        LecturasContrato: [],
        companyIdUser: '',
        companyNameGlobal: '',
        userGroupId: 1000,
        userId: '',
        userName:'',
        fechaDenegacion: '',
        fechaBajaContrato: '',
        fechaContrato: '',
        fechaFinContrato: '',
        fechaFirmaContrato: '',
        fechaInicioContrato: '',
        fechaVencimientoContrato: '',
        fechaActivacionFlag: 0,
        fechaFirmaContratoFlag: 0,
        fechaVencimientoContratoFlag: 0,
        saveData: false,
        saveDataRepre: false,
        saveProductButtonDisabled: true,
        contratoButtonDisabled: false,
        saveXMLButtonDisabled: true,
        idComercializadoraActualContrato: '',
        nombreComercializadoraActualContrato: '',
        cups: '',
        CIF: '',
        consumoAnualCup: '',
        idRepresentante: '',
        representanteDetail: [],
        T_Titular: {
            NombreTitular: "",
            Apellido1Titular: "",
            Apellido2Titular: "",
            NIF: "",
            TipoTitular: "",
            TipoViaTitular: "",
            ViaTitular: "",
            NumFincaTitular: "",
            PortalTitular: "",
            EscaleraTitular: "",
            PisoTitular: "",
            PuertaTitular: "",
            CodigoPostalTitular: "",
            AutonomiaTitular: "",
            ProvinciaTitular: "",
            MunicipioTitular: "",
            TelefonoFijo: "",
            TelefonMovil: "",
            Email: "",
            TipoCliente: "",
            ObservacionesCliente: "",
            GDPR: true,
            IBAN: "",
            CNAE: "",
            actividadCNAE: ""
        },
        T_Representante: {
            nombreRepresentante: "",
            apellido1Representante: "",
            apellido2Representante: "",
            NIFRepresentante: "",
            TipoTitularRepresentante: "",
            idRepresentante: "",
            idRepresentanteInterno: 0,
            CargoRepresentante: "",
            telefonoRepresentante: "",
            correoRepresentante: "",
            codigoPostalRepresentante: "",
            AutonomiaRepresentante: "",
            MunicipioRepresentante: "",
            ProvinciaRepresentante: "",
            escaleraRepresentante: "",
            numFincaRepresentante: "",
            pisoRepresentante: "",
            portalRepresentante: "",
            puertaRepresentante: "",
            tipoViaRepresentante: "",
            viaRepresentante: ""
        },
        T_PuntoSuministro: {
            TipoViaPS: "",
            ViaPS: "",
            NumFincaPS: "",
            PortalPS: "",
            EscaleraPS: "",
            PisoPS: "",
            PuertaPS: "",
            CodigoPostalPS: "",
            NombreMunicipioPS: "",
            codigoMunicipioPS: "",
            NombreProvinciaPS: "",
            codigoProvinciaPS: "",
            AutonomiaPS: "",
            codigoAutonomiaPS: "",
            TelefonoPS: "",
            ObservacionesPuntoSuministro: ""
        },
        T_CUPs: {
            Distribuidora: "",
            CUPsEle: "",
            Tarifa: "",
            P1: 0,
            P2: 0,
            P3: 0,
            P4: 0,
            P5: 0,
            P6: 0,
            PotMaxBie: "",
            TipoServicio: "",
            Consumo: "",
            esViviendaHabitual: "",
            fechaCaducidadAPM: null,
            fechaCaducidadBIE: null,
            fechaEmisionAPM: null,
            fechaEmisionBIE: null,
            fechaLimiteDerechosReconocidos: null,
            fechaUltimaLectura: null,
            fechaUltimoCambioComercializador: null,
            fechaUltimoMovimientoContrato: null,
            importeDepositoGarantiaEuros: 0,
            informacionImpagos: null,
            codigoAutoconsumo: "",
            descAutoconsumo: "",
            codigoTelegestion: "",
            tipoTelegestion: "",
            proEquipoMedida: "",
            propiedadICP: "",
            relacionTransformacionIntensidad: "",
            tarifaATR: "",
            tensionEm: "",
            tipoContrato: "",
            valorDerechosAccesoW: "",
            valorDerechosExtensionW: "",
            Tension: "",
            accesoContador: ""
        },
        T_PropuestaComercial: {
            PorcentajeAhorro: "",
            ImporteAhorro: "",
            Estado: "",
            Comercializadora: "",
            Producto: "",
            TarifaPeaje: "",
            Tarifa: "",
            IdProducto: "",
            Anexo: "",
            TipoPrecio: "",
            ObservacionesProducto: "",
            GastoOperativo: "",
            GastoOperativoPotencia: "",
            idServicio: "",
            idComercializadora: "",
            nombreServicio: "",
            precioServicio: "",
            ComercializadoraProcedencia: "",
            idComercializadoraProcedencia: ""
        },
        T_DatosAgenteComercial: {
            usuario: "",
            nombreUsuario: "",
            compania: 0,
            nombreCompania: "",
            correoAgente: "",
            zonaGeografica: ""
        },
        contratoDataGeneralElectricidad: {
            idComercializadora: "",
            nombreComercializadora: "",
            idProducto: "",
            nombreProducto: "",
            consumoAnualCup: "",
            tarifaATR: "",
            precioContratoTEP1: 0,
            precioContratoTEP2: 0,
            precioContratoTEP3: 0,
            precioContratoTEP4: 0,
            precioContratoTEP5: 0,
            precioContratoTEP6: 0,
            precioContratoTP1: 0,
            precioContratoTP3: 0,
            precioContratoTP2: 0,
            precioContratoTP4: 0,
            precioContratoTP5: 0,
            precioContratoTP6: 0,
            costeOperativo: "",
            costeOperativoPotencia: "",
            descuentoTerminoEnergia: 0,
            descuentoTerminoPotencia: 0,
            duracionContrato: ""
        },
        contratoDataGeneralGas: {
            idComercializadora: "",
            nombreComercializadora: "",
            idProducto: "",
            nombreProducto: "",
            consumoAnualCup: "",
            tarifaATR: "",
            precioContratoTEP1: 0,
            precioContratoTEP2: 0,
            precioContratoTFijo: 0,
            coeficienteK: 0,
            costeOperativo: 0,
            descuentoTerminoEnergia: 0,
            duracionContrato: "",
            caudalDiarioGas: 0
        },
        T_General: {
            Usuario: "",
            compania: 0,
            fechaPropuesta: "",
            horaPropuesta: "",
            mesPropuesta: 0,
            anioPropuesta: 0,
            fechaAprobacion: "",
            horaAprobacion: "",
            mesAprobacion: 0,
            anioAprobacion: 0,
            esAprobada: true,
            fechaDenegacion: "",
            horaDenegacion: "",
            mesDenegacion: 0,
            anioDenegacion: 0,
            esDenegada: false,
            IdOferta: "",
            IdOfertaInterna: "",
            cups: "",
            tipoCliente: "",
            tipoEnergia: "",
            estado:"",
            OfertaTipo: "",
            ofertaTipoId: "",
            idContrato: "",
            idInternoContrato: "",
            idCliente: "",
            idTipoContrato: "",
            fechaContrato: "",
            esRenovacion: false,
            modRenovacion: "",
            existePropRenovacion: false,
            fechaInicioContrato: null,
            duracionContrato: "12",
            fechaVencimientoContrato: null,
            fechaFirmaContrato: null,
            fechaFinContrato: null,
            referenciaContrato: "",
            observacionesContrato: "",
            idTipoSeguimiento: "",
            contratoGeneradoEnviado: false,
            esBajaContrato: false,
            fechaBajaContrato: "",
            justificacionBajaContrato: "",
            rutaDocumento: "",
            createdAt: "",
            updatedAt: "",
            createdBy: "",
            updatedBy: "",
            estadoCups: "",
            idContratoComercializadora: "",
            estadoSinComercialiazadora: "",
            estadoId:""

        },
            T_Seguimiento: [{
                accion: '',
                anio: 0,
                compania: '',
                fecha: '',
                hora: '',
                idAccion: '',
                mes: 0,
                nombreUsuario: '',
                observacion: "",
                usuario: ''
            }],
            T_SeguimientoAux: {
                accion: null,
                anio: null,
                compania: null,
                fecha: null,
                hora: null,
                idAccion: null,
                mes: null,
                nombreUsuario: null,
                observacion: "",
                usuario: null
            },
        T_Propuesta: [{

    }],
T_PropuestaComercialDatosIndividual:{},
        propuestaData:{
            precioPotenciaP1:0,
            precioPotenciaP2:0,
            precioPotenciaP3:0,
            precioPotenciaP4:0,
            precioPotenciaP5:0,
            precioPotenciaP6:0,
            precioEnergiaP1:0,
            precioEnergiaP2:0,
            precioEnergiaP3:0,
            precioEnergiaP4:0,
            precioEnergiaP5:0,
            precioEnergiaP6:0

        },
        observacionAux:"",
        estadoAux:"",
        estadoIdAux :"",
        saveButtonDisabledEstado: true,
        aprobarButtonDisabled: false,
        CIFRepreError: false,
        inputDisabled: false,
        show: false,
        imgCollection: [],
        saveButtonDisabled: true,
        showFilters: true,
        toggledClearRows: false,
        cupsError: false,
        cifError: false,
        inputCifDisabled: false,
        inputCupsDisabled: false,
        inputContratoDisabled: false,
        openModalRepresentante: false,
        representanteSeleccionado: [],
        openModalComparative: false,
        openModalConsumos: false,
        representante: [],
        locale: 'es',
        apiOptions: [],
        alert: null,
        dicEstados:{},
        estadoDisabled:false,
        productoTarifas: {},

    };

    sipsCSVData: any = [];
    viewSipsConsumoAnualCSVData: any = [];
    sipsConsumosCSVData: any = [];
    sipstotal: any = [];




    constructor(props) {
        super(props);
        this.store = UserStore;
        this.formRef = React.createRef();

        this.onFileChange = this.onFileChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.fileInput = React.createRef();
        this.lecturasRef = React.createRef();
        this.inputEl = React.createRef();

        this.showModal = this.showModal.bind(this);

        this.toggle = this.toggle.bind(this);

    }

    componentWillReceiveProps() {

        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;

        const userGroupId = usuario.user_group_id
        const userId = usuario.id
        const userName = usuario.fullname

        var direccion = ''
        if (companyIdUser) {
            direccion = `http://78.47.44.246:3020/filemanager/` + companyIdUser
        }

        const apiOptions = {
            ...connectorNodeV1.apiOptions,
            apiRoot: direccion // Or you local Server Node V1 installation.
        }

        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };

        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId,
            userName,
            apiOptions

        });
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////

    componentDidMount() {
        const {
            match: { params },
        } = this.props;

        this.setState({
            contrato: params.cups,
            tipoContrato: params.tipo
        }, this.fetchItems);

        let companyIdUser = '';
        let companyNameUser = '';
        let companyNameGlobal = '';
        let userGroupId = '';
        let userId = '';
        let userName = '';

        const userFromStorage = localStorage.getItem("user");
        if (userFromStorage) {
            try {
                const parsed = JSON.parse(userFromStorage);
                companyIdUser = parsed.companyId;
                companyNameUser = parsed.company;
                companyNameGlobal = parsed.company;
                userGroupId = parsed.user_group_id;
                userId = parsed.id
                userName = parsed.fullname

            } catch (e) {
                console.log(e)
            }
        }
        this.setState({ companyIdUser, companyNameUser, companyNameGlobal, userGroupId, userId, userName });
    };





    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    fetchItems = async () => {

        var existeRepre = 0

        //console.log('VALOR DE this.state.companyIdUser en buscarContrato/Fetch Items ', this.state)
        const companyIdAux = this.state.companyIdUser.toString();
        var userIdAux = this.state.userId.toString();
        var userQuery = this.state.userId.toString();
        //const IdOferta = this.state.contrato;
        if (this.state.userGroupId == 1000) {
            userQuery = ""
        }

        var dataFilters = {
            'compania': "",
            'cups': "",
            'Usuario': userQuery,
            'NIF': "",
            'IdOferta': this.state.contrato
        };
        var contratoDato: any = []

        var valores = JSON.stringify(dataFilters)
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };
        let direccionFile = "https://datapi.psgestion.es/propuesta/listado?id=" + companyIdAux + "&user=" + userIdAux;

        await axios.get(direccionFile, { headers, params: { valores } }).
            then(result => {
                console.log('resultado del listados')
                console.log(result.data.rows)
                contratoDato = result.data.rows[0]
                let contratoDatosGenerales = { ...result.data.rows[0] }
                delete contratoDatosGenerales.T_Titular
                delete contratoDatosGenerales.T_Representante
                delete contratoDatosGenerales.T_PuntoSuministro
                delete contratoDatosGenerales.T_CUPs
                delete contratoDatosGenerales.T_PropuestaComercial
                delete contratoDatosGenerales.T_DatosAgenteComercial
                delete contratoDatosGenerales.contratoDataGeneralElectricidad
                delete contratoDatosGenerales.contratoDataGeneralGas
                delete contratoDatosGenerales.T_Propuesta
                delete contratoDatosGenerales.T_Seguimiento

                var estadoDisabledButton =  false
                var aprobarDisabledButton = false
                if(contratoDato.esAprobada == true || contratoDato.esDenegada == true){
                        estadoDisabledButton = true
                        aprobarDisabledButton = true
                            }

var propuestaDataAux= {
    precioPotenciaP1: result.data.rows[0].T_Propuesta.producto.datos.precioPotenciaP1,
    precioPotenciaP2:result.data.rows[0].T_Propuesta.producto.datos.precioPotenciaP2,
    precioPotenciaP3:result.data.rows[0].T_Propuesta.producto.datos.precioPotenciaP3,
    precioPotenciaP4:result.data.rows[0].T_Propuesta.producto.datos.precioPotenciaP4,
    precioPotenciaP5:result.data.rows[0].T_Propuesta.producto.datos.precioPotenciaP5,
    precioPotenciaP6:result.data.rows[0].T_Propuesta.producto.datos.precioPotenciaP6,
    precioEnergiaP1:result.data.rows[0].T_Propuesta.producto.datos.precioEnergiaP1,
    precioEnergiaP2:result.data.rows[0].T_Propuesta.producto.datos.precioEnergiaP2,
    precioEnergiaP3:result.data.rows[0].T_Propuesta.producto.datos.precioEnergiaP3,
    precioEnergiaP4:result.data.rows[0].T_Propuesta.producto.datos.precioEnergiaP4,
    precioEnergiaP5:result.data.rows[0].T_Propuesta.producto.datos.precioEnergiaP5,
    precioEnergiaP6:result.data.rows[0].T_Propuesta.producto.datos.precioEnergiaP6

}


if(result.data.rows[0].OfertaTipo == 'MP'){

    propuestaDataAux= {
        precioPotenciaP1: result.data.rows[0].T_Propuesta.producto.precioPotenciaP1,
        precioPotenciaP2:result.data.rows[0].T_Propuesta.producto.precioPotenciaP2,
        precioPotenciaP3:result.data.rows[0].T_Propuesta.producto.precioPotenciaP3,
        precioPotenciaP4:result.data.rows[0].T_Propuesta.producto.precioPotenciaP4,
        precioPotenciaP5:result.data.rows[0].T_Propuesta.producto.precioPotenciaP5,
        precioPotenciaP6:result.data.rows[0].T_Propuesta.producto.precioPotenciaP6,
        precioEnergiaP1:result.data.rows[0].T_Propuesta.producto.precioEnergiaP1,
        precioEnergiaP2:result.data.rows[0].T_Propuesta.producto.precioEnergiaP2,
        precioEnergiaP3:result.data.rows[0].T_Propuesta.producto.precioEnergiaP3,
        precioEnergiaP4:result.data.rows[0].T_Propuesta.producto.precioEnergiaP4,
        precioEnergiaP5:result.data.rows[0].T_Propuesta.producto.precioEnergiaP5,
        precioEnergiaP6:result.data.rows[0].T_Propuesta.producto.precioEnergiaP6

    }



}

                this.setState({
                    contratoDetailTotal: result.data.rows[0],
                    T_Titular: result.data.rows[0].T_Titular,
                    T_General: contratoDatosGenerales,
                    T_Representante: result.data.rows[0].T_Representante,
                    T_PuntoSuministro: result.data.rows[0].T_PuntoSuministro,
                    T_CUPs: result.data.rows[0].T_CUPs,
                    T_PropuestaComercial: result.data.rows[0].T_PropuestaComercial,
                    T_DatosAgenteComercial: result.data.rows[0].T_DatosAgenteComercial,
                    contratoDataGeneralElectricidad: result.data.rows[0].contratoDataGeneralElectricidad,
                    contratoDataGeneralGas: result.data.rows[0].contratoDataGeneralGas,
                    T_Seguimiento: result.data.rows[0].T_Seguimiento,
                    T_Propuesta: result.data.rows[0].T_Propuesta,
                    estadoDisabled :estadoDisabledButton,
                    propuestaData: propuestaDataAux,
                    aprobarButtonDisabled: aprobarDisabledButton

                })

            }).catch((error) => {
                console.log('Failure')
                this.warningAlert();
            });

        //console.log('result.data.rows[0] en contratoDato --->', contratoDato)

        let fechaFirmaContratoAux = contratoDato.esAprobada
        let fechaDenegacionAux = contratoDato.esDenegada


        let idComercializadoraActualContrato = "";
        let nombreComercializadoraActualContrato = ""

        if (contratoDato.T_PropuestaComercial.hasOwnProperty('idComercializadoraProcedencia')) {
            idComercializadoraActualContrato = contratoDato.T_PropuestaComercial.idComercializadoraProcedencia
            nombreComercializadoraActualContrato = contratoDato.T_PropuestaComercial.ComercializadoraProcedencia
        };

        this.setState({
            idComercializadoraActualContrato: idComercializadoraActualContrato,
            nombreComercializadoraActualContrato: nombreComercializadoraActualContrato,
            CIF: contratoDato.T_Titular.NIF,
            cups: contratoDato.cups,
            fechaDenegacion: fechaDenegacionAux,
            idRepresentante: contratoDato.T_Representante.idRepresentante,
            consumoAnualCup: contratoDato.T_CUPs.Consumo
        });

        if (fechaFirmaContratoAux || fechaDenegacionAux) {
            this.setState({
                saveXMLButtonDisabled: false,
                inputDisabled: true,
                contratoButtonDisabled: false
            })
        } else {
            this.setState({
                saveXMLButtonDisabled: true,
                inputDisabled: false,
                contratoButtonDisabled: false
            })

        };

        existeRepre = contratoDato.T_Representante.idRepresentanteInterno
        this.setState({ isLoadingSips: false });


        if (existeRepre != 0 && (!fechaDenegacionAux || !fechaFirmaContratoAux)) {


            var dataFiltersRepre = {
                'compania': "",
                'cups': "",
                'Usuario': userQuery,
                'NIF': "",
                'idContrato': "",
                'NIFRepresentante': contratoDato.T_Representante.NIFRepresentante
            };
            var contratoDato: any = []

            valores = JSON.stringify(dataFiltersRepre)

            const headers = {
                'Authorization': 'Bearer my-token',
                'My-Custom-Header': 'foobar',
                'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
            };
            let direccionFile = "https://datapi.psgestion.es/representante/listado?id=" + companyIdAux + "&user=" + userIdAux;

            await axios.get(direccionFile, { headers, params: { valores } }).
                then(result => {
                    console.log('resultado del listados')
                    console.log(result.data.rows)

                    this.setState({
                        idRepresentante: result.data.rows[0].idRepresentanteInterno,
                        T_Representante: result.data.rows[0]
                    });

                }).catch((error) => {
                    console.log('Failure')
                    this.warningAlert()
                });
        }


        let direccionFileList = "https://datapi.psgestion.es/files?id=" + this.state.companyIdUser + "&contrato=" + this.state.contrato;
        axios.get(direccionFileList, { headers }).
            then(result => {
                console.log('resultado del listados')
                console.log(result)
                this.setState({ filesContrato: result.data.Contents})
            }).catch(function() {
                console.log('Failure')
            });


    };

    ///////////////////////////////////////////////////////////////////////////////////////


    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    warningAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error en la base de datos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un error en la Base de datos. Revise los datos introducidos
     </ReactBSAlert>
            )
        });
    };


    warningAlertService = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error de comunicación con el servicio de generación de contratos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un error de comunicación con el servicio. Si persiste comuniquelo al soporte técnico
     </ReactBSAlert>
            )
        });
    };

    warningAlertFiles = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error en el servidor de ficheros"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un error en el servidor. Comunique el problema a soporte
     </ReactBSAlert>
            )
        });
    };


    confirmAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Estás seguro de eliminar el representante?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.borrar()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Si, Borrar!"
                    btnSize=""
                >
                    No se podrá volver atrás!
            </ReactBSAlert>
            )
        });
    };
    confirmedAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Borrado!"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="primary"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    EL representante se ha eliminado de la propuesta.
            </ReactBSAlert>
            )
        });
    };


    confirmAlertSavePDF = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Desea generar la propuesta presentada al cliente?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onPdf()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Generar"
                    btnSize=""
                >
            </ReactBSAlert>
            )
        });
    };

    pdfAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="contrato Generado"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="primary"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    El contrato se ha generado correctamente. Consulta el directorio de descargas.
            </ReactBSAlert>
            )
        });
    };


    successAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Correcto"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Contrato actualizado correctamente
        </ReactBSAlert>
            )
        });
    };




    ///////////////////////////////////////////////////////////////////////////////

    toggle() {
        this.setState({
            openModalComparative: !this.state.openModalComparative,
        });
    };

    ///////////////////////////////////////////////////////////////////////////////

    showModal() {
        this.setState({
            openModalComparative: true,
        });
    };

    ////////////////////////////////////////////////////////////////////////////

    openModalComparativa = async (event) => {
        this.showModal();
        return;

    };

    //////////////////////////////////////////////////////////////////////////7

    deleteRepresentante = async (event) => {
        this.confirmAlert();
    }

    borrar = async () => {

        this.setState({
            T_Representante: {
                nombreRepresentante: "",
                apellido1Representante: "",
                apellido2Representante: "",
                NIFRepresentante: "",
                TipoTitularRepresentante: "",
                idRepresentante: "",
                idRepresentanteInterno: 0,
                CargoRepresentante: "",
                telefonoRepresentante: "",
                correoRepresentante: "",
                codigoPostalRepresentante: "",
                AutonomiaRepresentante: "",
                MunicipioRepresentante: "",
                ProvinciaRepresentante: "",
                escaleraRepresentante: "",
                numFincaRepresentante: "",
                pisoRepresentante: "",
                portalRepresentante: "",
                puertaRepresentante: "",
                tipoViaRepresentante: "",
                viaRepresentante: ""
            },
            saveProductButtonDisabled: false
        });
        this.confirmedAlert()

    };



    ////////////////////////////////////////////////////////////////////////////////
    sipConsumosGasSelected = () => {
        let id = this.state.T_General.cups;
        const win = window.open("/consumos/sip-detalles-extendidos/" + id, "_blank");
        if (win != null) {
            win.focus();
        }
    };

    /////////////////////////////////////////////////////////////////////////////////

    sipConsumosSelected = () => {
        let id = this.state.T_General.cups;
        const win = window.open("/consumos/sip-detalles-extendidos-elec/" + id, "_blank");
        if (win != null) {
            win.focus();
        }
    };

    /////////////////////////////////////////////////////////////////////////////////

    onPageClick = (page) => { };

////////////////////////////////////////////////////////////////////////////////////////////////

    onChangeField = (event) => {

};

////////////////////////////////////////////////////////////////////////////////////////////////


    onChangeFieldTitular = (event) => {

    };

    ////////////////////////////////////////////////////////////////////////////////////////////////

    onChangeFieldRepre = (event) => {


    };

    ////////////////////////////////////////////////////////////////////////////////////////////////

    onChangeFieldPuntoSuministro = (event) => {

    };

    ////////////////////////////////////////////////////////////////////////////////////////////////

    onChangeFieldCUPs = (event) => {

    }

    onChangeFieldPropuestaComercial = (event) => {

    }

    ////////////////////////////////////////////////////////////////////////////////////////////////


    handleChangeDateFilter = (value, formattedValue, id) => {

        let formData = { ...this.state.T_General };
        let formDataSeguimiento ={...this.state.T_SeguimientoAux}
        let target = id;


        const name = id;
        var valueDay = formattedValue
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////


    export = () => { };

    tipoLectura = (props) => {
        const tipoContrato = props.tipoContrato;
        const cupsId = this.state.contratoDetail.idContrato
        var { path, url } = this.props.match;
        //{url} = "/admin/sip-contratos/"
        // this.props.history.push('/admin/sip-contratos/');

        if (tipoContrato === 'GAS') {
            return (

                <React.Fragment>
                    <Card className="p-3 bg-light">
                        <FormGroup>

                            <Button id="gpsProptooltip" color="primary" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                onClick={this.sipConsumosGasSelected}
                            >
                                <span className="btn-inner--icon">
                                    <i className="far fa-chart-bar fa-2x" style={{ fontSize: 35 }}></i>
                                </span>
                                <span className="btn-inner--text"> Datos Consumos</span>
                                <UncontrolledTooltip delay={0} placement="top" target="gpsProptooltip">
                                    DATOS DE CONSUMOS Y PUNTO SUMINISTRO GAS
                                    </UncontrolledTooltip>
                            </Button>

                            <Button id="glecturaProptooltip" color="primary" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                onClick={this.showTable}
                            >
                                <span className="btn-inner--icon">
                                    <i className="fas fa-file-invoice fa-2x" style={{ fontSize: 35 }}></i>
                                </span>
                                <span className="btn-inner--text"> Mostrar Estados</span>
                                <UncontrolledTooltip delay={0} placement="top" target="glecturaProptooltip">
                                    HISTORICO ESTADOS DE SEGUIMIENTO DE LA PROPUESTA
                                    </UncontrolledTooltip>
                            </Button>

                        </FormGroup>
                    </Card>

                </React.Fragment>

            )

        } else {
            return (
                <React.Fragment>

                    <Card className="p-3 bg-light">
                        <FormGroup>

                            <Button id="elecProptooltip" color="primary" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                onClick={this.sipConsumosSelected}
                            >
                                <span className="btn-inner--icon">
                                    <i className="far fa-chart-bar fa-2x" style={{ fontSize: 35 }}></i>
                                </span>
                                <span className="btn-inner--text"> Datos Consumos</span>
                                <UncontrolledTooltip delay={0} placement="top" target="elecProptooltip">
                                    DATOS DE CONSUMOS Y PUNTO SUMINISTRO ELECTRICO
                                        </UncontrolledTooltip>
                            </Button>

                            <Button id="glecturaProptooltip" color="primary" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                onClick={this.showTable}
                            >
                                <span className="btn-inner--icon">
                                    <i className="fas fa-file-invoice fa-2x" style={{ fontSize: 35 }}></i>
                                </span>
                                <span className="btn-inner--text"> Mostrar Estados</span>
                                <UncontrolledTooltip delay={0} placement="top" target="glecturaProptooltip">
                                    Muestra el histórico de estados de la propuesta
                                    </UncontrolledTooltip>
                            </Button>


                        </FormGroup>
                    </Card>

                </React.Fragment>

            )

        }
    }

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    tipoCup = (props) => {
        const tipoCup = props.tipoCup;
        const form_T_CUPs: any = this.state.T_CUPs;
        const form_T_PropuestaComercial: any = this.state.T_PropuestaComercial;
        if (tipoCup === 'ELECTRICIDAD') {
            return (
                <div>
                    <Row>
                        <Col md="3">
                            <FormGroup>

                                <Label className="form-control-label" htmlFor="CUPsEle">
                                    CUPS ELECTRICIDAD
                                </Label>
                                <Input
                                    id="CUPsEle"
                                    placeholder=""
                                    name="CUPsEle"
                                    onChange={this.onChangeField}
                                    value={form_T_CUPs.CUPsEle}
                                    type="text"
                                    required
                                />
                            </FormGroup>
                        </Col>

                        <Col md="1">
                            <FormGroup>

                                <Label className="form-control-label" htmlFor="Tarifa">
                                    Tarifa
                                </Label>
                                <Input
                                    id="Tarifa"
                                    placeholder=""
                                    name="Tarifa"
                                    onChange={this.onChangeField}
                                    value={form_T_CUPs.Tarifa}
                                    type="text"
                                    required
                                />
                            </FormGroup>
                        </Col>


                        <Col sm="1">
                            <FormGroup>

                                <Label className="form-control-label" htmlFor="P1">
                                    P1
                                </Label>
                                <Input
                                    id="P1"
                                    placeholder=""
                                    name="P1"
                                    onChange={this.onChangeField}
                                    value={form_T_CUPs.P1}
                                    type="text"
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="1">
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="P2">
                                    P2
                                </Label>
                                <Input
                                    id="P2"
                                    placeholder=""
                                    name="P2"
                                    onChange={this.onChangeField}
                                    value={form_T_CUPs.P2}
                                    type="text"
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="1">
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="P3">
                                    P3
                                </Label>
                                <Input
                                    id="P3"
                                    placeholder=""
                                    name="P3"
                                    onChange={this.onChangeField}
                                    value={form_T_CUPs.P3}
                                    type="text"
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="1">
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="P4">
                                    P4
                                </Label>
                                <Input
                                    id="P4"
                                    placeholder=""
                                    name="P4"
                                    onChange={this.onChangeField}
                                    value={form_T_CUPs.P4}
                                    type="text"
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="1">
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="P5">
                                    P5
                                </Label>
                                <Input
                                    id="P5"
                                    placeholder=""
                                    name="P5"
                                    onChange={this.onChangeField}
                                    value={form_T_CUPs.P5}
                                    type="text"
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="1">
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="P6">
                                    P6
                                </Label>
                                <Input
                                    id="P6"
                                    placeholder=""
                                    name="P6"
                                    onChange={this.onChangeField}
                                    value={form_T_CUPs.P6}
                                    type="text"
                                    required
                                />
                            </FormGroup>
                        </Col>

                        <Col md="2">
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="Consumo">
                                    Consumo Anual
                                </Label>
                                <Input
                                    id="Consumo"
                                    placeholder=""
                                    name="Consumo"
                                    onChange={this.onChangeField}
                                    value={form_T_CUPs.Consumo}
                                    type="text"
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>);
        } else {
            return (
                <div>
                    <Row>
                        <Col md="3">
                            <FormGroup>

                                <Label className="form-control-label" htmlFor="CUPsEl">
                                    CUPS GAS
                                    </Label>
                                <Input
                                    id="CUPsEl"
                                    placeholder=""
                                    name="CUPsEl"
                                    onChange={this.onChangeField}
                                    value={form_T_CUPs.CUPsEle}
                                    type="text"
                                    required
                                />
                            </FormGroup>
                        </Col>

                        <Col md="1">
                            <FormGroup>

                                <Label className="form-control-label" htmlFor="Tarifa">
                                    Tarifa
                                    </Label>
                                <Input
                                    id="Tarifa"
                                    placeholder=""
                                    name="Tarifa"
                                    onChange={this.onChangeField}
                                    value={form_T_CUPs.Tarifa}
                                    type="text"
                                    required
                                />
                            </FormGroup>
                        </Col>


                        <Col md="2">
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="Consumo">
                                    Consumo
                                    </Label>
                                <Input
                                    id="Consumo"
                                    placeholder=""
                                    name="Consumo"
                                    onChange={this.onChangeField}
                                    value={form_T_CUPs.Consumo}
                                    type="text"
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>)
        }
    }
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

tipoPrecioTarifa = (props) => {
    const tipoCup = props.tipoCup;
    const form_T_CUPs: any = this.state.T_CUPs;
    const form_T_PropuestaComercial: any = this.state.T_PropuestaComercial;
    const form_T_Propuesta:any = this.state.T_Propuesta;
    if (tipoCup === 'ELECTRICIDAD') {
        if(form_T_PropuestaComercial.TipoPrecio == "1"){
        return (
            <div>
                <Row>
                    <Col>
                        <CardTitle className="mt-4 info-title"><h2>Resumen producto {this.state.T_PropuestaComercial.Producto}</h2></CardTitle>
                        <Row>
                            <Col><strong>Precio P1: </strong><br></br>{this.state.propuestaData.precioPotenciaP1||0}</Col>
                            <Col><strong>Precio P2: </strong><br></br>{this.state.propuestaData.precioPotenciaP2||0}</Col>
                            <Col><strong>Precio P3: </strong><br></br>{this.state.propuestaData.precioPotenciaP3||0}</Col>
                            <Col><strong>Precio P4: </strong><br></br>{this.state.propuestaData.precioPotenciaP4||0}</Col>
                            <Col><strong>Precio P5: </strong><br></br>{this.state.propuestaData.precioPotenciaP5||0}</Col>
                            <Col><strong>Precio P6: </strong><br></br>{this.state.propuestaData.precioPotenciaP6||0}</Col>
                        </Row>
                        <Row className="mt-2">
                            <Col><strong>Precio E1: </strong><br></br>{this.state.propuestaData.precioEnergiaP1||0}</Col>
                            <Col><strong>Precio E2: </strong><br></br>{this.state.propuestaData.precioEnergiaP2||0}</Col>
                            <Col><strong>Precio E3: </strong><br></br>{this.state.propuestaData.precioEnergiaP3||0}</Col>
                            <Col><strong>Precio E4: </strong><br></br>{this.state.propuestaData.precioEnergiaP4||0}</Col>
                            <Col><strong>Precio E5: </strong><br></br>{this.state.propuestaData.precioEnergiaP5||0}</Col>
                            <Col><strong>Precio E6: </strong><br></br>{this.state.propuestaData.precioEnergiaP6||0}</Col>
                        </Row>

                </Col>

                </Row>
            </div>)} else {
                return (
                    <div>
                        <Row>
                            <Col>
                                <CardTitle className="mt-4 info-title"><h2>Resumen producto {this.state.T_PropuestaComercial.Producto}</h2></CardTitle>
                                <Row>
                                    <Col><strong>Precio P1: </strong><br></br>{this.state.propuestaData.precioPotenciaP1||0}</Col>
                                    <Col><strong>Precio P2: </strong><br></br>{this.state.propuestaData.precioPotenciaP2||0}</Col>
                                    <Col><strong>Precio P3: </strong><br></br>{this.state.propuestaData.precioPotenciaP3||0}</Col>
                                    <Col><strong>Precio P4: </strong><br></br>{this.state.propuestaData.precioPotenciaP4||0}</Col>
                                    <Col><strong>Precio P5: </strong><br></br>{this.state.propuestaData.precioPotenciaP5||0}</Col>
                                    <Col><strong>Precio P6: </strong><br></br>{this.state.propuestaData.precioPotenciaP6||0}</Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col><strong>Precio E1: </strong><br></br>{this.state.propuestaData.precioEnergiaP1||0}</Col>
                                    <Col><strong>Precio E2: </strong><br></br>{this.state.propuestaData.precioEnergiaP2||0}</Col>
                                    <Col><strong>Precio E3: </strong><br></br>{this.state.propuestaData.precioEnergiaP3||0}</Col>
                                    <Col><strong>Precio E4: </strong><br></br>{this.state.propuestaData.precioEnergiaP4||0}</Col>
                                    <Col><strong>Precio E5: </strong><br></br>{this.state.propuestaData.precioEnergiaP5||0}</Col>
                                    <Col><strong>Precio E6: </strong><br></br>{this.state.propuestaData.precioEnergiaP6||0}</Col>
                                </Row>

                        </Col>

                        </Row>
                    </div>)

                };
    } else {
        if(form_T_PropuestaComercial.TipoPrecio == "1"){
        return (
            <div>
                <Row>
                    <Col>
                        <CardTitle className="mt-4 info-title"><h2>Resumen producto {this.state.T_PropuestaComercial.Producto}</h2></CardTitle>
                        <Row>
                            <Col><strong>Modificador Termino Variable Consumo Coeficiente K: </strong><br></br>{form_T_Propuesta.producto.datos.factork||0}</Col>
                        </Row>
                    </Col>

                </Row>
            </div>) } else {
                return (
                    <div>
                        <Row>
                            <Col>
                                <CardTitle className="mt-4 info-title"><h2>Resumen producto {this.state.T_PropuestaComercial.Producto}</h2></CardTitle>
                                <Row>
                                    <Col><strong>Precio P1: </strong><br></br>{this.state.propuestaData.precioPotenciaP1||0}</Col>
                                    <Col><strong>Precio P2: </strong><br></br>{this.state.propuestaData.precioPotenciaP2||0}</Col>
                                    <Col><strong>Precio P3: </strong><br></br>{this.state.propuestaData.precioPotenciaP3||0}</Col>
                                    <Col><strong>Precio P4: </strong><br></br>{this.state.propuestaData.precioPotenciaP4||0}</Col>
                                    <Col><strong>Precio P5: </strong><br></br>{this.state.propuestaData.precioPotenciaP5||0}</Col>
                                    <Col><strong>Precio P6: </strong><br></br>{this.state.propuestaData.precioPotenciaP6||0}</Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col><strong>Precio E1: </strong><br></br>{this.state.propuestaData.precioEnergiaP1||0}</Col>
                                    <Col><strong>Precio E2: </strong><br></br>{this.state.propuestaData.precioEnergiaP2||0}</Col>
                                    <Col><strong>Precio E3: </strong><br></br>{this.state.propuestaData.precioEnergiaP3||0}</Col>
                                    <Col><strong>Precio E4: </strong><br></br>{this.state.propuestaData.precioEnergiaP4||0}</Col>
                                    <Col><strong>Precio E5: </strong><br></br>{this.state.propuestaData.precioEnergiaP5||0}</Col>
                                    <Col><strong>Precio E6: </strong><br></br>{this.state.propuestaData.precioEnergiaP6||0}</Col>
                                </Row>

                        </Col>

                        </Row>
                    </div>)

                };
    }
}




////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    showTable = () => {
        if (this.state.show === false) {
            this.setState({
                show: true
            });

            const reftp = document.getElementById("tablaLecturas")
            console.log('VALORES DE REFTP', reftp)
            reftp ?.scrollIntoView({ behavior: "smooth" })

            } else {

            this.setState({
                show: false
            });
        }
    };


    ////////////////////////////////////////////////////////////////////////////////////////

    handleButtonClick = (state) => {
        // eslint-disable-next-line no-console
        const id = state.target.id;
        const file = state.target.value
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };

        let direccionFileList = "https://datapi.psgestion.es/files/download?id=" + this.state.companyIdUser + "&contrato=" + this.state.contrato + "&file=" + file;

        axios.get(direccionFileList,
            {
                headers,
                responseType: 'blob'
            }).
            then((response) => {
                FileDownload(response.data, file);
            }).catch(function() {
                console.log('Failure')
            });
    };


    ////////////////////////////////////////////////////////////////////////////////////////////////

    contratoHandler(props) {

        //this.confirmAlertSavePDF();
        this.onPdf()
    };


    //////////////////////////////////////////////////////////////////////////////////////////////////////
    onEstado = async () => {

        let formData = { ...this.state.T_General };

        const now = new Date().toISOString();

        var fechaEstado = moment
            .parseZone(now.split("T")[0])
            .format("YYYY-MM-DD");
        var check = (moment(fechaEstado, "YYYY-MM-DD"));
        var monthEstado = Number(check.format('M'));
        var yearEstado = check.year();

        var currentDate = moment().format("YYYY-MM-DDTHH:mm:ss");

        var partes = currentDate.slice(0).split('T');


        var date = partes[0]
        var hour = partes[1]

        if(this.state.T_SeguimientoAux.idAccion == '50'){

                formData['fechaAprobacion'] = date
                formData['horaAprobacion'] = hour
                formData['mesAprobacion'] = monthEstado
                formData['anioAprobacion'] = yearEstado
                formData['esAprobada'] = true
                formData['estado'] = 'APROBADA'
                formData['estadoId'] = '50'
                }


        if(this.state.T_SeguimientoAux.idAccion == '40' && this.state.T_SeguimientoAux.idAccion ){

                formData['fechaDenegacion'] = date
                formData['horaDenegacion'] = hour
                formData['mesDenegacion'] = monthEstado
                formData['anioDenegacion'] = yearEstado
                formData['esDenegada'] = true
                formData['estado'] = 'DENEGADA'
                formData['estadoId'] = '40'

            } else if (this.state.T_SeguimientoAux.idAccion == '40' && !this.state.T_SeguimientoAux.idAccion){

                  formData['fechaAprobacion'] = date
                  formData['horaAprobacion'] = hour
                  formData['mesAprobacion'] = monthEstado
                  formData['anioAprobacion'] = yearEstado
                  formData['esAprobada'] = true

                  formData['fechaDenegacion'] = date
                  formData['horaDenegacion'] = hour
                  formData['mesDenegacion'] = monthEstado
                  formData['anioDenegacion'] = yearEstado
                  formData['esDenegada'] = true
                  formData['estado'] = 'DENEGADA'
                  formData['estadoId'] = '40'

                }


            var estado = {

                    "usuario": this.state.userId,
                    "nombreUsuario": this.state.userName,
                    "compania": this.state.companyIdUser,
                    "fecha": date,
                    "hora": hour,
                    "mes": monthEstado,
                    "anio": yearEstado,
                    "accion": this.state.T_SeguimientoAux.accion,
                    "idAccion":  this.state.T_SeguimientoAux.idAccion,
                    "observacion": this.state.T_SeguimientoAux.observacion

                }

                this.setState({
                     T_General:formData,
                     T_Seguimiento: [...this.state.T_Seguimiento, estado],
                    saveProductButtonDisabled: false,
                    saveButtonDisabledEstado: true
                 });


    }


   ////////////////////////////////////////////////////////////////////////////////////////////////////////

    onPdf = async () => {

        const tipo = this.state.T_General.tipoEnergia;
        const id = this.state.T_General.IdOferta
        const compania = this.state.companyIdUser
        if (tipo == 'ELECTRICIDAD') {
            const win = window.open("/print/sip-propuesta-print/" + id + "/" + compania, "_blank");
            if (win != null) {
                win.focus();
            }
        } else {
            const win = window.open("/print/sipgas-comparative-print/" + id, "_blank");
            if (win != null) {
                win.focus();
            }

        }




    };


    ////////////////////// Función para subir Ficheros /////////////////////////

    onSubmit(e) {
        e.preventDefault()

        var formData = new FormData();
        for (const key of Object.keys(this.state.imgCollection)) {
            formData.append('files', this.state.imgCollection[key])
        }


        const headers = {
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W',
            "Content-Type": "multipart/form-data"
        };
        let direccionFile = "https://datapi.psgestion.es/uploadDocS3?id=" + this.state.companyIdUser + "&contrato=" + this.state.contrato;

        let direccionFileb = "http://78.47.44.246:4000/api/upload-images";
        //let direccionFileList = "https://datapi.psgestion.es/files?id="+this.state.companyIdUser;
        let direccionFileList = "https://datapi.psgestion.es/files?id=" + this.state.companyIdUser + "&contrato=" + this.state.contrato;

        //console.log(direccionFile)
        axios.post(direccionFile, formData, { headers }
        ).then(result => {
            //console.log('resultado de la subida')
            //console.log(result);
            this.setState({
                filesConvert: result.data.Contents,
                filesContrato: result.data.Contents
            })
            this.setState({ imgCollection: [] })
            this.setState({ saveButtonDisabled: true })
            this.inputEl.current.value = ''

        })

    };


    //////////////////////////////////

    onFileChange(e) {
        e.persist()

        this.setState(
            ({ imgCollection }) => ({
                imgCollection: e.target.files
            }), () => {
                this.setState(({ imgCollection }) => ({
                    imgCollection: e.target.files
                }))
            })

        //this.setState({ imgCollection: e.target.files })
        //console.log('VALOR DE SELECCION DE FILES -----> ', e.target.files)

        let tamañoFiles = e.target.files.length;
        //console.log('VALOR DE SELECCION DE FILES logitud -----> ', tamañoFiles)

        if (tamañoFiles > 0) {
            this.setState(
                ({ saveButtonDisabled }) => ({
                    saveButtonDisabled: false
                }), () => {
                    this.setState(({ saveButtonDisabled }) => ({
                        saveButtonDisabled: false
                    }))
                })

        } else {
            this.setState(
                ({ saveButtonDisabled }) => ({
                    saveButtonDisabled: true
                }), () => {
                    this.setState(({ saveButtonDisabled }) => ({
                        saveButtonDisabled: true
                    }))
                })
        }
    };


    ///////////////////////////////////////////////


    representanteSeleccionado = async (event) => {

        this.setState({
            representanteSeleccionado: event.representante,
            idRepresentante: event.representante.idRepresentante,
            T_Representante: event.representante,
            saveDataRepre: true,
            saveProductButtonDisabled: false,
        });
    };

    ////////////////////////////////////////////////////


    showFile = (blob) => {
        var newBlob = new Blob([blob], { type: "application/pdf" })
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement('a');
        link.href = data;
        link.download = "contratoGasCalordom.pdf";
        link.click();
        setTimeout(function() {
            window.URL.revokeObjectURL(data);
        }, 100);
    }



    render() {
        const disableExport =
            this.state.isLoadingSips ||
            this.state.isLoadingConsumos ||
            this.state.isLoadingConsumosTotales ||
            this.state.isLoadingSips;
        const disableExportClass = disableExport ? " disabled" : "";
        const form: any = this.state.contratoDetail;

        const formDetalle: any = this.state.contratoDetailTotal;

        const form_T_General: any = this.state.T_General;
        const form_T_Titular: any = this.state.T_Titular;
        const form_T_Representante: any = this.state.T_Representante;
        const form_T_PuntoSuministro: any = this.state.T_PuntoSuministro;
        const form_T_CUPs: any = this.state.T_CUPs;
        const form_T_PropuestaComercial: any = this.state.T_PropuestaComercial;
        const form_T_DatosAgenteComercial: any = this.state.T_DatosAgenteComercial;;
        const form_contratoDataGeneralElectricidad: any = this.state.contratoDataGeneralElectricidad;
        const form_contratoDataGeneralGas: any = this.state.contratoDataGeneralGas;

        const formRepre: any = this.state.T_Representante;
        //console.log('VALOR DE THIS:STATE EN RENDER SIPDETAILSCONTRATOS ------> ', this.state)
        //console.log('VALOR DE FORM ---------> ', form)

        const fechaDenegacion = this.state.fechaDenegacion
        const fechaFirmaContrato = this.state.fechaFirmaContrato
        const fechaVencimientoContrato = this.state.fechaVencimientoContrato


        var textoTipoContrato = '';
        if (this.state.tipoContrato == 'GAS') {
            textoTipoContrato = 'Gas';
        } else {
            textoTipoContrato = 'Eléctrico';
        }



        let freeModal = <></>;


        var iconAtencion;

        if (this.state.T_PropuestaComercial.nombreServicio) {
            iconAtencion = this.state.T_PropuestaComercial.nombreServicio
        }
        else {
            iconAtencion = "NO"
        }

        var fechaPropuestaFormateo = ''

        if (!this.state.T_General.fechaPropuesta) {
            fechaPropuestaFormateo = "SIN DATOS"

        } else {
            fechaPropuestaFormateo = moment(this.state.T_General.fechaPropuesta).format("DD-MM-YYYY")
        }


        var fechaVencimientoContratoFormateo = ''

        if (!this.state.T_General.fechaVencimientoContrato) {
            fechaVencimientoContratoFormateo = "SIN DATOS"

        } else {
            fechaVencimientoContratoFormateo = moment(this.state.T_General.fechaVencimientoContrato).format("DD-MM-YYYY")
        }

        if (this.state.openModalComparative == true) {

            freeModal = (
                <ContratoRepresentanteFreeModal
                    isOpen={this.state.openModalComparative}
                    toggle={this.toggle}
                    cifEmpresa={this.state.contratoDetail.CIF}
                    companyId={this.state.companyIdUser}
                    representante={this.representanteSeleccionado}


                ></ContratoRepresentanteFreeModal>
            );
        }




        return (
            <>
                <Header title={'Propuesta de Suministro ' + textoTipoContrato + ' "' + this.state.contrato + '"'} />
                <Container className=" mt--7" fluid>
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <button
                                                className="btn btn-sm btn-primary"
                                                onClick={this.props.history.goBack}
                                            >
                                                Atrás
                                                                </button>
                                        </Col>

                                        <Col className="col-auto d-flex">
                                            <Card className="p-3 bg-light">
                                                <FormGroup>

                                                    <Button disabled={this.state.contratoButtonDisabled} id="gcontratoProptooltip" color="success" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                                        onClick={() => { this.contratoHandler(this.state.saveData) }}
                                                    >
                                                        <span className="btn-inner--icon">
                                                            <i className="fas fa-file-contract fa-2x" style={{ fontSize: 35 }}></i>
                                                        </span>
                                                        <span className="btn-inner--text">PROPUESTA</span>
                                                        <UncontrolledTooltip delay={0} placement="top" target="gcontratoProptooltip">
                                                            VER PROPUESTA
                                                                                    </UncontrolledTooltip>
                                                    </Button>

                                                </FormGroup>


                                            </Card>
                                        </Col>


                                        <this.tipoLectura tipoContrato={this.state.tipoContrato} />

                                    </Row>


                                </CardBody>
                            </Card>

                            <Row className="margin-reset w-100">


                                <Col md="6" xl="3">
                                    <Card className="card-stats">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-muted mb-0"
                                                    >
                                                        TARIFA
                                    </CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">
                                                        {this.state.T_PropuestaComercial.Tarifa}
                                                    </span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                                        <i className="bi bi-clock-history" />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <p className="mt-3 mb-0 text-sm">
                                                <span className="text-nowrap">Tarifa ATR</span>
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="6" xl="3">
                                    <Card className="card-stats">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-muted mb-0"
                                                    >
                                                        SERVICIOS
                                    </CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">
                                                        {iconAtencion}
                                                    </span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                                        <i className="bi bi-headset" />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <p className="mt-3 mb-0 text-sm">
                                                {" "}
                                                <span className="text-nowrap">Con servicios adicionales</span>
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col md="6" xl="3">
                                    <Card className="card-stats">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-muted mb-0"
                                                    >
                                                        FECHA PROPUESTA
                                    </CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">
                                                        {fechaPropuestaFormateo}

                                                    </span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div className="icon icon-shape bg-gradient-blue text-white rounded-circle shadow">
                                                        <i className="bi bi-calendar-event" />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <p className="mt-3 mb-0 text-sm">
                                                {" "}
                                                <span className="text-nowrap">Fecha de realización propuesta</span>
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>


                                <Col md="6" xl="3">
                                    <Card className="card-stats">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-muted mb-0"
                                                    >
                                                        ESTADO PROPUESTA
                                    </CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">

                                                        {form_T_General.estado||"SIN ESTADO"}

                                                    </span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                                        <i className="bi bi-calendar-event" />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <p className="mt-3 mb-0 text-sm">
                                                {" "}
                                                <span className="text-nowrap">Situación de la Propuesta</span>
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>

                            </Row>

                            <Card className="shadow">
                                <CardBody>
                                    <div>
                                        <CardTitle>DATOS DEL TITULAR DEL SUMINISTRO</CardTitle>
                                    </div>

                                    <LoadingOverlay
                                        active={this.state.isLoadingSips}
                                        spinner
                                        text="Cargando..."
                                    >
                                        <Row>
                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="NombreTitular">
                                                        Nombre Titular
                                                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="NombreTitular"
                                                        placeholder=""
                                                        name="NombreTitular"
                                                        onChange={this.onChangeFieldTitular}
                                                        value={form_T_Titular.NombreTitular}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="Apellido1Titular">
                                                        Primer Apellido Titular
                                                                                    </Label>
                                                    <Input
                                                        id="Apellido1Titular"
                                                        placeholder=""
                                                        name="Apellido1Titular"
                                                        onChange={this.onChangeFieldTitular}
                                                        value={form_T_Titular.Apellido1Titular}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>


                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="Apellido2Titular">
                                                        Segundo Apellido Titular
                                                                                    </Label>
                                                    <Input
                                                        id="Apellido2Titular"
                                                        placeholder=""
                                                        name="Apellido2Titular"
                                                        onChange={this.onChangeFieldTitular}
                                                        value={form_T_Titular.Apellido2Titular}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="1">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="TipoTitular">
                                                        TIPO
                                                                                    </Label>
                                                    <Input
                                                        id="TipoTitular"
                                                        placeholder=""
                                                        name="TipoTitular"
                                                        onChange={this.onChangeFieldTitular}
                                                        value={form_T_Titular.TipoTitular}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>


                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="NIF">
                                                        NIF/CIF
                                                                                    </Label>
                                                    <Input
                                                        id="NIF"
                                                        placeholder=""
                                                        name="NIF"
                                                        onChange={this.onChangeFieldTitular}
                                                        value={form_T_Titular.NIF}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                        </Row>

                                        <div>
                                            <CardTitle>DATOS DEL REPRESENTANTE</CardTitle>
                                        </div>

                                        <Row>
                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="nombreRepresentante">
                                                        Nombre Representante
                                                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="nombreRepresentante"
                                                        placeholder=""
                                                        name="nombreRepresentante"
                                                        onChange={this.onChangeFieldRepre}
                                                        value={form_T_Representante.nombreRepresentante}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="apellido1Representante">
                                                        Primer Apellido
                                                                                    </Label>
                                                    <Input
                                                        id="apellido1Representante"
                                                        placeholder=""
                                                        name="apellido1Representante"
                                                        onChange={this.onChangeFieldRepre}
                                                        value={form_T_Representante.apellido1Representante}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>


                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="apellido2Representante">
                                                        Segundo Apellido
                                                                                    </Label>
                                                    <Input
                                                        id="apellido2Representante"
                                                        placeholder=""
                                                        name="apellido2Representante"
                                                        onChange={this.onChangeFieldRepre}
                                                        value={form_T_Representante.apellido2Representante}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>



                                            <Col>
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="NIFRepresentante">
                                                        NIF/CIF
                                                                                    </Label>
                                                    <Input
                                                        id="NIFRepresentante"
                                                        placeholder=""
                                                        name="NIFRepresentante"
                                                        onChange={this.onChangeFieldRepre}
                                                        value={form_T_Representante.NIFRepresentante}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                    {this.state.CIFRepreError ? <FormText color="red">Comprobar NIF/CIF</FormText> : ''}
                                                </FormGroup>
                                            </Col>


                                        </Row>
                                        <Row>

                                            <Col md="2" sm="2" lg="2" >
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="CargoRepresentante">
                                                        Cargo
                                                                                    </Label>
                                                    <Input
                                                        id="CargoRepresentante"
                                                        placeholder=""
                                                        name="CargoRepresentante"
                                                        onChange={this.onChangeFieldRepre}
                                                        value={form_T_Representante.CargoRepresentante}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="telefonoRepresentante">
                                                        Teléfono Contacto
                                                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="telefonoRepresentante"
                                                        placeholder=""
                                                        name="telefonoRepresentante"
                                                        onChange={this.onChangeFieldRepre}
                                                        value={form_T_Representante.telefonoRepresentante}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="correoRepresentante">
                                                        Correo Contacto
                                                                                    </Label>
                                                    <Input
                                                        id="correoRepresentante"
                                                        placeholder=""
                                                        name="correoRepresentante"
                                                        onChange={this.onChangeFieldRepre}
                                                        value={form_T_Representante.correoRepresentante}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="IdOferta">
                                                        ID. Oferta
                                                                                    </Label>
                                                    <Input
                                                        id="IdOferta"
                                                        placeholder=""
                                                        name="IdOferta"
                                                        onChange={this.onChangeField}
                                                        value={form_T_General.IdOferta}
                                                        type="text"
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="fechaPropuesta">
                                                        Fecha Propuesta
                                                                                    </Label>
                                                    <Input
                                                        id="fechaPropuesta"
                                                        placeholder=""
                                                        name="fechaPropuesta"
                                                        onChange={this.onChangeField}
                                                        value={form_T_General.fechaPropuesta}
                                                        type="text"
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>

                                        </Row>

                                        <Row>
                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="TipoViaTitular">
                                                        Tipo Via
                                                                                    </Label>
                                                    <Input
                                                        id="TipoViaTitular"
                                                        placeholder=""
                                                        name="TipoViaTitular"
                                                        onChange={this.onChangeFieldTitular}
                                                        value={form_T_Titular.TipoViaTitular}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="ViaTitular">
                                                        Dirección Titular
                                                                                    </Label>
                                                    <Input
                                                        id="ViaTitular"
                                                        placeholder=""
                                                        name="ViaTitular"
                                                        onChange={this.onChangeFieldTitular}
                                                        value={form_T_Titular.ViaTitular}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>


                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="NumFincaTitular">
                                                        Nº Finca
                                                                                    </Label>
                                                    <Input
                                                        id="NumFincaTitular"
                                                        placeholder=""
                                                        name="NumFincaTitular"
                                                        onChange={this.onChangeFieldTitular}
                                                        value={form_T_Titular.NumFincaTitular}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>



                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="PortalTitular">
                                                        Portal Tit.
                                                                                    </Label>
                                                    <Input
                                                        id="PortalTitular"
                                                        placeholder=""
                                                        name="PortalTitular"
                                                        onChange={this.onChangeFieldTitular}
                                                        value={form_T_Titular.PortalTitular}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="1">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="EscaleraTitular">
                                                        ESC.
                                                                                    </Label>
                                                    <Input
                                                        id="EscaleraTitular"
                                                        placeholder=""
                                                        name="EscaleraTitular"
                                                        onChange={this.onChangeFieldTitular}
                                                        value={form_T_Titular.EscaleraTitular}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="1">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="PisoTitular">
                                                        Piso
                                                                                    </Label>
                                                    <Input
                                                        id="PisoTitular"
                                                        placeholder=""
                                                        name="PisoTitular"
                                                        onChange={this.onChangeFieldTitular}
                                                        value={form_T_Titular.PisoTitular}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="1">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="PuertaTitular">
                                                        Puerta
                                                                                    </Label>
                                                    <Input
                                                        id="PuertaTitular"
                                                        placeholder=""
                                                        name="PuertaTitular"
                                                        onChange={this.onChangeFieldTitular}
                                                        value={form_T_Titular.PuertaTitular}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="CodigoPostalTitular">
                                                        C.P. Titular
                                                                                    </Label>
                                                    <Input
                                                        id="CodigoPostalTitular"
                                                        placehclder=""
                                                        name="CodigoPostalTitular"
                                                        onChange={this.onChangeFieldTitular}
                                                        value={form_T_Titular.CodigoPostalTitular}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <FormGroup>
                                                    <Label className="form-control-label">
                                                        Municipio:
                                                                                    </Label>
                                                    <Input
                                                        placeholder=""
                                                        //                     invalid={forceUseLocality}
                                                        id="MunicipioTitular"
                                                        name="MunicipioTitular"
                                                        onChange={this.onChangeFieldTitular}
                                                        value={form_T_Titular.MunicipioTitular}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>


                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="ProvinciaTitular">
                                                        Provincia
                                                                                    </Label>
                                                    <Input
                                                        id="ProvinciaTitular"
                                                        placeholder=""
                                                        name="ProvinciaTitular"
                                                        onChange={this.onChangeFieldTitular}
                                                        value={form_T_Titular.ProvinciaTitular}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="AutonomiaTitular">
                                                        C. Autónoma
                                                                                    </Label>
                                                    <Input
                                                        id="AutonomiaTitular"
                                                        placeholder=""
                                                        name="AutonomiaTitular"
                                                        onChange={this.onChangeFieldTitular}
                                                        value={form_T_Titular.AutonomiaTitular}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>


                                    </LoadingOverlay>
                                </CardBody>
                            </Card>
                            {this.state.alert}
                            <Card className="mt-3 shadow">
                                <CardBody>
                                    <CardTitle>DATOS DEL PUNTO DE SUMINISTRO</CardTitle>

                                    <Row>
                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="TipoViaPS">
                                                    Tipo Via
                                                                                </Label>
                                                <Input
                                                    id="TipoViaPS"
                                                    placeholder=""
                                                    name="TipoViaPS"
                                                    onChange={this.onChangeFieldPuntoSuministro}
                                                    value={form_T_PuntoSuministro.TipoViaPS}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="6">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="ViaPS">
                                                    Dirección Suministro
                                                                                </Label>
                                                <Input
                                                    id="ViaPS"
                                                    placeholder=""
                                                    name="ViaPS"
                                                    onChange={this.onChangeFieldPuntoSuministro}
                                                    value={form_T_PuntoSuministro.ViaPS}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>


                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="NumFincaPS">
                                                    Nº Finca
                                                                                </Label>
                                                <Input
                                                    id="NumFincaPS"
                                                    placeholder=""
                                                    name="NumFincaPS"
                                                    onChange={this.onChangeFieldPuntoSuministro}
                                                    value={form_T_PuntoSuministro.NumFincaPS}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>



                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="PortalPS">
                                                    Portal
                                                                                </Label>
                                                <Input
                                                    id="PortalPS"
                                                    placeholder=""
                                                    name="PortalPS"
                                                    onChange={this.onChangeFieldPuntoSuministro}
                                                    value={form_T_PuntoSuministro.PortalPS}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="1">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="EscaleraPS">
                                                    ESC.
                                                                                </Label>
                                                <Input
                                                    id="EscaleraPS"
                                                    placeholder=""
                                                    name="EscaleraPS"
                                                    onChange={this.onChangeFieldPuntoSuministro}
                                                    value={form_T_PuntoSuministro.EscaleraPS}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="1">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="PisoPS">
                                                    Piso
                                                                                </Label>
                                                <Input
                                                    id="PisoPS"
                                                    placeholder=""
                                                    name="PisoPS"
                                                    onChange={this.onChangeFieldPuntoSuministro}
                                                    value={form_T_PuntoSuministro.PisoPS}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="1">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="PuertaPS">
                                                    Puerta
                                                                                </Label>
                                                <Input
                                                    id="PuertaPS"
                                                    placeholder=""
                                                    name="PuertaPS"
                                                    onChange={this.onChangeFieldPuntoSuministro}
                                                    value={form_T_PuntoSuministro.PuertaPS}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="CodigoPostalPS">
                                                    C.P.
                                                                                </Label>
                                                <Input
                                                    id="CodigoPostalPS"
                                                    placeholder=""
                                                    name="CodigoPostalPS"
                                                    onChange={this.onChangeFieldPuntoSuministro}
                                                    value={form_T_PuntoSuministro.CodigoPostalPS}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="3">
                                            <FormGroup>
                                                <Label className="form-control-label">
                                                    Localidad:
                                                                                </Label>
                                                <Input
                                                    placeholder=""
                                                    //                     invalid={forceUseLocality}
                                                    id="NombreMunicipioPS"
                                                    name="NombreMunicipioPS"
                                                    onChange={this.onChangeFieldPuntoSuministro}
                                                    value={form_T_PuntoSuministro.NombreMunicipioPS}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>


                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="NombreProvinciaPS">
                                                    Provincia
                                                                                </Label>
                                                <Input
                                                    id="NombreProvinciaPS"
                                                    placeholder=""
                                                    name="NombreProvinciaPS"
                                                    onChange={this.onChangeFieldPuntoSuministro}
                                                    value={form_T_PuntoSuministro.NombreProvinciaPS}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="3">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="AutonomiaPS">
                                                    C. Autónoma
                                                                                </Label>
                                                <Input
                                                    id="AutonomiaPS"
                                                    placeholder=""
                                                    name="AutonomiaPS"
                                                    onChange={this.onChangeFieldPuntoSuministro}
                                                    value={form_T_PuntoSuministro.AutonomiaPS}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>

                                    </Row>


                                    <this.tipoCup tipoCup={this.state.tipoContrato} />
                                    <Row>

                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="fechaPropuesta">
                                                    Entrada Propuesta
                                                                                </Label>
                                                <Input
                                                    id="fechaPropuesta"
                                                    placeholder=""
                                                    name="fechaPropuesta"
                                                    onChange={this.onChangeField}
                                                    value={moment(form_T_General.fechaPropuesta).format("DD-MM-YYYY")}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="fechaAprobacion">
                                                    Aprobación Propuesta
                                                </Label>
                                                <Input
                                                    id="fechaAprobacion"
                                                    placeholder=""
                                                    name="fechaAprobacion"
                                                    onChange={this.onChangeField}
                                                    value={moment(form_T_General.fechaAprobacion, 'YYYY-MM-DD', true).isValid() ? moment(form_T_General.fechaAprobacion, 'YYYY-MM-DD').format('DD-MM-YYYY') : ""}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="fechaDenegacion">
                                                    Propuesta Rechazada
                                                </Label>
                                                <Input
                                                    id="fechaDenegacion"
                                                    placeholder=""
                                                    name="fechaDenegacion"
                                                    onChange={this.onChangeField}
                                                    value={moment(form_T_General.fechaDenegacion, 'YYYY-MM-DD', true).isValid() ? moment(form_T_General.fechaDenegacion, 'YYYY-MM-DD').format('DD-MM-YYYY') : ""}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>


                                    </Row>

                                    <Row>

                                        <Col md="3">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="Comercializadora">
                                                    Comercializadora
                                                                                </Label>
                                                <Input
                                                    id="Comercializadora"
                                                    placeholder=""
                                                    name="Comercializadora"
                                                    onChange={this.onChangeField}
                                                    value={form_T_PropuestaComercial.Comercializadora}
                                                    type="text"
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="3">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="Producto">
                                                    Producto
                                                                                </Label>
                                                <Input
                                                    id="Producto"
                                                    placeholder=""
                                                    name="Producto"
                                                    onChange={this.onChangeField}
                                                    value={form_T_PropuestaComercial.Producto}
                                                    type="text"
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="1">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="TipoPrecio">
                                                    Precio
                                                                                </Label>
                                                <Input
                                                    id="TipoPrecio"
                                                    placeholder=""
                                                    name="TipoPrecio"
                                                    onChange={this.onChangeField}
                                                    value={form_T_PropuestaComercial.TipoPrecio}
                                                    type="text"
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="nombreServicio">
                                                    Servicio Adicional
                                                                                </Label>
                                                <Input
                                                    id="nombreServicio"
                                                    placeholder=""
                                                    name="nombreServicio"
                                                    onChange={this.onChangeField}
                                                    value={form_T_PropuestaComercial.nombreServicio}
                                                    type="text"
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="1">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="GastoOperativo">
                                                    CO En. c€/kw
                                                                                </Label>
                                                <Input
                                                    id="GastoOperativo"
                                                    placeholder=""
                                                    name="GastoOperativo"
                                                    onChange={this.onChangeField}
                                                    value={form_T_PropuestaComercial.GastoOperativo}
                                                    type="text"
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="1">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="GastoOperativoPotencia">
                                                    CO Pot. c€/kw
                                                                                </Label>
                                                <Input
                                                    id="GastoOperativoPotencia"
                                                    placeholder=""
                                                    name="GastoOperativoPotencia"
                                                    onChange={this.onChangeField}
                                                    value={form_T_PropuestaComercial.GastoOperativoPotencia}
                                                    type="text"
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>


                                    </Row>
                                    <Row>

                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="TelefonoPS">
                                                    Teléfono Contacto PS
                                                                    </Label>
                                                <Input
                                                    id="TelefonoPS"
                                                    placeholder=""
                                                    name="TelefonoPS"
                                                    onChange={this.onChangeField}
                                                    value={form.TelefonoPS}
                                                    type="text"
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>




                            <div id="tablaLecturas">
                                {
                                    this.state.show &&
<React.Fragment>
    <Card className="mt-3 shadow">
        <CardBody>
<CardTitle>ESTADOS DEL PUNTO DE SUMINISTRO</CardTitle>


                                    <DataTable
                                        columns={columnsEstados(this.handleButtonClick)}
                                        data={this.state.T_Seguimiento}
                                        sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                        noDataComponent={"Sin datos para mostrar"}
                                        pagination
                                        paginationPerPage={10}
                                        paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
                                        highlightOnHover
                                        striped
                                        pointerOnHover
                                        selectableRowsHighlight
                                        customStyles={customStyles}
                                        subHeader
                                        subHeaderComponent={
                                            <div style={{ display: 'flex', alignItems: 'center' }}>

                                                <CSVLink
                                                    data={this.state.LecturasContrato}
                                                    filename={"FicherosLecturas.csv"}
                                                    className="btn btn-default btn-sm btn-circle"
                                                    color="default"
                                                    target="_blank"
                                                    separator={";"}
                                                >

                                                    <span className="btn-inner--icon">
                                                        <i className={"bi bi-file-earmark-spreadsheet"} style={{ fontSize: 25 }}></i>
                                                    </span>
                                                    <span className="btn-inner--text">Fichero CSV</span>

                                                </CSVLink>
                                            </div>

                                        }

                                    />
                                </CardBody>
                            </Card>
</React.Fragment>
                                }

                            </div>






                            <Card className="mt-3 shadow">
                                <CardBody>
                                    <CardTitle>TARIFAS DE LA PROPUESTA PRESENTADA</CardTitle>


<this.tipoPrecioTarifa tipoCup={this.state.tipoContrato} />


                            </CardBody>
                            </Card>

                            <Card className="mt-3 shadow">
                                <CardBody>
                                    <CardTitle>DOCUMENTOS ASOCIADOS AL CONTRATO DE SUMINISTRO</CardTitle>

                                    <Row id="contratos_resumen" className="card-deck mt-2">
                                        <Card className="shadow">
                                            <DataTable
                                                title="LISTADO DE DOCUMENTOS"
                                                columns={columnsFiles(this.handleButtonClick)}
                                                data={this.state.filesContrato}
                                                sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                                noDataComponent={"Sin datos para mostrar"}
                                                pagination
                                                paginationPerPage={10}
                                                paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}

                                                highlightOnHover
                                                striped
                                                pointerOnHover
                                                selectableRowsHighlight

                                                customStyles={customStyles}

                                            />
                                        </Card>
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                </Container>

                {freeModal}
            </>
        );
    }
}


export default withApollo(SipDetallesPropuestas);
