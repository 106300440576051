import gql from "graphql-tag";


export const CLIENT_DATA = gql`
query getClientData($companyId: String!, $CIF: String!) {
	getClientData(companyId: $companyId, CIF: $CIF) {
idCliente
idTipoTitular
descIdTipoTitular
nombreTitular
apellido1Titular
apellido2Titular
codigoProvinciaTitular
desProvinciaTitular
codigoPostalTitular
municipioTitular
desMunicipioTitular
tipoViaTitular
viaTitular
numFincaTitular
portalTitular
escaleraTitular
pisoTitular
puertaTitular
esViviendaHabitual
CNAE
actividadCNAE
CIF
codigoAutonomiaPS
desAutonomiaPS
idBanco
descBanco
iban
idCompany
correoElectronico
telefonoFijo
telefonoMovil

	}
}
`;

export const DATA_COMPANY_COMPARATIVA_FULL = gql`
query comparativaFullCompanyData($companyId: String!, $datos: ComparativaLibreInput!) {
	comparativaFullCompanyData(companyId: $companyId, datos: $datos) {
		companyId
		totalContratos
		totalAceptados
		ultimaFecha
		comercial
	}
}
`;

export const DATA_GENERAL_COMPANY = gql`
query comparativaInfoById($companyId: String!, $userId: String!, $datos: ComparativaLibreInput!) {
	comparativaInfoById(companyId: $companyId, userId: $userId, datos: $datos) {
		id
		nombreOferta
		fechaPropuesta
		esAprobada
		empleado
		datosContrato
		fechaAprobacion
		idOferta
		ofertaTipo
		ofertaTipoId
		nombreOfertaComercial
		tipo

	}
}
`;

export const DATA_ULTIMOS_CONTRATOS = gql`
query comparativasUltimas($companyId: String!, $userId: String!) {
	comparativasUltimas(companyId: $companyId, userId: $userId) {
		id
		tipo
		nombreOferta
		esAprobada
		fechaPropuesta
		empleado
	}
}
`;

export const DATA_COMPANY_COMPARATIVA_LIBRE = gql`
query comparativaLibreCompanyData($companyId: String!) {
	comparativaLibreCompanyData(companyId: $companyId) {
		companyId
		totalContratos
		totalAceptados
		ultimaFecha
	}
}
`;

export const DATA_OFERTA = gql`
query comparativaInfoOferta($idOferta: String!, $userId: String!, $userGI: String!) {
	comparativaInfoOferta(idOferta: $idOferta, userId: $userId, userGI: $userGI) {
		id
		userId
		cups
		nombreOferta
		datosPreciosActual
		fechaPropuesta
		codigoPostalPS
		nombreMunicipioPS
		nombreProvinciaPS
		nombreAutonomiaPS
		esAprobada
		datosPropuesta
		correoContacto
	}
}
`;

export const GET_DATOS_PROPUESTA = gql`
query datosPropuesta($companyId: String!) {
	datosPropuesta(companyId: $companyId) {
		datosPropuesta
	}
}
`;

export const CHART_TOTAL = gql`
query totalTrimestre($time: String!, $userId: String!, $companyId: String!) {
    totalTrimestre(time: $time, userId: $userId, companyId: $companyId) {
	    label: numeroMes
        data: totalContratos
        anio
    }
}
`;

export const CHART_ACEPTADOS = gql`
query ($time: String!, $userId: String!, $companyId: String!) {
    aceptadosTrimestre(time: $time, userId: $userId, companyId: $companyId) {
	    mes: numeroMes
        totalAceptados: totalAceptados
        anio
				totalEnEspera: totalEnEspera
    }
}
`;

export const CHART_ACEPTADOS_BIS = gql`
query ($time: String!, $userId: String!, $companyId: String!) {
    aceptadosTrimestre(time: $time, userId: $userId, companyId: $companyId) {
	    label: numeroMes
        data: totalAceptados
        anio
    }

    esperaTrimestre(time: $time, userId: $userId, companyId: $companyId) {
	    label: numeroMes
        data: totalEspera
        anio
    }
}
`;


export const CHART_OFERTA_VENDIDA = gql`
query ofertaVendida($time: String!, $companyId: String!) {
    ofertaVendida(time: $time, companyId: $companyId) {
	    label: nombreOferta
        data: numeroAprobados
    }
}
`;

export const CHART_OFERTA_PROVINCIA = gql`
query ofertaProvincia($time: String!, $companyId: String!) {
    ofertaProvincia(time: $time, companyId: $companyId) {
	    label: nombreProvinciaPS
        data: totalContratos
    }
}
`;

export const GET_MEDIA_COMERCIALIZADORA = gql`
query mediaComercializadora($companyId : String!, $marketer: String!, $time: String!) {
    mediaComercializadora(companyId : $companyId, marketer: $marketer, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }
}
`;

export const GET_MEDIA_PROPUESTAS = gql`
query mediaPropuestas($companyId: String!, $time: String!) {
    mediaPropuestas(companyId: $companyId, time: $time) {
	    label: numeroMes
        data: mediaPrecio
    }
}
`;

export const GET_MEDIA_COMERCIAL = gql`
query mediaComercial($companyId: String!, $time: String!, $comercial: String!) {
    mediaComercial(companyId: $companyId, time: $time, comercial: $comercial) {
	    label: comercial
        data: mediaPrecio
    }
}
`;

export const GET_COMERCIALIZADORA_PROVINCIA = gql`
query comercializadoraProvincia($companyId: String!, $time: String!) {
    comercializadoraProvincia(companyId: $companyId, time: $time) {
	    label: nombreProvinciaPS
        data: mediaPrecio
        nombreComercializadora
    }
}
`;

export const GET_PROVINCIA_COMERCIALIZADORA = gql`
query provinciaComercializadora($companyId: String!, $time: String!) {
    provinciaComercializadora(companyId: $companyId, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
        nombreProvinciaPS
    }
}
`;

// **************************************************************************************************

export const GET_AVERAGE_P20A = gql`
query ($companyId: String!, $codeATR: String!, $time: String!) {
    periodo1(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }
}
`;

export const GET_AVERAGE_P30A = gql`
query ($companyId: String!, $codeATR: String!, $time: String!) {
    periodo1(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }

    periodo2(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }

    periodo3(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }
}
`;

export const GET_AVERAGE_P21A = gql`
query mediaP21A($companyId: String!, $codeATR: String!, $time: String!) {
    periodo1(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }
}
`;

export const GET_AVERAGE_P20DHS = gql`
query ($companyId: String!, $codeATR: String!, $time: String!) {
    periodo1(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }
    periodo2(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }
    periodo3(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }
}
`;

export const GET_AVERAGE_P21DHS = gql`
query ($companyId: String!, $codeATR: String!, $time: String!) {
    periodo1(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }
    periodo2(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }
    periodo3(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }
}
`;

export const GET_AVERAGE_P31A = gql`
query ($companyId: String!, $codeATR: String!, $time: String!) {
    periodo1(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }
    periodo2(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }
    periodo3(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }
}
`;

export const GET_AVERAGE_P20DHA = gql`
query ($companyId: String!, $codeATR: String!, $time: String!) {
    periodo1(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }
    periodo2(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }
}
`;

export const GET_AVERAGE_P21DHA = gql`
query ($companyId: String!, $codeATR: String!, $time: String!) {
    periodo1(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }
    periodo2(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }
}
`;
