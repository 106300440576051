import React, { useState} from "react";

// Apollo
import { Query, withApollo } from "react-apollo";
import { chartOptions } from "../../variables/charts";
import Header from "../../components/Headers/Header";
import { Bar, Doughnut } from 'react-chartjs-2';
import graphql2chartjs from "graphql2chartjs";

import {
	GET_COMERCIALIZADORA_PROVINCIA,
	GET_PROVINCIA_COMERCIALIZADORA,
	GET_MEDIA_COMERCIALIZADORA,
	CHART_OFERTA_PROVINCIA,
	CHART_OFERTA_VENDIDA,
	GET_MEDIA_PROPUESTAS,
	GET_AVERAGE_P20A,
	GET_AVERAGE_P30A,
	GET_AVERAGE_P21A,
	GET_AVERAGE_P20DHS,
	GET_AVERAGE_P21DHS,
	GET_AVERAGE_P31A,
	GET_AVERAGE_P20DHA,
	GET_AVERAGE_P21DHA,
	GET_MEDIA_COMERCIAL,
	CHART_ACEPTADOS,
	CHART_TOTAL
} from "../../queries/sips-comparativa/sip-comparativa-general";

import {
	Button, Label,
	Card, CardBody,
	Col, CardTitle,
	Container, Form,
	Row, Input, FormGroup,
} from "reactstrap";

import formatData from "../../utils/formatData";
import randomColor from "../../utils/randomColor";
import { LIST_MARKETERS, LIST_USER, LIST_USERS } from "../../components/Sips/SipsOption.graphql";

const PropuestasControlPanel = (props) => {
	const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
	const colors = ["#74B7FB", "#FBE888", "#A2037B"];
	const userFromStorage = JSON.parse(localStorage.getItem("user") || "{}");
	const [comercializadora, setComercializadora] = useState("");
	const [filters, setFilters] = useState({
		periodoTiempo : "3",
		comercial : "",
		changed : false
	});
	const terminacionTiempo = (
		filters.periodoTiempo === "1" ? "en el último mes" : // if
		filters.periodoTiempo === "3" ? "en el último trimestre" : // else if
		filters.periodoTiempo === "6" ? "en el último semestre" : // else if
		filters.periodoTiempo === "12" ? "en el último año" : "en total"
	);
	// ************************************************************************
	let dataMostSoldOffer = null;
	let dataProvinceQuery = null;
	let dataContractsCount = null;
	let dataAcceptedCount = null;
	let dataAverageMarketers = null;
	let dataAveragePrize = null;
	let dataAverageCommercial = null;
	let dataMarketerProvince = null;
	let dataProvinceMarketer = null;
	// ************************************************************************
	let dataAverageP20A = null;
	let dataAverageP30A = null;
	let dataAverageP21A = null;
	let dataAverageP20DHS = null;
	let dataAverageP21DHS = null;
	let dataAverageP31A = null;
	let dataAverageP20DHA = null;
	let dataAverageP21DHA = null;

	const getParams = () => {
		if(props.location.search && !filters.changed) {
			let filtros = filters;
			let parts = props.location.search.split("&");
			parts.forEach(function(part, index) {
				let val = part.split("=");
				filtros.changed = true;
				if(val[0] === "comercial" || val[0] === "?comercial") {
					filtros.comercial = val[1].trim();
				} else if(val[0] === "radioTiempo" || val[0] === "?radioTiempo") {
					filtros.periodoTiempo = val[1].trim();
				}
			});
			setFilters(filtros);
		}
	};

	const CSVExport = (res) => {
		let CsvString = "";
		if(res) res.forEach(function(RowItem, RowIndex) {
			// @ts-ignore
			RowItem.forEach(function(ColItem, ColIndex) {
				CsvString += ColItem + ",";
			});
			CsvString += "\r\n";
		});
		CsvString = "data:application/csv," + encodeURIComponent(CsvString);
		let x = document.createElement("A");
		x.setAttribute("href", CsvString);
		x.setAttribute("download", "datos.csv");
		document.body.appendChild(x);
		x.click();
	}

	const QueryUsers =  () => {
		if(userFromStorage.user_group_id === 1000) {
			return (
				<>
					<option disabled selected> --Selecciona un comercial-- </option>
	        <Query  query={LIST_USERS} variables={{companyId : userFromStorage.companyId.toString()}}>
	            {({data}) => {
		            if(data && data.listUsers.datos !== 0) {
			            return data.listUsers.map((item, key) => (
				            <option key={key} value={item.id}>{item.first_name + " " + item.last_name}</option>
			            ));
		            }

		            return null;
	            }}
	        </Query>
				</>
			);
		} else {
			return (
				<>
	        <Query query={LIST_USER} variables={{
		        userId : userFromStorage.id,
		        companyId : userFromStorage.companyId.toString()
	        }}>
	            {({data}) => {
		            if(data && data.listUser.datos !== 0) {
			            return data.listUser.map((item, key) => (
				            <option disabled selected key={key} value={item.id}>{item.first_name + " " + item.last_name}</option>
			            ));
		            }

		            return null;
	            }}
	        </Query>
				</>
			);
		}
	}

	const QueryMarketers = () => {
		return (
			<>
				<option disabled selected value=''> -- Selecciona una opción -- </option>
				<Query query={LIST_MARKETERS}>
					{({data}) => {
						if(data && data.listMarketers.datos !== 0) {
							return data.listMarketers.map((item, key) => (
								<option key={key} value={item.Id}>{item.Empresa}</option>
							));
						}

						return null;
					}}
				</Query>
			</>
		);
	}

	const MostSoldOffer = (csv) => {
		if(csv === true) {
			CSVExport(dataMostSoldOffer);
			return (<></>);
		} else {
			return (
				<>
				<Query query={CHART_OFERTA_VENDIDA} variables={{
					time : filters.periodoTiempo,
					userId : filters.comercial,
					companyId : userFromStorage.companyId.toString()
				}}>
					{({data}) => {
						if(data) {
							let datos = [];

							data.ofertaVendida.forEach(value => {
								let existe = false;
								if(datos.length > 0) {
									datos.forEach(value2 => {
										// @ts-ignore
										if(value.label === value2.label) {
											// @ts-ignore
											value2.data = (parseInt(value2.data) + parseInt(value.data)).toString();
											existe = true;
											return false;
										}
									});
									if(!existe) {
										datos.push({
											// @ts-ignore
											label : value.label,
											// @ts-ignore
											data : value.data
										})
									}
								} else {
									datos.push({
										// @ts-ignore
										label : value.label,
										// @ts-ignore
										data : value.data
									})
								}
							});

							if(!dataMostSoldOffer) {
								var outputData = datos.map(Object.values);
								// @ts-ignore
								dataMostSoldOffer = outputData;
							}

							if(datos.length > 9) {
								datos = datos.slice(0, 9);
							}
							const g2c = new graphql2chartjs();
							g2c.add({ofertaVendida : datos}, (dataSetName, dataPoint) => {
								return {
									...dataPoint,
									chartType : "bar",
									borderColor : "#ffffff",
									fill : false
								};
							});

							if(g2c.data.datasets[0].borderColor && g2c.data.datasets[0].borderColor.length > 1) {
								g2c.data.datasets[0].borderColor.forEach((value, index) => {
									if(!g2c.data.datasets[0].backgroundColor) {
										g2c.data.datasets[0].backgroundColor = [];
									} else {
										g2c.data.datasets[0].backgroundColor.push(randomColor());
									}
								})
							}
							const opt = chartOptions();
							return (
								<Bar data={g2c.data} options={opt.defaults.global}/>
							);
						}
						return null;
					}}
	            </Query>
			</>
			);
		}
	}

	const ProvinceQuery = (csv) => {
		if(csv === true) {
			CSVExport(dataProvinceQuery);
			return (<></>);
		} else {
			return (
				<>
					<Query query={CHART_OFERTA_PROVINCIA} variables={{
						time : filters.periodoTiempo,
						companyId : userFromStorage.companyId.toString()
					}}>
						{({data}) => {
							if(data) {
								const g2c = new graphql2chartjs();

								if(!dataProvinceQuery) {
									var outputData = data.ofertaProvincia.map(Object.values);
									// @ts-ignore
									dataProvinceQuery = outputData;
								}

								if(data.ofertaProvincia.length > 9) {
									data.ofertaProvincia = data.ofertaProvincia.slice(0, 9);
								}
								g2c.add(data, (dataSetName, dataPoint) => {
									return {
										...dataPoint,
										chartType : "doughnut",
										borderColor : "#ffffff",
										fill : false,
										label : dataPoint.label + ': ' + dataPoint.data
									};
								});
								if(g2c.data.datasets[0].borderColor && g2c.data.datasets[0].borderColor.length > 1) {
									g2c.data.datasets[0].borderColor.forEach((value, index) => {
										if(!g2c.data.datasets[0].backgroundColor) {
											g2c.data.datasets[0].backgroundColor = [];
										} else {
											g2c.data.datasets[0].backgroundColor.push(randomColor());
										}
									})
								}
								return (
									<Doughnut data={g2c.data} options={{
										legend : {
											display : true,
											position : "right"
										}
									}}/>
								);
							}
							return null;
						}}
		            </Query>
				</>
			);
		}
	}

	const ContractsCount = (csv) => {
		if(csv === true) {
			CSVExport(dataContractsCount);
			return (<></>);
		} else {
			console.log("Entra query");
			return (
				<>
					<Query query={CHART_TOTAL} variables={{
						time : filters.periodoTiempo,
						userId : filters.comercial,
						companyId : userFromStorage.companyId.toString()
					}}>
						{({data}) => {
							if(data) {
								if(!dataContractsCount) {
									var outputData = data.totalTrimestre.map(Object.values);
									// @ts-ignore
									dataContractsCount = outputData;
								}

								const chartData = formatData(data);
								const datos = {totalTrimestre : chartData};
								const g2c = new graphql2chartjs(datos, "bar");
								const opt = chartOptions();
								return (
									<Bar data={g2c.data} options={opt.defaults.global}/>
								);
							}
							return null;
						}}
		            </Query>
				</>
			);
		}
	}

	const AcceptedCount = (csv) => {
		if(csv === true) {
			CSVExport(dataAcceptedCount);
			return (<></>);
		} else {
			return (
				<>
					<Query query={CHART_ACEPTADOS} variables={{
						time : filters.periodoTiempo,
						userId : filters.comercial,
						companyId : userFromStorage.companyId.toString()
					}}>
						{({data}) => {
							if(data) {
								if(!dataAcceptedCount) {
									var outputData = data.aceptadosTrimestre.map(Object.values);
									// @ts-ignore
									dataAcceptedCount = outputData;
								}

								if(data.aceptadosTrimestre.length > 9) {
									data.aceptadosTrimestre = data.aceptadosTrimestre.slice(0, 9);
								}
								const g2c = new graphql2chartjs();
								g2c.add(data, (dataSetName, dataPoint) => {
									if(dataSetName === "aceptadosTrimestre") {
										// return bar chart properties for article likes
										return {
											...dataPoint,
											chartType : "bar",
											borderColor : "#00ff42",
											pointBackgroundColor : "#009f08",
											backgroundColor : "#009f08",
											fill : false,
											label : months[parseInt(dataPoint.label) - 1]
										};
									}
									// return line chart properties for article comments
									return {
										...dataPoint,
										chartType : "bar",
										borderColor : "#d90724",
										pointBackgroundColor : "#8f4040",
										backgroundColor : "#8f4040",
										fill : false,
										label : months[parseInt(dataPoint.label) - 1]
									};
								});
								const opt = chartOptions();
								return (
									<Bar data={g2c.data} options={opt.defaults.global}/>
								);
							}
							return null;
						}}
		            </Query>
				</>
			);
		}
	}

	const AverageMarketers = (csv) => {
		if(csv === true) {
			CSVExport(dataAverageMarketers);
			return (<></>);
		} else {
			return (
				<>
					<Query query={GET_MEDIA_COMERCIALIZADORA} variables={{
						companyId : userFromStorage.companyId.toString(),
						marketer : comercializadora,
						time : filters.periodoTiempo
					}}>
						{({data}) => {
							if(data) {
								const g2c = new graphql2chartjs();
								if(!dataAverageMarketers) {
									var outputData = data.mediaComercializadora.map(Object.values);
									// @ts-ignore
									dataAverageMarketers = outputData;
								}

								if(data.mediaComercializadora.length > 9) {
									data.mediaComercializadora = data.mediaComercializadora.slice(0, 9);
								}
								g2c.add(data, (dataSetName, dataPoint) => {
									return {
										...dataPoint,
										chartType : "bar",
										borderColor : "#fff",
										fill : false
									};
								});

								if(g2c.data.datasets[0].borderColor && g2c.data.datasets[0].borderColor.length > 1) {
									g2c.data.datasets[0].borderColor.forEach((value, index) => {
										if(!g2c.data.datasets[0].backgroundColor) {
											g2c.data.datasets[0].backgroundColor = [];
										} else {
											g2c.data.datasets[0].backgroundColor.push(randomColor());
										}
									})
								}
								const opt = chartOptions();
								return (
									<Bar data={g2c.data} options={opt.customized.global}/>
								);
							}
							return null;
						}}
		            </Query>
				</>
			);
		}
	}

	const AveragePrize = (csv) => {
		if(csv === true) {
			CSVExport(dataAveragePrize);
			return (<></>);
		} else {
			return (
				<>
					<Query query={GET_MEDIA_PROPUESTAS} variables={{
						companyId : userFromStorage.companyId.toString(),
						time : filters.periodoTiempo
					}}>
						{({data}) => {
							if(data) {
								const g2c = new graphql2chartjs();
								if(!dataAveragePrize) {
									var outputData = data.mediaPropuestas.map(Object.values);
									// @ts-ignore
									dataAveragePrize = outputData;
								}

								g2c.add(data, (dataSetName, dataPoint) => {
									return {
										...dataPoint,
										chartType : "bar",
										borderColor : "#fff",
										fill : false,
										label : months[parseInt(dataPoint.label) - 1]
									};
								});

								if(g2c.data.datasets[0].borderColor && g2c.data.datasets[0].borderColor.length > 1) {
									g2c.data.datasets[0].borderColor.forEach((value, index) => {
										if(!g2c.data.datasets[0].backgroundColor) {
											g2c.data.datasets[0].backgroundColor = [];
										} else {
											g2c.data.datasets[0].backgroundColor.push(randomColor());
										}
									})
								}
								const opt = chartOptions();
								return (
									<Bar data={g2c.data} options={opt.defaults.global}/>
								);
							}
							return null;
						}}
		            </Query>
				</>
			);
		}
	}

	const AverageCommercial = (csv) => {
		if(csv === true) {
			CSVExport(dataAverageCommercial);
			return (<></>);
		} else {
			return (
				<>
					<Query query={GET_MEDIA_COMERCIAL} variables={{
						companyId : userFromStorage.companyId.toString(),
						time : filters.periodoTiempo,
						comercial : filters.comercial
					}}>
					 {({data}) => {
						 if(data) {
							 const g2c = new graphql2chartjs();
							 if(!dataAverageCommercial) {
								 var outputData = data.mediaComercial.map(Object.values);
								 // @ts-ignore
								 dataAverageCommercial = outputData;
							 }

							 if(data.mediaComercial.length > 9) {
								 data.mediaComercial = data.mediaComercial.slice(0, 9);
							 }
							 g2c.add(data, (dataSetName, dataPoint) => {
								 return {
									 ...dataPoint,
									 chartType : "doughnut",
									 borderColor : "#fff",
									 fill : false,
									 label : dataPoint.label + ': ' + dataPoint.data
								 };
							 });

							 if(g2c.data.datasets[0].borderColor && g2c.data.datasets[0].borderColor.length > 1) {
								 g2c.data.datasets[0].borderColor.forEach((value, index) => {
									 if(!g2c.data.datasets[0].backgroundColor) {
										 g2c.data.datasets[0].backgroundColor = [];
									 } else {
										 g2c.data.datasets[0].backgroundColor.push(randomColor());
									 }
								 })
							 }
							 return (
								 <Doughnut data={g2c.data} options={{legend : {display : true, position : "right"}}}/>
							 );
						 }
						 return null;
					 }}
		            </Query>
				</>
			);
		}
	}

	const MarketerProvince = (csv) => {
		if(csv === true) {
			CSVExport(dataMarketerProvince);
			return (<></>);
		} else {
			return (
				<>
					<Query query={GET_COMERCIALIZADORA_PROVINCIA} variables={{
						companyId : userFromStorage.companyId.toString(),
						time : filters.periodoTiempo
					}}>
					 {({data}) => {
						 if(data) {
							 const g2c = new graphql2chartjs();
							 if(!dataMarketerProvince) {
								 var outputData = data.comercializadoraProvincia.map(Object.values);
								 // @ts-ignore
								 dataMarketerProvince = outputData;
							 }

							 if(data.comercializadoraProvincia.length > 9) {
								 data.comercializadoraProvincia = data.comercializadoraProvincia.slice(0, 9);
							 }
							 g2c.add(data, (dataSetName, dataPoint) => {
								 return {
									 ...dataPoint,
									 chartType : "bar",
									 borderColor : "#fff",
									 fill : false,
									 label : dataPoint.label + ": " + dataPoint.nombreComercializadora
								 };
							 });

							 if(g2c.data.datasets[0].borderColor && g2c.data.datasets[0].borderColor.length > 1) {
								 g2c.data.datasets[0].borderColor.forEach((value, index) => {
									 if(!g2c.data.datasets[0].backgroundColor) {
										 g2c.data.datasets[0].backgroundColor = [];
									 } else {
										 g2c.data.datasets[0].backgroundColor.push(randomColor());
									 }
								 })
							 }
							 const opt = chartOptions();
							 return (
								 <Bar data={g2c.data} options={opt.customized.global}/>
							 );
						 }
						 return null;
					 }}
		            </Query>
				</>
			);
		}
	}

	const ProvinceMarketer = (csv) => {
		if(csv === true) {
			CSVExport(dataProvinceMarketer);
			return (<></>);
		} else {
			return (
				<>
					<Query query={GET_PROVINCIA_COMERCIALIZADORA} variables={{
						companyId : userFromStorage.companyId.toString(),
						time : filters.periodoTiempo
					}}>
					 {({data}) => {
						 if(data) {
							 const g2c = new graphql2chartjs();
							 if(!dataProvinceMarketer) {
								 var outputData = data.provinciaComercializadora.map(Object.values);
								 // @ts-ignore
								 dataProvinceMarketer = outputData;
							 }

							 if(data.provinciaComercializadora.length > 9) {
								 data.provinciaComercializadora = data.provinciaComercializadora.slice(0, 9);
							 }
							 g2c.add(data, (dataSetName, dataPoint) => {
								 return {
									 ...dataPoint,
									 chartType : "bar",
									 borderColor : "#fff",
									 fill : false,
									 label : dataPoint.label + ": " + dataPoint.nombreProvinciaPS
								 };
							 });

							 if(g2c.data.datasets[0].borderColor && g2c.data.datasets[0].borderColor.length > 1) {
								 g2c.data.datasets[0].borderColor.forEach((value, index) => {
									 if(!g2c.data.datasets[0].backgroundColor) {
										 g2c.data.datasets[0].backgroundColor = [];
									 } else {
										 g2c.data.datasets[0].backgroundColor.push(randomColor());
									 }
								 })
							 }
							 const opt = chartOptions();
							 return (
								 <Bar data={g2c.data} options={opt.customized.global}/>
							 );
						 }
						 return null;
					 }}
		            </Query>
				</>
			);
		}
	}

	// *******************************************************************

	const AverageP20A = (csv) => {
		if(csv === true) {
			CSVExport(dataAverageP20A);
			return (<></>);
		} else {
			return (
				<>
					<Query query={GET_AVERAGE_P20A} variables={{
						companyId : userFromStorage.companyId.toString(),
						codeATR : '001',
						time : filters.periodoTiempo
					}}>
					 {({data}) => {
						 if(data) {
							 const g2c = new graphql2chartjs();
							 if(!dataAverageP20A) {
								 var outputData = data.periodo1.map(Object.values);
								 // @ts-ignore
								 dataAverageP20A = outputData;
							 }

							 if(data.periodo1.length > 9) {
								 data.periodo1 = data.periodo1.slice(0, 9);
							 }
							 g2c.add(data, (dataSetName, dataPoint) => {
								 return {
									 ...dataPoint,
									 chartType : "bar",
									 borderColor : "#fff",
									 fill : false
								 };
							 });

							 if(g2c.data.datasets[0].borderColor && g2c.data.datasets[0].borderColor.length > 1) {
								 g2c.data.datasets[0].borderColor.forEach((value, index) => {
									 if(!g2c.data.datasets[0].backgroundColor) {
										 g2c.data.datasets[0].backgroundColor = [];
									 } else {
										 g2c.data.datasets[0].backgroundColor.push(randomColor());
									 }
								 })
							 }

							 const opt = chartOptions();
							 return (
								 <Bar data={g2c.data} options={opt.customized.global}/>
							 );
						 }
						 return null;
					 }}
		            </Query>
				</>
			);
		}
	}

	const AverageP30A = (csv) => {
		if(csv === true) {
			CSVExport(dataAverageP30A);
			return (<></>);
		} else {
			return (
				<>
					<Query query={GET_AVERAGE_P30A} variables={{
						companyId : userFromStorage.companyId.toString(),
						codeATR : '003',
						time : filters.periodoTiempo
					}}>
					 {({data}) => {
						 if(data) {
							 let datos = [];
							 const opt = chartOptions();
							 const g2c = new graphql2chartjs();
							 if(!dataAverageP30A) {
								 Array.prototype.push.apply(datos, data.periodo1);
								 Array.prototype.push.apply(datos, data.periodo2);
								 Array.prototype.push.apply(datos, data.periodo3);
								 var outputData = datos.map(Object.values);
								 // @ts-ignore
								 dataAverageP30A = outputData;
							 }

							 if(data.periodo1.length > 9) {
								 data.periodo1 = data.periodo1.slice(0, 9);
							 }
							 if(data.periodo2.length > 9) {
								 data.periodo2 = data.periodo2.slice(0, 9);
							 }
							 if(data.periodo3.length > 9) {
								 data.periodo3 = data.periodo3.slice(0, 9);
							 }

							 g2c.add(data, (dataSetName, dataPoint) => {
								 return {
									 ...dataPoint,
									 chartType : "bar",
									 borderColor : "#fff",
									 fill : false
								 };
							 });

							 for(let i = 0 ; i < 3 ; i++) {
								 if(g2c.data.datasets[i].borderColor && g2c.data.datasets[i].borderColor.length > 1) {
									 g2c.data.datasets[i].borderColor.forEach((value, index) => {
										 if(!g2c.data.datasets[i].backgroundColor) {
											 g2c.data.datasets[i].backgroundColor = [];
										 } else {
											 g2c.data.datasets[i].backgroundColor.push(colors[i]);
										 }
									 })
								 }
							 }
							 return (
								 <Bar data={g2c.data} options={opt.customized.global}/>
							 );
						 }
						 return null;
					 }}
		            </Query>
				</>
			);
		}
	}

	const AverageP21A = (csv) => {
		if(csv === true) {
			CSVExport(dataAverageP21A);
			return (<></>);
		} else {
			return (
				<>
					<Query query={GET_AVERAGE_P21A} variables={{
						companyId : userFromStorage.companyId.toString(),
						codeATR : '003',
						time : filters.periodoTiempo
					}}>
					 {({data}) => {
						 if(data) {
							 const g2c = new graphql2chartjs();
							 if(!dataAverageP21A) {
								 var outputData = data.periodo1.map(Object.values);
								 // @ts-ignore
								 dataAverageP21A = outputData;
							 }

							 if(data.periodo1.length > 9) {
								 data.periodo1 = data.periodo1.slice(0, 9);
							 }

							 g2c.add(data, (dataSetName, dataPoint) => {
								 return {
									 ...dataPoint,
									 chartType : "bar",
									 borderColor : "#fff",
									 fill : false
								 };
							 });

							 if(g2c.data.datasets[0].borderColor && g2c.data.datasets[0].borderColor.length > 1) {
								 g2c.data.datasets[0].borderColor.forEach((value, index) => {
									 if(!g2c.data.datasets[0].backgroundColor) {
										 g2c.data.datasets[0].backgroundColor = [];
									 } else {
										 g2c.data.datasets[0].backgroundColor.push(randomColor());
									 }
								 })
							 }

							 const opt = chartOptions();
							 return (
								 <Bar data={g2c.data} options={opt.customized.global}/>
							 );
						 }
						 return null;
					 }}
		            </Query>
				</>
			);
		}
	}

	const AverageP20DHS = (csv) => {
		if(csv === true) {
			CSVExport(dataAverageP20DHS);
			return (<></>);
		} else {
			return (
				<>
					<Query query={GET_AVERAGE_P20DHS} variables={{
						companyId : userFromStorage.companyId.toString(),
						codeATR : '007',
						time : filters.periodoTiempo
					}}>
					 {({data}) => {
						 if(data) {
							 let datos = [];
							 const opt = chartOptions();
							 const g2c = new graphql2chartjs();
							 if(!dataAverageP20DHS) {
								 Array.prototype.push.apply(datos, data.periodo1);
								 Array.prototype.push.apply(datos, data.periodo2);
								 Array.prototype.push.apply(datos, data.periodo3);
								 var outputData = datos.map(Object.values);
								 // @ts-ignore
								 dataAverageP20DHS = outputData;
							 }

							 if(data.periodo1.length > 9) {
								 data.periodo1 = data.periodo1.slice(0, 9);
							 }
							 if(data.periodo2.length > 9) {
								 data.periodo2 = data.periodo2.slice(0, 9);
							 }
							 if(data.periodo3.length > 9) {
								 data.periodo3 = data.periodo3.slice(0, 9);
							 }

							 g2c.add(data, (dataSetName, dataPoint) => {
								 return {
									 ...dataPoint,
									 chartType : "bar",
									 borderColor : "#fff",
									 fill : false
								 };
							 });

							 for(let i = 0 ; i < 3 ; i++) {
								 if(g2c.data.datasets[i].borderColor && g2c.data.datasets[i].borderColor.length > 1) {
									 g2c.data.datasets[i].borderColor.forEach((value, index) => {
										 if(!g2c.data.datasets[i].backgroundColor) {
											 g2c.data.datasets[i].backgroundColor = [];
										 } else {
											 g2c.data.datasets[i].backgroundColor.push(colors[i]);
										 }
									 })
								 }
							 }
							 return (
								 <Bar data={g2c.data} options={opt.customized.global}/>
							 );
						 }
						 return null;
					 }}
		            </Query>
				</>
			);
		}
		;
	}

	const AverageP21DHS = (csv) => {
		if(csv === true) {
			CSVExport(dataAverageP21DHS);
			return (<></>);
		} else {
			return (
				<>
					<Query query={GET_AVERAGE_P21DHS} variables={{
						companyId : userFromStorage.companyId.toString(),
						codeATR : '008',
						time : filters.periodoTiempo
					}}>
					 {({data}) => {
						 if(data) {
							 let datos = [];
							 const opt = chartOptions();
							 const g2c = new graphql2chartjs();
							 if(!dataAverageP21DHS) {
								 Array.prototype.push.apply(datos, data.periodo1);
								 Array.prototype.push.apply(datos, data.periodo2);
								 Array.prototype.push.apply(datos, data.periodo3);
								 var outputData = datos.map(Object.values);
								 // @ts-ignore
								 dataAverageP21DHS = outputData;
							 }

							 if(data.periodo1.length > 9) {
								 data.periodo1 = data.periodo1.slice(0, 9);
							 }
							 if(data.periodo2.length > 9) {
								 data.periodo2 = data.periodo2.slice(0, 9);
							 }
							 if(data.periodo3.length > 9) {
								 data.periodo3 = data.periodo3.slice(0, 9);
							 }

							 g2c.add(data, (dataSetName, dataPoint) => {
								 return {
									 ...dataPoint,
									 chartType : "bar",
									 borderColor : "#fff",
									 fill : false
								 };
							 });

							 for(let i = 0 ; i < 3 ; i++) {
								 if(g2c.data.datasets[i].borderColor && g2c.data.datasets[i].borderColor.length > 1) {
									 g2c.data.datasets[i].borderColor.forEach((value, index) => {
										 if(!g2c.data.datasets[i].backgroundColor) {
											 g2c.data.datasets[i].backgroundColor = [];
										 } else {
											 g2c.data.datasets[i].backgroundColor.push(colors[i]);
										 }
									 })
								 }
							 }
							 return (
								 <Bar data={g2c.data} options={opt.customized.global}/>
							 );
						 }
						 return null;
					 }}
		            </Query>
				</>
			);
		}
		;
	}

	const AverageP31A = (csv) => {
		if(csv === true) {
			CSVExport(dataAverageP31A);
			return (<></>);
		} else {
			return (
				<>
					<Query query={GET_AVERAGE_P31A} variables={{
						companyId : userFromStorage.companyId.toString(),
						codeATR : '011',
						time : filters.periodoTiempo
					}}>
					 {({data}) => {
						 if(data) {
							 let datos = [];
							 const opt = chartOptions();
							 const g2c = new graphql2chartjs();
							 if(!dataAverageP31A) {
								 Array.prototype.push.apply(datos, data.periodo1);
								 Array.prototype.push.apply(datos, data.periodo2);
								 Array.prototype.push.apply(datos, data.periodo3);
								 var outputData = datos.map(Object.values);
								 // @ts-ignore
								 dataAverageP31A = outputData;
							 }

							 if(data.periodo1.length > 9) {
								 data.periodo1 = data.periodo1.slice(0, 9);
							 }
							 if(data.periodo2.length > 9) {
								 data.periodo2 = data.periodo2.slice(0, 9);
							 }
							 if(data.periodo3.length > 9) {
								 data.periodo3 = data.periodo3.slice(0, 9);
							 }

							 g2c.add(data, (dataSetName, dataPoint) => {
								 return {
									 ...dataPoint,
									 chartType : "bar",
									 borderColor : "#fff",
									 fill : false
								 };
							 });

							 for(let i = 0 ; i < 3 ; i++) {
								 if(g2c.data.datasets[i].borderColor && g2c.data.datasets[i].borderColor.length > 1) {
									 g2c.data.datasets[i].borderColor.forEach((value, index) => {
										 if(!g2c.data.datasets[i].backgroundColor) {
											 g2c.data.datasets[i].backgroundColor = [];
										 } else {
											 g2c.data.datasets[i].backgroundColor.push(colors[i]);
										 }
									 })
								 }
							 }
							 return (
								 <Bar data={g2c.data} options={opt.customized.global}/>
							 );
						 }
						 return null;
					 }}
		            </Query>
				</>
			);
		}
		;
	}

	const AverageP20DHA = (csv) => {
		if(csv === true) {
			CSVExport(dataAverageP20DHA);
			return (<></>);
		} else {
			return (
				<>
					<Query query={GET_AVERAGE_P20DHA} variables={{
						companyId : userFromStorage.companyId.toString(),
						codeATR : '004',
						time : filters.periodoTiempo
					}}>
					 {({data}) => {
						 if(data) {
							 let datos = [];
							 const opt = chartOptions();
							 const g2c = new graphql2chartjs();
							 if(!dataAverageP20DHA) {
								 Array.prototype.push.apply(datos, data.periodo1);
								 Array.prototype.push.apply(datos, data.periodo2);
								 var outputData = datos.map(Object.values);
								 // @ts-ignore
								 dataAverageP20DHA = outputData;
							 }

							 if(data.periodo1.length > 9) {
								 data.periodo1 = data.periodo1.slice(0, 9);
							 }
							 if(data.periodo2.length > 9) {
								 data.periodo2 = data.periodo2.slice(0, 9);
							 }

							 g2c.add(data, (dataSetName, dataPoint) => {
								 return {
									 ...dataPoint,
									 chartType : "bar",
									 borderColor : "#fff",
									 fill : false
								 };
							 });

							 for(let i = 0 ; i < 2 ; i++) {
								 if(g2c.data.datasets[i].borderColor && g2c.data.datasets[i].borderColor.length > 1) {
									 g2c.data.datasets[i].borderColor.forEach((value, index) => {
										 if(!g2c.data.datasets[i].backgroundColor) {
											 g2c.data.datasets[i].backgroundColor = [];
										 } else {
											 g2c.data.datasets[i].backgroundColor.push(colors[i]);
										 }
									 })
								 }
							 }
							 return (
								 <Bar data={g2c.data} options={opt.customized.global}/>
							 );
						 }
						 return null;
					 }}
		            </Query>
				</>
			);
		}
		;
	}

	const AverageP21DHA = (csv) => {
		if(csv === true) {
			CSVExport(dataAverageP21DHA);
			return (<></>);
		} else {
			return (
				<>
					<Query query={GET_AVERAGE_P21DHA} variables={{
						companyId : userFromStorage.companyId.toString(),
						codeATR : '006',
						time : filters.periodoTiempo
					}}>
					 {({data}) => {
						 if(data) {
							 let datos = [];
							 const opt = chartOptions();
							 const g2c = new graphql2chartjs();
							 if(!dataAverageP21DHA) {
								 Array.prototype.push.apply(datos, data.periodo1);
								 Array.prototype.push.apply(datos, data.periodo2);
								 var outputData = datos.map(Object.values);
								 // @ts-ignore
								 dataAverageP21DHA = outputData;
							 }

							 if(data.periodo1.length > 9) {
								 data.periodo1 = data.periodo1.slice(0, 9);
							 }
							 if(data.periodo2.length > 9) {
								 data.periodo2 = data.periodo2.slice(0, 9);
							 }

							 g2c.add(data, (dataSetName, dataPoint) => {
								 return {
									 ...dataPoint,
									 chartType : "bar",
									 borderColor : "#fff",
									 fill : false
								 };
							 });

							 for(let i = 0 ; i < 2 ; i++) {
								 if(g2c.data.datasets[i].borderColor && g2c.data.datasets[i].borderColor.length > 1) {
									 g2c.data.datasets[i].borderColor.forEach((value, index) => {
										 if(!g2c.data.datasets[i].backgroundColor) {
											 g2c.data.datasets[i].backgroundColor = [];
										 } else {
											 g2c.data.datasets[i].backgroundColor.push(colors[i]);
										 }
									 })
								 }
							 }
							 return (
								 <Bar data={g2c.data} options={opt.customized.global}/>
							 );
						 }
						 return null;
					 }}
		            </Query>
				</>
			);
		}
		;
	}

	if(!userFromStorage) {
		return (
			<>
				<Header title="Cargando..."/>
			</>
		);
	} else if(userFromStorage.user_group_id === 1000) {
		getParams();
		return (
			<>
				<Header title="Vista general"/>
				<Container id="graficas_control" className="mt--7" fluid>
					<Row className="card-deck">
						{/* ----------- Más Vendida ----------- */}
						<Card className="col-6">
							<CardBody>
								{/* @ts-ignore */}
								<CardTitle>Propuesta más vendida {terminacionTiempo}</CardTitle>
								<Row>
									<Col md="12">
										<MostSoldOffer/>
									</Col>
								</Row>
								<Button size="sm" className="float-right mt-2" onClick={() => MostSoldOffer(true)}>Exportar a CSV</Button>
							</CardBody>
						</Card>
						{/* ------------- Filtros ------------- */}
						<Card className="col-6">
							<CardBody>
								<CardTitle><h1>Filtros</h1></CardTitle>
								<Form>
									<Row>
										<Col md="5">
											<FormGroup tag="fieldset">
										        <legend>Periodo de tiempo</legend>
										        <FormGroup check>
											        <Label check>
										                <Input type="radio" name="radioTiempo" value="1"/>{' '}
												        Contratos del último mes
										            </Label>
										        </FormGroup>
										        <FormGroup check>
											        <Label check>
										                <Input type="radio" name="radioTiempo" value="3"/>{' '}
												        Contratos del último trimestre
										            </Label>
										        </FormGroup>
										        <FormGroup check>
											        <Label check>
										                <Input type="radio" name="radioTiempo" value="6"/>{' '}
												        Contratos del último semestre
											        </Label>
										        </FormGroup>
										        <FormGroup check>
											        <Label check>
										                <Input type="radio" name="radioTiempo" value="12"/>{' '}
												        Contratos del último año
											        </Label>
										        </FormGroup>
										        <FormGroup check>
											        <Label check>
										                <Input type="radio" name="radioTiempo" value="total"/>{' '}
												        Contratos totales
											        </Label>
										        </FormGroup>
											</FormGroup>
										</Col>
										<Col md="7">
											<FormGroup>
												<legend>Nombre del comercial</legend>
												<Input type="select" name="comercial" id="comercial">
													<QueryUsers/>
								                </Input>
											</FormGroup>
										</Col>
									</Row>
									<Button type="submit" onclick={() => filters.changed = false} color="success" size="sm" className="float-right">
										Filtrar
									</Button>
								</Form>
							</CardBody>
						</Card>
					</Row>
					<Row className="card-deck mt-5">
						{/* --------- Contratos Totales -------- */}
						<Card className="col-4">
							<CardBody>
								{/* @ts-ignore */}
								<CardTitle>Contratos totales {terminacionTiempo}</CardTitle>
								<Row>
									<Col md="12">
										<ContractsCount/>
									</Col>
								</Row>
								<Button size="sm" className="float-right mt-2" onClick={() => ContractsCount(true)}>Exportar a CSV</Button>
							</CardBody>
						</Card>
						{/* ------- Contratos Provincia ------- */}
						<Card className="col-4">
							<CardBody>
								{/* @ts-ignore */}
								<CardTitle>Propuestas por provincia {terminacionTiempo}</CardTitle>
								<Row>
									<Col md="12">
										<ProvinceQuery/>
									</Col>
								</Row>
								<Button size="sm" className="float-right mt-2" onClick={() => ProvinceQuery(true)}>Exportar a CSV</Button>
							</CardBody>
						</Card>
						{/* -------- Contratos Aceptados ------- */}
						<Card className="col-4">
							<CardBody>
								{/* @ts-ignore */}
								<CardTitle>Estado de las propuestas {terminacionTiempo}</CardTitle>
								<Row>
									<Col md="12">
										<AcceptedCount/>
									</Col>
								</Row>
								<Button size="sm" className="float-right mt-2" onClick={() => AcceptedCount(true)}>Exportar a CSV</Button>
							</CardBody>
						</Card>
					</Row>


					<Row className="card-deck mt-5">
						{/* ---- Precio medio comercializadora ---- */}
						<Card className="col-4">
							<CardBody>
								<Row>
									<Col md="6">
										{/* @ts-ignore */}
										<CardTitle>Precio medio de comercializadoras {terminacionTiempo}</CardTitle>
									</Col>
									<Col md="6">
										<FormGroup>
											<legend>Comercializadora</legend>
											<Input type="select" name="comercializadora" id="comercializadora" onChange={(e) => setComercializadora(e.target.value)}>
												<QueryMarketers/>
										    </Input>
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col md="12">
										<AverageMarketers/>
									</Col>
								</Row>
								<Button size="sm" className="float-right mt-2" onClick={() => AverageMarketers(true)}>Exportar a CSV</Button>
							</CardBody>
						</Card>
						{/* -------- Precio medio comercial -------- */}
						<Card className="col-4">
							<CardBody>
								{/* @ts-ignore */}
								<CardTitle>Precio medio por comercial {terminacionTiempo}</CardTitle>
								<Row>
									<Col md="12">
										<AverageCommercial/>
									</Col>
								</Row>
								<Button size="sm" className="float-right mt-2" onClick={() => AverageCommercial(true)}>Exportar a CSV</Button>
							</CardBody>
						</Card>
						{/* -- Precio medio propuesta presentada -- */}
						<Card className="col-4">
							<CardBody>
								{/* @ts-ignore */}
								<CardTitle>Precio medio de las propuestas presentadas {terminacionTiempo}</CardTitle>
								<Row>
									<Col md="12">
										<AveragePrize/>
									</Col>
								</Row>
								<Button size="sm" className="float-right mt-2" onClick={() => AveragePrize(true)}>Exportar a CSV</Button>
							</CardBody>
						</Card>
					</Row>
					<Row className="card-deck mt-5">
						{/* ------- Comercializadora en cada provincia ------- */}
						<Card className="col-6">
							<CardBody>
								{/* @ts-ignore */}
								<CardTitle>Comercializadora por provincia {terminacionTiempo}</CardTitle>
								<Row>
									<Col md="12">
										<MarketerProvince/>
									</Col>
								</Row>
								<Button size="sm" className="float-right mt-2" onClick={() => MarketerProvince(true)}>Exportar a CSV</Button>
							</CardBody>
						</Card>
						{/* -- En cada provincia comerzializadora más vende -- */}
						<Card className="col-6">
							<CardBody>
								{/* @ts-ignore */}
								<CardTitle>Comercializadora que más vende en cada provincia {terminacionTiempo}</CardTitle>
								<Row>
									<Col md="12">
										<ProvinceMarketer/>
									</Col>
								</Row>
								<Button size="sm" className="float-right mt-2" onClick={() => ProvinceMarketer(true)}>Exportar a CSV</Button>
							</CardBody>
						</Card>
					</Row>
					
				</Container>
			</>
		);
	} else {
		return (
			<>
				<Header title="No tienes permisos para acceder a esta sección"/>
			</>
		);
	}
}

export default withApollo(PropuestasControlPanel);
