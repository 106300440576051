import React from "react";
import memoize from 'memoize-one';
// reactstrap components
import {
    Card,
    CardHeader,
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Form, Input,
    CardBody, CardTitle, Label
} from "reactstrap";
import { Link } from 'react-router-dom';
//import LoadingOverlay from "react-loading-overlay";
import axios from 'axios';
import moment from "moment";
import 'moment/locale/es'
import ReactBSAlert from "react-bootstrap-sweetalert";

// core components
import Header from "../../components/Headers/Header";

import SipsOption from "../../components/Sips/SipsOption";
import { Query, withApollo } from "react-apollo";

import { chartOptions } from "../../variables/charts";

import {
    LIST_USERS
} from "../../components/Sips/SipsOption.graphql";
//import timestampToDate from "../../utils/timestampToDate";
import formatData from "../../utils/formatData";
//import Environment from "../../constants/environment";
import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

import CircularProgress from '@material-ui/core/CircularProgress';
import Reflux from "reflux";
import { UserStore } from "../../store/UserStore";

const userFromStorage = localStorage.getItem("user");

const parsed = JSON.parse(userFromStorage || '{}');

const companyNameGlobal = parsed.company;
const CompanyIdUser = parsed.companyId;

const userGroupId = parsed.user_group_id
const userId = parsed.id


const sortIcon = <ArrowDownward />;

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}


const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>
);


const columnsDatos = memoize(clickHandler => [

    { selector: row => row['IdOferta'], name: 'ID PROPUESTA', sortable: true, width: '200px', format: (row => capitalize(row.IdOferta)) },
    {
        // eslint-disable-next-line react/button-has-type
        //cell: () => <button className="btn btn-primary btn-sm btn-circle" onClick={clickHandler}></button>,
        //cell: row => <div><div style={{ fontWeight: 700 }}>{row.title}</div>{row.summary}</div>,

        cell: row => <Link
            className="btn btn-primary btn-sm btn-circle"
            data-toggle="modal"
            to={{ pathname: "sip-propuesta-detalle/" + row.IdOferta + "/" + row.tipoEnergia }}
            id="buscartooltip"
        >
            <i className="pe-7s-search text-white"></i>
        </Link>,

        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        name: 'INFO',
        minWidth: '55px'

    },
    {
        cell: (row) => <Button onClick={clickHandler} color="info" size="sm" className="float-right" id={row.tipoEnergia} value={row.IdOferta}>Detalles</Button>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
    },
    { selector: row => row['tipo'], name: 'TIPO CONTRATO', sortable: true, width: '100px', center: true, format: (row => capitalize(row.tipoEnergia)) },

    {
        selector: row => row['esAprobada'], name: 'ESTADO', sortable: true, width: '210px', center: true, style: {
            color: 'rgba(0,0,0,.54)',
        }, format: (row => row.esAprobada ? "Aprobado" : "En espera")
    },
    { selector: row => row['fechaPropuesta'], name: 'FECHA PROPUESTA', width: '180px', sortable: true, center: true, format: (row => moment(row.fechaPropuesta, 'YYYY-MM-DD', true).isValid() ? moment(row.fechaPropuesta, 'YYYY-MM-DD').format('DD-MM-YYYY') : "") },
    { selector: row => row['fechaAprobacion'], name: 'FECHA APROBACION', width: '180px', sortable: true, center: true, format: (row => moment(row.fechaAprobacion, 'YYYY-MM-DD', true).isValid() ? moment(row.fechaAprobacion, 'YYYY-MM-DD').format('DD-MM-YYYY') : "") },
    { selector: row => row['empleado'], name: 'AGENTE COMERCIAL', sortable: true, width: '250px', center: true, format: (row => capitalize(row.T_DatosAgenteComercial.nombreUsuario)) },
    { selector: row => row['nombreOfertaComercial'], name: 'PRODUCTO', sortable: true, width: '250px', center: true, format: (row => capitalize(row.T_PropuestaComercial.Producto)) },
    { selector: row => row['Comercializadora'], name: 'COMERCIALIZADORA', sortable: true, width: '250px', center: true, format: (row => capitalize(row.T_PropuestaComercial.Comercializadora)) },
    { selector: row => row['ofertaTipo'], name: 'TIPO', sortable: true, width: '100px', center: true, format: (row => capitalize(row.OfertaTipo)) },
    { selector: row => row['ofertaTipoId'], name: 'SEQ', sortable: true, width: '100px', center: true },

]);

const columnsDatosGenerales = [
    {
        cell: row => <h5 className="mb-0"> {companyNameGlobal} </h5>,

        ignoreRowClick: true,
        allowOverflow: true,
        name: 'EMPRESA',
        minWidth: '55px',
        width: '200px'

    },
    {
        selector: row => row['totalContratos'], name: 'TOTAL PROPUESTAS', sortable: true, width: '210px', style: {
            color: 'rgba(0,0,0,.54)',
        },
    },
    { selector: row => row['totalAceptados'], name: 'TOTAL ACEPTADOS', sortable: true, width: '210px', center: false },
    { selector: row => row['ultimaFecha'], name: 'FECHA ULTIMA PROPUESTA', width: '210px', sortable: false, center: true, format: (row => moment(row.ultimaFecha, 'DD-MM-YYYY', true).isValid() ? row.ultimaFecha : "") },
];

const columnsDatos10Last = memoize(clickHandler => [
    { selector: row => row['IdOferta'], name: 'ID CONTRATO', sortable: true, width: '200px', format: (row => capitalize(row.IdOferta)) },

    {
        // eslint-disable-next-line react/button-has-type
        cell: (row) => <Button onClick={clickHandler} color="info" size="sm" className="float-right" id={row.tipoEnergia} value={row.IdOferta}>Detalles</Button>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
    },
    {
        selector: row => row['esAprobada'], name: 'ESTADO', sortable: true, width: '210px', center: true, style: {
            color: 'rgba(0,0,0,.54)',
        }, format: (row => row.esAprobada ? "Aprobado" : "En espera")
    },
    { selector: row => row['fechaPropuesta'], name: 'FECHA PROPUESTA', width: '180px', sortable: true, center: true, format: (row => moment(row.fechaPropuesta, 'YYYY-MM-DD', true).isValid() ? moment(row.fechaPropuesta, 'YYYY-MM-DD').format('DD-MM-YYYY') : "") },
    { selector: row => row['fechaAprobacion'], name: 'FECHA APROBACION', width: '180px', sortable: true, center: true, format: (row => moment(row.fechaAprobacion, 'YYYY-MM-DD', true).isValid() ? moment(row.fechaAprobacion, 'YYYY-MM-DD').format('DD-MM-YYYY') : "") },
    { selector: row => row['empleado'], name: 'AGENTE COMERCIAL', sortable: true, width: '250px', center: true, format: (row => capitalize(row.T_DatosAgenteComercial.nombreUsuario)) },
    { selector: row => row['nombreOfertaComercial'], name: 'PRODUCTO', sortable: true, width: '250px', center: true, format: (row => capitalize(row.T_PropuestaComercial.Producto)) },
    { selector: row => row['Comercializadora'], name: 'COMERCIALIZADORA', sortable: true, width: '250px', center: true, format: (row => capitalize(row.T_PropuestaComercial.Comercializadora)) },


]);


const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};



class GestionPropuesta extends Reflux.Component<any, any> {
    lastFiltersEmpre = {};
    state = {
        UserStore: {},
        isLoading: false,
        isSearching: false,
        copiedText: null,
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        page: 1,
        limit: 200,
        paginatedData: { items: [{ CIF: '' }] },
        paginatedDataTemp: { items: [] },
        showIndexButton: false,
        showFreeMarketButton: false,
        sumOfEnergy: 0,
        sumOfEnergyLast: 0,
        cupsGroupedBydistri: [],
        cupsGroupedBydistriEnergy: [],
        cupsGroupedByATREnergy: [],
        cupsGroupedByComer: [],
        cupsGroupedByATR: [],
        cupsGroupedByProvincia: [],
        cupsGroupedByComercialEnergy: [],
        cupsGroupedByComercialEnergyCont: [],
        EnergiaTotalATRCont: [],
        datosEmpresa: {
            CIF: "",
            CNAE: "",
            Calle: "",
            CodigoPostal: "",
            Consolidada: "",
            Ejercicio: "",
            FormaJuridica: "",
            IngExplota: "",
            LiteralCNAE: "",
            Localidad: "",
            Marca: "",
            Nombre: "",
            Proveedor: "",
            Provincia: "",
            Telefono: "",
            Web: ""

        },
        filters: {
            comercial: '',
            fechaInicio: '',
            fechaFin: '',
            codeComercializadora: '',
            codigoAutonomiaPS: '',
            codigoMunicipioPS: '',
            provincia: '',
            contrato: '',
            aprobada: ''
        },
        alert: null,
        setFilters: {
            comercial: '',
            fechaInicio: '',
            fechaFin: '',
            codeComercializadora: '',
            codeComercializadoraGas: '',
            codigoAutonomiaPS: '',
            codigoMunicipioPS: '',
            provincia: '',
            contrato: '',
            aprobada: false
        },
        dataAceptadosContratos: [],
        codegas: '',
        codeele: '',
        companyIdUser: '',
        userName:'',
        companyNameUser: '',
        userGroupId: 1000,
        userId: '',
        idCard: "",
        dataCountContratos: [],
        dataResumenContratos: {
            totalAceptados: 0,
            totalContratos: 0,
            totalEnEspera: 0,
            ultimaFecha: ''
        },
        dataUltimosContratos: [],
        dataGeneralContratos: [],
        ListaUsuarios: [
            {
                id: "0",
                first_name: "sin datos",
            },
        ],
        activeUsers: false,
        activeUser: false,
        selectedRows: [],
        user: '',
        producto: [],
        selectProductButtonDisabled: true,
        toggledClearRows: false,
        setPending: true,
        dataContractTrin: [],
        alineado: 'right',
        comerListDisabled: false,
        comerGasListDisabled: false,
isLoading2: true
    };

    constructor(props) {
        super(props);
        this.store = UserStore;
    }

    componentWillReceiveProps() {

        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;

        const userGroupId = usuario.user_group_id
        const userId = usuario.id
        const userName = usuario.fullname
        const idCard = usuario.email

        let showIndexButton = usuario.indexada;
        let showFreeMarketButton = usuario.mercadolibre;
        let companyNameUser = usuario.company;

        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };

        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId,
            showIndexButton,
            userName,
            showFreeMarketButton,
            companyNameUser,
            idCard

        });
    }

    UNSAFE_componentWillMount() {


        var userFromStorageTemp = localStorage.getItem("user");
        var parsedTemp = JSON.parse(userFromStorageTemp || '{}');
        var userIdTemp = parsedTemp.id;
        var companyIdUserTemp = parsedTemp.companyId;
        var userGroupId = parsedTemp.user_group_id;
        var company = parsedTemp.company;
        var idCard = parsedTemp.email;

        this.setState({

            companyIdUser: companyIdUserTemp,
            userId: userIdTemp,
            userGroupId: userGroupId,
            companyNameUser: company,
            idCard: idCard

        });

    };


    componentDidMount() {

        const {
            match: { params },
        } = this.props;

        //this.ContractsCount();
        //this.AcceptedCount();
        //this.QueryUltimosContratos();
        this.QueryContratos();
        this.QueryResumenContratos();
        this.QueryUsers();

    }

    QueryUsers = async () => {
        let isLoading = true;
        const isSearching = true;




        const companyId = this.state.companyIdUser.toString()
        const userId = ''
        const time = ''
        await this.props.client.query({
            query: LIST_USERS,
            variables: { companyId },
        }).then(res => {
            //console.log('DATOS DEVUELTOS POR DATA_ACCEPT_CONTRATOS ------>', res)
            const data = res.data.listUsers;

            this.setState({
                ListaUsuarios: data
            });
        })
            .catch(err => {
                isLoading = false;
                this.setState({ isLoading });
            })
    };


    submit = () => {

        if ((this.state.setFilters.fechaInicio && !this.state.setFilters.fechaFin) || (!this.state.setFilters.fechaInicio && this.state.setFilters.fechaFin)) {
            this.warningAlert();

        } else {

            this.QueryContratos();

        }

    };


    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    successAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Correcto"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Propuestas actualizadas correctamente
          </ReactBSAlert>
            )
        });
    };


    warningAlertDB = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error en la base de datos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un error en la Base de datos.
         </ReactBSAlert>
            )
        });
    };
    warningAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error en las fechas"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Debe poner rango de búsqueda en los campos de fechas. Revise los datos introducidos
     </ReactBSAlert>
            )
        });
    };


    onChangeField = (event) => {


        const name = event.target.name;
        let value = event.target.value;
        var setFilters = this.state.setFilters;

        setFilters[name] = value;
        if (event.target.type === "select-one" && value === "0") {
            delete setFilters[name];
        }

        if (value === "" || value === null) {
            delete setFilters[name];
        }

        if (name == 'codeComercializadoraGas' && event.target.type === "select-one" && value === "0") {
            this.setState({
                codegas: '',
                comerListDisabled: false
            })
        } else if (name == 'codeComercializadoraGas' && event.target.type === "select-one" && value != "0") {
            this.setState({
                codegas: value,
                comerListDisabled: true
            })
        }

        if (name == 'codeComercializadora' && event.target.type === "select-one" && value === "0") {
            this.setState({
                codeele: '',
                comerGasListDisabled: false
            })
        } else if (name == 'codeComercializadora' && event.target.type === "select-one" && value != "0") {
            this.setState({
                codeele: value,
                comerGasListDisabled: true
            })
        }

        this.setState({ setFilters });
    };


    QueryContratos = async () => {
        let isLoading = true;
        const isSearching = true;
        let { page, limit } = this.state;
        //  const isSearching = true;
        this.setState({ isLoading, isSearching });
        //this.lastFiltersEmpre = filters;

        if (this.lastFiltersEmpre.hasOwnProperty("limit")) {
            this.state.limit = parseInt('200');
        }
        if (
            (!this.state.limit || this.state.limit === 0) &&
            (this.state.pageSize !== null || this.state.pageSize > 1)
        ) {
            this.state.limit = this.state.pageSize;
        }

        //let { page, limit } = this.state;

        const companyId = this.state.companyIdUser.toString()
        const userId = '-1'
        var datos = this.state.setFilters
        var codeComercializadora = ''

        if (!datos.codeComercializadora && datos.codeComercializadoraGas) {
            codeComercializadora = datos.codeComercializadoraGas

        } else if (!datos.codeComercializadoraGas && datos.codeComercializadora) {
            codeComercializadora = datos.codeComercializadora

        }

        if (this.state.codegas != '' && !this.state.codeele) {

            codeComercializadora = this.state.codegas
        }
        if (this.state.codeele != '' && !this.state.codegas) {

            codeComercializadora = this.state.codeele
        }

        if (this.state.codeele == '' && this.state.codegas == '') {

            codeComercializadora = ''
        }



        var userIdAux = this.state.userId.toString();
        var userQuery = this.state.userId.toString();
        //const IdOferta = this.state.contrato;
        if (this.state.userGroupId == 1000) {
            userQuery = datos.comercial
        } else {
            userQuery = this.state.userId.toString();
        }

        var idCard = this.state.idCard;

        var dataFilters = {
            'Usuario': userQuery,
            'idContrato': datos.contrato,
            'codigoMunicipioPS': datos.codigoMunicipioPS,
            'codigoAutonomiaPS': datos.codigoAutonomiaPS,
            'codigoProvinciaPS': datos.provincia,
            'idComercializadora': codeComercializadora,
            'fechaAprobacionDesde': datos.fechaInicio,
            'fechaAprobacionHasta': datos.fechaFin,
            'esAprobada': datos.aprobada
        };
        var contratoDato: any = []

        var valores = JSON.stringify(dataFilters)
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };
        let direccionFile = "https://datapi.psgestion.es/propuesta/listadomultiadministrador?id=" + companyId + "&user=" + userId + "&idcard=" + idCard;

        await axios.get(direccionFile, { headers, params: { valores } }).
            then(result => {
                //console.log('resultado del listados')
                //console.log(result.data.rows)
                let data = result.data.rows
                var longData = data.length

                var longitud = 10;
                if (longData < 10) {
                    longitud = longData
                }
                var arr2: string[] = [];
                for (var n = 0; n < longitud; n++) {

                    arr2.push(data[n]);
                }

                this.setState({
                    dataGeneralContratos: data,
                    dataUltimosContratos: arr2,
                    setPending: false
                });
            }).catch((error) => {
                console.log('Failure ', error)
                this.warningAlertDB();
            });

    };


    //////////////////////////////////////////////////////////////////////////////////////

    QueryResumenContratos = async () => {
        let isLoading = true;
        const isSearching = true;
        const companyId = this.state.companyIdUser.toString()
        const userId = '-1'

        var datos = this.state.setFilters
        var userIdAux = this.state.userId.toString();
        var userQuery = this.state.userId.toString();
        var idCard = this.state.idCard;
        //const IdOferta = this.state.contrato;
        if (this.state.userGroupId == 1000) {
            userQuery = datos.comercial
        } else {
            userQuery = this.state.userId.toString();
        }

        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };
        let direccionFile = "https://datapi.psgestion.es/propuesta/resumenadministrador?id=" + companyId + "&user=" + userIdAux+ "&idcard=" + idCard;

        await axios.get(direccionFile, { headers }).
            then(result => {
                console.log('resultado del listados')
                console.log(result.data.rows)
                let data = result.data.rows[0]

                this.setState({
                    dataResumenContratos: data,
                    isLoading2: false
                });
            }).catch((error) => {
                console.log('Failure ', error)
                this.warningAlertDB();
            });

    };

    /////////////////////////////////////////////////////////////////////////////////////

    handleChange = state => {

        this.setState({ selectedRows: state.selectedRows });
        if (state.selectedRows.length > 0) {
            this.setState({ selectProductButtonDisabled: false })
        } else {
            this.setState({ selectProductButtonDisabled: true })
        }
    };



    beforeRenderItem = (index, item) => {
        if (index.includes("fecha") && item.value) {
            const dateValue = new Date(item.value);
            item.value = dateValue.toISOString().split("T")[0];
        }
        return true;
    };


    handleButtonClick = (state) => {
        // eslint-disable-next-line no-console
        const tipo = state.target.id;
        const id = state.target.value
        const compania = this.state.companyIdUser
        if (tipo == 'ELECTRICIDAD') {
            const win = window.open("/print/sip-propuesta-print/" + id + "/" + compania, "_blank");
            if (win != null) {
                win.focus();
            }
        } else {
            const win = window.open("/print/sipgas-comparative-print/" + id+ "/" + compania, "_blank");
            if (win != null) {
                win.focus();
            }

        }


    };

    alerta = (e) => {
        // eslint-disable-next-line no-console
        console.log('clicked');
        console.log(e);
    };

    render() {
        //console.log('DENTRO DE Empresa')
        //console.log(this.state)
        //console.log(this.props)
        var titulo10 = '';
        if (userGroupId === 1000) {
            titulo10 = "ULTIMAS 10 PROPUESTAS - " + companyNameGlobal;
        } else {
            titulo10 = "TUS ULTIMAS PROPUESTAS";
        }

        const propuestasGeneral = this.state.dataGeneralContratos;
        const datosTrimestre = { totalTrimestre: this.state.dataCountContratos };

        const data10: any[] = this.state.dataAceptadosContratos;
        const ListaUsuarios = this.state.ListaUsuarios;
        console.log('VALOR DE LISTAUSURIOS ---> ', this.state.ListaUsuarios)
        console.log('VALOR DE LISTAUSURIOS DOS ---> ', ListaUsuarios)
        const companyIdUser = this.state.companyIdUser;
        const company = this.state.companyNameUser



        if (!parsed.mercadolibre) {
            return (
                <>
                    <Header title={parsed.company !== "" ? "Detalles | Propuestas " + company : "Detalles | Resumen de propuestas"} />
                    <Container id="resumen_general" className="mt--7" fluid>

                        <Row className="margin-reset w-100">

                            <Col md="6" xl="3">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    TOTAL PROPUESTAS
              </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">
                                                    {this.state.dataResumenContratos.totalContratos || 0}
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                                    <i className="bi bi-files" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <p className="mt-3 mb-0 text-sm">
                                            <span className="text-nowrap">Propuesta totales realizadas</span>
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="6" xl="3">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    ACEPTADOS
              </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">
                                                    {this.state.dataResumenContratos.totalAceptados || 0}
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                                    <i className="bi bi-check2-circle" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <p className="mt-3 mb-0 text-sm">
                                            {" "}
                                            <span className="text-nowrap">Propuestas aceptadas en el trimestre</span>
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col md="6" xl="3">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    EN ESPERA
              </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">
                                                    {this.state.dataResumenContratos.totalEnEspera || 0}
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-gradient-blue text-white rounded-circle shadow">
                                                    <i className="bi bi-clock-history" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <p className="mt-3 mb-0 text-sm">
                                            {" "}
                                            <span className="text-nowrap">Propuestas a la espera de aceptación</span>
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>


                            <Col md="6" xl="3">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    FECHA ULT. PROPUESTA
              </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">
                                                    {this.state.dataResumenContratos.ultimaFecha || "SIN DATOS"}
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                                    <i className="bi bi-calendar-event" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <p className="mt-3 mb-0 text-sm">
                                            {" "}
                                            <span className="text-nowrap">Fecha de la última propuesta introducida</span>
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>


                        <Row id="contratos_resumen" className="card-deck mt-2">
                            <Card className="shadow">
                                <DataTable
                                    title={titulo10}
                                    columns={columnsDatos10Last(this.handleButtonClick)}

                                    data={this.state.dataUltimosContratos}
                                    sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you
                                    fixedHeader={true}
                                    noDataComponent={"Sin datos para mostrar"}
                                    progressPending={this.state.setPending}
                                    progressComponent={<Circular />}
                                    dense={true}

                                />
                            </Card>
                        </Row>
                    </Container>
                    <Container id="contratos_empresa" className="mt-3" fluid>



                        <Row id="filtros_contratos" className="card-deck mt-5">
                            <Card className="shadow">
                                <CardHeader>

                                    <h3 className="mb-0">{userGroupId === 1000 ? "Filtrar propuestas" : "Filtra tus propuestas"}</h3>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <Row>
                                            <Col md="2">


                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="comercial">
                                                        Nombre Agente Comercial
                </Label><br />

                                                    {this.state.activeUser ? <><Input type="select" defaultValue={'DEFAULT'} name="comercial" id="comercial" onChange={this.onChangeField}>
                                                        <option value=""> --Selecciona un comercial-- </option>
                                                        {ListaUsuarios.map((option) => (
                                                                  <option value={option.id}>{option.first_name}</option>
                                                                ))}



                                                    </Input></> : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="contrato">
                                                        Nombre contrato
                </Label>
                                                    <Input type="text" name="contrato" id="contrato"
                                                        placeholder="Nombre del contrato..."
                                                        value={this.state.setFilters.contrato}
                                                        onChange={this.onChangeField}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="comercializadora">
                                                        Comercializadora Electricidad
</Label><br />
                                                    <SipsOption
                                                        placeholder="Seleccionar comercializadora"
                                                        id="codeComercializadora"
                                                        name="codeComercializadora"
                                                        value={this.state.setFilters.codeComercializadora}
                                                        query="LIST_MARKETERS"
                                                        onChange={this.onChangeField}
                                                        disabled={this.state.comerListDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3">
                                                <FormGroup>
                                                    <Label
                                                        className="form-control-label"
                                                        htmlFor="ComercializadoraGas"
                                                    >
                                                        Comercializadora Gas
</Label>
                                                    <SipsOption
                                                        placeholder="Seleccionar comercializadora"
                                                        id="codeComercializadoraGas"
                                                        name="codeComercializadoraGas"
                                                        value={this.state.setFilters.codeComercializadoraGas}
                                                        query="LIST_MARKETERS_GAS"
                                                        onChange={this.onChangeField}
                                                        disabled={this.state.comerGasListDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="municipio">
                                                        Municipio
                </Label><br />
                                                    <SipsOption
                                                        placeholder="Seleccionar localidad"
                                                        //                     invalid={forceUseLocality}
                                                        id="codigoMunicipioPS"
                                                        name="codigoMunicipioPS"
                                                        query="LIST_LOCALITIES"
                                                        onChange={this.onChangeField}
                                                        value={this.state.setFilters.codigoMunicipioPS}

                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="provincia">
                                                        Provincia
                </Label><br />
                                                    <SipsOption
                                                        placeholder="Seleccionar provincia"
                                                        id="codigoProvincia"
                                                        name="provincia"
                                                        query="LIST_PROVINCES"
                                                        onChange={this.onChangeField}
                                                        value={this.state.setFilters.provincia}
                                                    ></SipsOption>
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="comunidadAutonoma">
                                                        Comunidad Autónoma
                </Label><br />
                                                    <SipsOption
                                                        placeholder="Seleccionar comunidad autónoma"
                                                        id="codigoAutonomiaPS"
                                                        name="codigoAutonomiaPS"
                                                        query="LIST_AUTONOMOUS_COMMUNITIES"
                                                        onChange={this.onChangeField}
                                                        value={this.state.setFilters.codigoAutonomiaPS}
                                                    ></SipsOption>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="fechaInicio">Fecha inicio</Label>
                                                    <Input type="date" name="fechaInicio" id="fechaInicio" placeholder="Fecha inicio"
                                                        onChange={this.onChangeField}
                                                        value={this.state.setFilters.fechaInicio}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="fechaFin">Fecha fin</Label>
                                                    <Input type="date" name="fechaFin" id="fechaFin" placeholder="Fecha fin"
                                                        onChange={this.onChangeField}
                                                        value={this.state.setFilters.fechaFin}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <br />
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="Aprobada?">
                                                        <Input
                                                            id="esAprobada"
                                                            name="aprobada"
                                                            onChange={this.onChangeField}
                                                            value="true"
                                                            type="radio"
                                                        />{' '}
                                                        Contrato aprobado
                </Label>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label className="form-control-label" >
                                                        <Input
                                                            id="noesAprobada"
                                                            name="aprobada"
                                                            onChange={this.onChangeField}
                                                            value="false"
                                                            type="radio"
                                                        />{' '}
                                                        Contrato No aprobado
              </Label>

                                                </FormGroup>

                                            </Col>
                                        </Row>
                                        <Button color="success" size="sm" className="float-right"
                                            onClick={this.submit}
                                        >
                                            <i className={"bi bi-search"} /> Buscar
          </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Row>


                        <Row id="contratos_resumen" className="card-deck mt-2">
                            <Card className="shadow">
                                <DataTable
                                    title="LISTADO DE PROPUESTAS"
                                    columns={columnsDatos(this.handleButtonClick)}
                                    data={propuestasGeneral}
                                    sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                    noDataComponent={"Sin datos para mostrar"}
                                    pagination
                                    paginationPerPage={10}
                                    paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
                                    onSelectedRowsChange={this.handleChange}
                                    highlightOnHover
                                    striped
                                    pointerOnHover
                                    selectableRowsHighlight

                                    customStyles={customStyles}
                                    contextMessage={{ singular: 'Propuesta', plural: 'Propuestas', message: 'seleccionadas' }}
                                    subHeader
                                    progressPending={this.state.setPending}
                                    progressComponent={<Circular />}
                                />
                            </Card>
                        </Row>
                        {this.state.alert}



                    </Container>
                </>
            );
        } else {
            return (
                <>
                    <Header title={"No tienes permisos para acceder a este apartado"} />
                </>
            );
        }
    }
}

export default withApollo(GestionPropuesta);
