import React from "react";
// reactstrap components
import { Card, Container, Row, CardTitle, CardBody } from "reactstrap";

import LoadingOverlay from "react-loading-overlay";
import { CSVLink } from "react-csv";

// core components
import Header from "../../components/Headers/Header";

import { withApollo } from "react-apollo";
import {
  VIEW_SIPS_CONSUMOS_TOTAL,
  LIST_SIPS_CONSUMOS,
} from "../../queries/sips-consumo/sip-consumo";
import { VIEW_SIPS } from "../../queries/sips/sips.graphql";

import SipsTable from "../../components/Sips/SipsTable";
import moment from "moment";

const highPriorityItemsFields = {
  cups: "CUPS",
  nombreCompletoTitular: "Titular",
  nombreEmpresaDistribuidora: "Distribuidora Principal",
  empresaDistribuidora: "Distribuidora",
  comercializadora: "Comercializadora",
  direccion_ps: "Dirección",
  localidad_ps: "Localidad",
  codigoPostalPS: "Código Postal",
  nombreProvinciaPS: "Provincia",
  tarifaATR: "Descripción Tarifa",
  kWhAnual: "kWh Anual",
  fechaUltimoMovimientoContrato: "Fecha Último Movimiento",
  fechaUltimoCambioComercializador: "Fecha Último Cambio Comercializadora",
};
const lowPriorityItemsFields = {
  CIF: "CIF",
  telefono: "Teléfono",
  contactoTitular: "Contacto del titular",
  codigoEmpresaDistribuidora: "Cód. Distribuidora",
  codeComercializadora: "Cód. Comer",
  fechaAltaSuministro: "Fecha Alta",
  codigoTarifaATREnVigor: "Tarifa",
  Tension: "Tensión",
  potenciaMaximaBIEW: "Potencia Máxima BIE",
  potenciaMaximaAPMW: "Potencia Máxima Puesta en Marcha",
  codClasificacionPM: "Tipo PM",
  codigoDisponibilidadICP: "Indicativo ICP",
  perfilConsumo: "Perfil Consumo",
  valorDerechosExtensionW: "Derechos Extension",
  valorDerechosAccesoW: "Derechos Acceso Llano",
  derechosAccesoValle: "Derechos Acceso Valle",
  proEquipoMedida: "Propiedad Equipo Medida",
  propiedadICP: "Propiedad ICP",
  potenciasContratadasEnWP1: "Potencia Contratada Kw P1",
  potenciasContratadasEnWP2: "Potencia Contratada Kw P2",
  potenciasContratadasEnWP3: "Potencia Contratada Kw P3",
  potenciasContratadasEnWP4: "Potencia Contratada Kw P4",
  potenciasContratadasEnWP5: "Potencia Contratada Kw P5",
  potenciasContratadasEnWP6: "Potencia Contratada Kw P6",
  fechaLimiteExtension: "Fecha Límite Extensión",
  fechaUltimaLectura: "Fecha Última Lectura",
  cortes: "Cortes",
  fianza: "Fianza",
  perfilPersona: "Persona",
  esViviendaHabitual: "Primera Vivienda",
  informacionImpagos: "Impago",
  tipoTelegestion: "Telegestionado Activo",
  trimestreCambioContador: "Trimestre Cambio Contador",
  codigoAutoconsumo: "Cod. Autoconsumo",
  ConsumoAnualTotalActiva: "Total Consumo Año Actual Kw",
  ConsumoAnualActivaP1: "Consumo anual Kw P1",
  ConsumoAnualActivaP2: "Consumo anual Kw P2",
  ConsumoAnualActivaP3: "Consumo anual Kw P3",
  ConsumoAnualActivaP4: "Consumo anual Kw P4",
  ConsumoAnualActivaP5: "Consumo anual Kw P5",
  ConsumoAnualActivaP6: "Consumo anual Kw P6",
  ConsumoAnualActivaP1Porcentaje: "Consumo anual P1 (%)",
  ConsumoAnualActivaP2Porcentaje: "Consumo anual P2 (%)",
  ConsumoAnualActivaP3Porcentaje: "Consumo anual P3 (%)",
  ConsumoAnualActivaP4Porcentaje: "Consumo anual P4 (%)",
  ConsumoAnualActivaP5Porcentaje: "Consumo anual P5 (%)",
  ConsumoAnualActivaP6Porcentaje: "Consumo anual P6 (%)",
  ConsumoAnualTotalActivaAnterior: "total Consumo Año Anterior Kw",
  ConsumoAnualActivaP1Anterior: "Consumo año anterior Kw P1",
  ConsumoAnualActivaP2Anterior: "Consumo año anterior Kw P2",
  ConsumoAnualActivaP3Anterior: "Consumo año anterior Kw P3",
  ConsumoAnualActivaP4Anterior: "Consumo año anterior Kw P4",
  ConsumoAnualActivaP5Anterior: "Consumo año anterior Kw P5",
  ConsumoAnualActivaP6Anterior: "Consumo año anterior Kw P6",
  CNAE: "CNAE",
  actividadCNAE: "Desc. CNAE",
  totalEnergiaPuntosGWHAnual: "Total energia Puntos GWh Anual",
  totalPuntos: "Total Puntos",
  precioExcesoPotencia: "Precio Exc. Potencia",
  precioOptimizacionReactiva: "Precio Opt. Reactiva",
  reactivaPotencia: "Reactiva	Total Optimización \n Potencia / Reactiva",
  aplicacionBonoSocial: "Bono Social",
};

const sipsConsumosTotalFields = {
  year: "Año",
  codigoTarifaATR: "Codigo tarifa",
  ConsumoAnualActivaP1: "kWhP1",
  ConsumoAnualActivaP2: "kWhP2",
  ConsumoAnualActivaP3: "kWhP3",
  ConsumoAnualActivaP4: "kWhP4",
  ConsumoAnualActivaP5: "kWhP5",
  ConsumoAnualActivaP6: "kWhP6",
  ConsumoAnualTotalActiva: "Activa total",
  ConsumoAnualReactivaP1: "kVArhP1",
  ConsumoAnualReactivaP2: "kVArhP2",
  ConsumoAnualReactivaP3: "kVArhP3",
  ConsumoAnualReactivaP4: "kVArhP4",
  ConsumoAnualReactivaP5: "kVArhP5",
  ConsumoAnualReactivaP6: "kVArhP6",
  ConsumoAnualTotalReactiva: "Reactiva total",
  PotenciaDemandadaP1: "Demanda kWP1",
  PotenciaDemandadaP2: "Demanda kWP2",
  PotenciaDemandadaP3: "Demanda kWP3",
  PotenciaDemandadaP4: "Demanda kWP4",
  PotenciaDemandadaP5: "Demanda kWP5",
  PotenciaDemandadaP6: "Demanda kWP6",
  PotenciaDemandadaTotal: "Demanda total",
};

const sipsConsumosTotalExpandableFields = {};

const sipsConsumosFields = {
  fechaFinMesConsumo: "Fecha",
  codigoTarifaATR: "Codigo ATR",
  consumoEnergiaActivaEnWhP1: "Energia Activa kWhP1",
  consumoEnergiaActivaEnWhP2: "Energia Activa kWhP2",
  consumoEnergiaActivaEnWhP3: "Energia Activa kWhP3",
  consumoEnergiaActivaEnWhP4: "Energia Activa kWhP4",
  consumoEnergiaActivaEnWhP5: "Energia Activa kWhP5",
  consumoEnergiaActivaEnWhP6: "Energia Activa kWhP6",
  consumoEnergiaReactivaEnVArhP1: "Energia Reactiva kVArhP1",
  consumoEnergiaReactivaEnVArhP2: "Energia Reactiva kVArhP2",
  consumoEnergiaReactivaEnVArhP3: "Energia Reactiva kVArhP3",
  consumoEnergiaReactivaEnVArhP4: "Energia Reactiva kVArhP4",
  consumoEnergiaReactivaEnVArhP5: "Energia Reactiva kVArhP5",
  consumoEnergiaReactivaEnVArhP6: "Energia Reactiva kVArhP6",
  potenciaDemandadaEnWP1: "Potencia demandada kWP1",
  potenciaDemandadaEnWP2: "Potencia demandada kWP2",
  potenciaDemandadaEnWP3: "Potencia demandada kWP3",
  potenciaDemandadaEnWP4: "Potencia demandada kWP4",
  potenciaDemandadaEnWP5: "Potencia demandada kWP5",
  potenciaDemandadaEnWP6: "Potencia demandada kWP6",
  codigoDHEquipoDeMedida: "Codigo Equipo",
  codigoTipoLectura: "Codigo tipo lectura",
};

const sipsConsumosExpandableFields = {};

class SipDetails extends React.Component<any, any> {
  lastFilters = {};
  state = {
    isLoading: false,
    copiedText: null,
    currentPage: 0,
    pageSize: 0,
    pageCount: 0,
    count: 0,
    cups: "",
    page: 1,
    limit: 10,
    sipsConsumosTotal: [],
    sipsConsumos: [],
    sipsDetail: [],
    isLoadingSips: true,
    isLoadingConsumos: true,
    isLoadingConsumosTotales: true,
    paginatedData: {
      items: [],
    },
  };

  sipsCSVData: any = [];
  viewSipsConsumoAnualCSVData: any = [];
  sipsConsumosCSVData: any = [];
  sipstotal: any = [];

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.setState({ cups: params.cups }, this.fetchItems);
  }

  fetchItems = async () => {
    // Sips
    await this.props.client
      .query({
        query: VIEW_SIPS,
        variables: { cups: this.state.cups },
      })
      .then((result) => {
        this.setState({ sipsDetail: result.data.viewSips });
        this.setState({ isLoadingSips: false });
        if (result.data.viewSips) {
          const sipsCSVHeader: any = Object.values(
            highPriorityItemsFields
          ).concat(Object.values(lowPriorityItemsFields)) as any;
          const sipsFields = Object.keys({
            ...highPriorityItemsFields,
            ...lowPriorityItemsFields,
          });

          const csvArray = result.data.viewSips.map((o) => {
            return sipsFields.map((field) => {
              if (field.includes("fecha") && o[field]) {
                const dateValue = new Date(o[field]);

                o[field] = moment(dateValue).format("DD-MM-YYYY");
              }
              return o[field];
            });
          });
          this.sipsCSVData = [];
          this.sipsCSVData = this.sipsCSVData.concat([["Datos de suministro"]]);
          this.sipsCSVData = this.sipsCSVData.concat([[]]);
          this.sipsCSVData = this.sipsCSVData.concat([[""]]);
          this.sipsCSVData = this.sipsCSVData.concat([[]]);
          this.sipsCSVData = this.sipsCSVData.concat([sipsCSVHeader]);
          this.sipsCSVData = this.sipsCSVData.concat(csvArray);
        }
      });
    // Sips Consumos Total
    await this.props.client
      .query({
        query: VIEW_SIPS_CONSUMOS_TOTAL,
        variables: { cups: this.state.cups },
      })
      .then((result) => {
        this.setState({ sipsConsumosTotal: result.data.viewSipsConsumoAnual });
        this.setState({ isLoadingConsumosTotales: false });
        if (result.data.viewSipsConsumoAnual) {
          const viewSipsConsumoAnualCSVHeader: any = Object.values(
            sipsConsumosTotalFields
          );
          const viewSipsConsumoAnualFields = Object.keys(
            sipsConsumosTotalFields
          );

          const csvArray = result.data.viewSipsConsumoAnual.map((o) => {
            return viewSipsConsumoAnualFields.map((field) => o[field]);
          });

          this.viewSipsConsumoAnualCSVData = [];
          this.viewSipsConsumoAnualCSVData = this.viewSipsConsumoAnualCSVData.concat(
            [[]]
          );
          this.viewSipsConsumoAnualCSVData = this.viewSipsConsumoAnualCSVData.concat(
            [[""]]
          );
          this.viewSipsConsumoAnualCSVData = this.viewSipsConsumoAnualCSVData.concat(
            [[]]
          );
          this.viewSipsConsumoAnualCSVData = this.viewSipsConsumoAnualCSVData.concat(
            [["Consumos totales"]]
          );
          this.viewSipsConsumoAnualCSVData = this.viewSipsConsumoAnualCSVData.concat(
            [[]]
          );
          this.viewSipsConsumoAnualCSVData = this.viewSipsConsumoAnualCSVData.concat(
            [[""]]
          );
          this.viewSipsConsumoAnualCSVData = this.viewSipsConsumoAnualCSVData.concat(
            [[]]
          );
          this.viewSipsConsumoAnualCSVData = this.viewSipsConsumoAnualCSVData.concat(
            [viewSipsConsumoAnualCSVHeader]
          );
          this.viewSipsConsumoAnualCSVData = this.viewSipsConsumoAnualCSVData.concat(
            csvArray
          );
        }
      });
    // Sips Consumos
    await this.props.client
      .query({
        query: LIST_SIPS_CONSUMOS,
        variables: { cups: this.state.cups },
      })
      .then((result) => {
        this.setState({ sipsConsumos: result.data.listSipsConsumos });
        this.setState({ isLoadingConsumos: false });
        if (result.data.listSipsConsumos) {
          const listSipsConsumosCSVHeader: any = Object.values(
            sipsConsumosFields
          );
          const listSipsConsumosFields = Object.keys(sipsConsumosFields);

          const csvArray = result.data.listSipsConsumos.map((o) => {
            return listSipsConsumosFields.map((field) => {
              if (field.includes("fecha") && o[field]) {
                const dateValue = new Date(o[field]);
                o[field] = moment(dateValue).format("DD-MM-YYYY");
              }
              return o[field];
            });
          });

          this.sipsConsumosCSVData = [];
          this.sipsConsumosCSVData = this.sipsConsumosCSVData.concat([[]]);
          this.sipsConsumosCSVData = this.sipsConsumosCSVData.concat([[""]]);
          this.sipsConsumosCSVData = this.sipsConsumosCSVData.concat([[]]);
          this.sipsConsumosCSVData = this.sipsConsumosCSVData.concat([
            ["Consumos"],
          ]);
          this.sipsConsumosCSVData = this.sipsConsumosCSVData.concat([[]]);
          this.sipsConsumosCSVData = this.sipsConsumosCSVData.concat([[""]]);
          this.sipsConsumosCSVData = this.sipsConsumosCSVData.concat([[]]);
          this.sipsConsumosCSVData = this.sipsConsumosCSVData.concat([
            listSipsConsumosCSVHeader,
          ]);
          this.sipsConsumosCSVData = this.sipsConsumosCSVData.concat(csvArray);
          this.sipstotal = this.sipsCSVData;
          this.sipstotal = this.sipstotal.concat(
            this.viewSipsConsumoAnualCSVData
          );
          this.sipstotal = this.sipstotal.concat(this.sipsConsumosCSVData);
          this.forceUpdate();
        }
      });
  };

  onPageClick = (page) => {};

  export = () => {};

  render() {
    const disableExport =
      this.state.isLoadingSips ||
      this.state.isLoadingConsumos ||
      this.state.isLoadingConsumosTotales ||
      this.state.isLoadingSips;
    const disableExportClass = disableExport ? " disabled" : "";

    return (
      <>
        <Header title={'Suministro Eléctrico "' + this.state.cups + '"'} />
        <Container className=" mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardBody>
                  <div className="text-left">
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={this.props.history.goBack}
                    >
                      Atrás
                    </button>
                  </div>
                  <div className="text-right">
                    {this.sipstotal ? (
                      <CSVLink
                        data={this.sipstotal}
                        onClick={() => !disableExport}
                        separator={";"}
                        filename={"sips_" + this.state.cups + ".csv"}
                        className={
                          "btn btn-sm btn-primary" + disableExportClass
                        }
                      >
                        Exportar
                      </CSVLink>
                    ) : null}
                  </div>
                  <CardTitle>Detalles</CardTitle>
                  <LoadingOverlay
                    active={this.state.isLoadingSips}
                    spinner
                    text="Cargando..."
                  >
                    <SipsTable
                      showProblemInfo={true}
                      hideViewMoreButton={true}
                      items={this.state.sipsDetail}
                      fields={highPriorityItemsFields}
                      expandableFields={lowPriorityItemsFields}
                    ></SipsTable>
                  </LoadingOverlay>
                </CardBody>
              </Card>

              <Card className="mt-3 shadow">
                <CardBody>
                  <CardTitle>Consumo totales</CardTitle>
                  <LoadingOverlay
                    active={this.state.isLoadingConsumosTotales}
                    spinner
                    text="Cargando..."
                  >
                    <SipsTable
                      hideViewMoreButton={true}
                      items={this.state.sipsConsumosTotal}
                      fields={sipsConsumosTotalFields}
                      expandableFields={sipsConsumosTotalExpandableFields}
                    ></SipsTable>
                  </LoadingOverlay>
                </CardBody>
              </Card>
              <Card className="mt-3 shadow">
                <CardBody>
                  <CardTitle>Consumos</CardTitle>
                  <LoadingOverlay
                    active={this.state.isLoadingConsumos}
                    spinner
                    text="Cargando..."
                  >
                    <SipsTable
                      hideViewMoreButton={true}
                      items={this.state.sipsConsumos}
                      fields={sipsConsumosFields}
                      expandableFields={sipsConsumosExpandableFields}
                    ></SipsTable>
                  </LoadingOverlay>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default withApollo(SipDetails);
