import React from "react";
import { Card, CardBody, Form, Input, Row, Table, Col, FormGroup, Label} from "reactstrap";
//import { Bar } from '@reactchartjs/react-chart.js';
//import { withApollo } from "react-apollo";
//import client from "../../../api/client";
//import { Bar,  HorizontalBar  } from "react-chartjs-2";
import SipsTable from "../../../components/Sips/SipsTableNew";
import { PieChart, Pie, Sector, BarChart, Bar, ReferenceLine, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

//import { LIST_COMERCIAL_SERVICIOS} from "../../../queries/comercial-producto/comercial-producto";
import moment from 'moment';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const options = [
  { value: '0', label: 'Sin Impuestos' },
  { value: '0.21', label: '21 %' },
  { value: '0.10', label: '10%' },
];

const reactSelectStyles = {
    container: (provided, state) => ({
        ...provided,
        padding: 0,
        height: '25px',
        zIndex: 4
    }),
    control: (provided, state) => ({
        ...provided,
        borderWidth: 0,
        minHeight: '25px',
        height: '25px'
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '25px'
    }),
    input: (provided, state) => ({
        ...provided,
        height: '20px'
    })
};

const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '25px',
      height: '25px',
      boxShadow: state.isFocused ? null : null,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '25px',
      padding: '0 6px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '25px',
    }),
  };

  const rates6XPotencia= [
     '012',
     '013',
     '014',
     '015',
     '016',
     '019',
     '020',
     '021',
     '022',
     '023',
     '024',
     '025'
 ];

 const notifyBIE = () => toast.error(
   "La potencia introducida es mayor que la autorizada en BIE",
   {
     position: "bottom-center",
     autoClose: 3000,
     hideProgressBar: false,
     closeOnClick: true,
     pauseOnHover: true,
     draggable: true,
     progress: undefined,
 });


const notifyP1Error = () => toast.error(
  "La potencia introducida para ese periodo no puede ser mayor que las existentes para otro periodos",
  {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
});

const notifyPError = () => toast.error(
  "La potencia introducida para ese periodo no puede ser menor que las existentes para otro periodos",
  {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
});

//const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
//const COLORS = ['#0088FE', '#887cf5', '#c16ee1', '#e861c5', '#ff59a4','#ff5e82,'#ff6c60','#ff8042' ];
const COLORS = ['#0088FE', '#a177ef', '#e163cb', '#ff599d', '#ff656e','#ff8042'];

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, name } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={COLORS[name % COLORS.length]}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={COLORS[name % COLORS.length]}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`ENERGIA ${payload.periodo} ${value} Kwh`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

interface IComercialProductoMultiProps {
    sip: any,
    periods: number[],
    productoMulti: {
        active: boolean,
        productoSeleccionado: any[],
        costeOperativo: number,
        costeOperativoPotencia: number,
        esNuevoContrato:boolean
    };
    itemsConsumo: any[],
    output: any,
    ubi: any
}

interface IComercialProductoMultiState {
    sip: any,
    ubi:any,
    periods: number[],

    active: boolean
    producto: any []
    adFincas: boolean,
    agencia: boolean,
    comercial: boolean,
    comercialServicios: any[],
    costeOperativo: number,
    costeOperativoPotencia: number,
    daysOperacion: number,
    cambioPotencia: number,
    rates1XP:any[],
    rates2X: any[],
    rates3X: any[],
    rates2XP: any[],
    rates3XP: any[],
    rates6XP: any[],
    rates1XE: any[],
    rates2XE:  any[],
    rates3XE:  any[],
    rates6XE: any[],
    ratesMonth1:any[],
    ratesMonth2:any[],
    ratesMonth3:any[],
    ratesMonth4:any[],

    itemsTabla: any[];

  idservicio: any,
    idComercializadora: any,
    nombreComercializadora: any,
    nombreServicio: any,
    precioServicio: any,

    esNuevoContrato:boolean

    formMulti: any[],

    formDatosContrato:{
        tarifaATR: any,
        bie:number,
        potenciaP1 : number,
        potenciaP2 : number,
        potenciaP3 : number,
        potenciaP4 : number,
        potenciaP5 : number,
        potenciaP6 : number,
        potenciaContratoP1 : number,

        potenciaContratoP2 : number,
        potenciaContratoP3 : number,
        potenciaContratoP4 : number,
        potenciaContratoP5 : number,
        potenciaContratoP6 : number
    },

    formMultiProducto: {
        sumPotencia: number,
        sumEnergia: number,
        iePercent: number;
        impuestoElectrico: number;
        iva: number;
        ivaPorcentaje: number;
        bi: number;
        total: number;
        serviciosAdicionalesMonth: number;
        serviciosAdicionales: number;
        alquilerEquipoMedidaMonth: number;
        alquilerEquipoMedida: number;
        codigoATR: any;
        numDaysNew: number;
        numMonthNew: number;
        fechaDesdeConsumo: any;
        fechaHastaConsumo:  any;
        penalizacionPotencia: number,
        penalizacionEnergia: number,
        datasetProducto: number[],
        EnergiaActiva: any[],
        Potencia: any[],
        potenciaP1: number,
        potenciaP2: number,
        potenciaP3: number,
        potenciaP4: number,
        potenciaP5: number,
        potenciaP6: number,

        potenciaContratoP1: number,
        potenciaContratoP2: number,
        potenciaContratoP3: number,
        potenciaContratoP4: number,
        potenciaContratoP5: number,
        potenciaContratoP6: number,

        energiaActivaP1: number,
        energiaActivaP2: number,
        energiaActivaP3: number,
        energiaActivaP4: number,
        energiaActivaP5: number,
        energiaActivaP6: number,

        energiaReactivaP1: number,
        energiaReactivaP2: number,
        energiaReactivaP3: number,
        energiaReactivaP4: number,
        energiaReactivaP5: number,
        energiaReactivaP6: number,


                precioPotenciaP1 :  number,
                precioPotenciaP2 : number,
                precioPotenciaP3 : number,
                precioPotenciaP4 : number,
                precioPotenciaP5 : number,
                precioPotenciaP6 : number,

                precioEnergiaP1 : number,
                precioEnergiaP2 :  number,
                precioEnergiaP3 : number,
                precioEnergiaP4 : number,
                precioEnergiaP5 : number,
                 precioEnergiaP6 : number,


        idServicio: any,
        idComercializadora: any,
        nombreComercializadora: any,
        nombreServicio: any,
        precioServicio: number,
        nombreProducto:any,

        comisionContratoFija: number,
        comisionContratoConsumoAgente1: number,
        comisionContratoConsumoAgente2: number,
        comisionContratoConsumoAdmin: number

    },
    formActual: {

        precioTP1: number,
        precioTP2: number,
        precioTP3: number,
        precioTP4: number,
        precioTP5: number,
        precioTP6: number,

        precioTEP1: number,
        precioTEP2: number,
        precioTEP3: number,
        precioTEP4: number,
        precioTEP5: number,
        precioTEP6: number,

        serviciosAdicionalesMonth: number;
        serviciosAdicionales: number;
        alquilerEquipoMedidaMonth: number;
        alquilerEquipoMedida: number;
        codigoATR: any;

        numDaysNew: number;
        numMonthNew: number;
        fechaDesdeConsumo: any;
        fechaHastaConsumo:  any;

        sumPotencia: number;
        sumEnergia: number;
        iePercent: number;
        impuestoElectrico: number;
        iva: number;
        ivaPorcentaje: number;
        bi: number;
        total: number;

        penalizacionPotencia: number,
        penalizacionEnergia: number;

        datasetActual: number[],
        datasetLeyenda:any[],
        descuentoMonth: number,
        descuento: number,
        descuentoPotencia: number,
        descuentoPotenciaMonth: number
    },
    totalComparacion: any[]
  /*  {
        ahorroAnual: number,
        ahorroPercent: number,
        ahorroPeriodo: number,
        datosAhorro: number[]
    },
  */
    itemsConsumo: any[];
    productSelected: any;
    selectedOption: string;
activeIndex: number,
    penalizacionPotenciaCalculo: number;
    penalizacionReactivaCalculo: number;

    consumosMaxMin: { max :{
        P1: number,
        P2: number,
        P3: number,
        P4: number,
        P5: number,
        P6: number,
      },
      min:{
          P1: number,
          P2: number,
          P3: number,
          P4: number,
          P5: number,
          P6: number,
      }
    }
}


export default class ComercialProductoMulti extends React.Component<IComercialProductoMultiProps, IComercialProductoMultiState>
{
	public formActualRef: any = null;
	public formMultiProductoRef: any = null;
	public state: IComercialProductoMultiState = {
        formMulti:[],

        formDatosContrato:{
            tarifaATR: '',
            bie:0,
            potenciaP1 : 0,
            potenciaP2 : 0,
            potenciaP3 : 0,
            potenciaP4 : 0,
            potenciaP5 : 0,
            potenciaP6 : 0,
            potenciaContratoP1 : 0,

            potenciaContratoP2 : 0,
            potenciaContratoP3 : 0,
            potenciaContratoP4 : 0,
            potenciaContratoP5 : 0,
            potenciaContratoP6 : 0
        },
        formMultiProducto: {
            serviciosAdicionalesMonth: 0,
            serviciosAdicionales: 0,
            alquilerEquipoMedidaMonth: 0,
            alquilerEquipoMedida: 0,
            codigoATR: 0,

            sumPotencia: 0,
            sumEnergia: 0,
            iePercent: 0,
            impuestoElectrico: 0,
            iva: 0,
            ivaPorcentaje: 0,
            bi: 0,
            total: 0,

            EnergiaActiva: [],
            Potencia:[],

            potenciaP1 : 0,

        potenciaP2 : 0,
        potenciaP3 : 0,
        potenciaP4 : 0,
        potenciaP5 : 0,
        potenciaP6 : 0,

        potenciaContratoP1 : 0,

    potenciaContratoP2 : 0,
    potenciaContratoP3 : 0,
    potenciaContratoP4 : 0,
    potenciaContratoP5 : 0,
    potenciaContratoP6 : 0,

        energiaActivaP1: 0,
        energiaActivaP2: 0,
        energiaActivaP3: 0,
        energiaActivaP4: 0,
        energiaActivaP5: 0,
        energiaActivaP6: 0,

        energiaReactivaP1: 0,
        energiaReactivaP2: 0,
        energiaReactivaP3: 0,
        energiaReactivaP4: 0,
        energiaReactivaP5: 0,
        energiaReactivaP6: 0,

        precioPotenciaP1 :  0,
        precioPotenciaP2 : 0,
        precioPotenciaP3 : 0,
        precioPotenciaP4 : 0,
        precioPotenciaP5 : 0,
        precioPotenciaP6 : 0,

        precioEnergiaP1 : 0,
        precioEnergiaP2 :  0,
        precioEnergiaP3 : 0,
        precioEnergiaP4 : 0,
        precioEnergiaP5 : 0,
         precioEnergiaP6 : 0,

            numDaysNew: 0,
            numMonthNew: 0,
            fechaDesdeConsumo: null,
            fechaHastaConsumo:  null,

            penalizacionPotencia: 0,
            penalizacionEnergia: 0,

            datasetProducto: [],
          idServicio : null,
          idComercializadora: null,
         nombreComercializadora: "",
            nombreServicio: null,
            precioServicio: 0,
            nombreProducto: "",

            comisionContratoFija: 0,
            comisionContratoConsumoAgente1: 0,
            comisionContratoConsumoAgente2: 0,
            comisionContratoConsumoAdmin: 0



        },
        formActual: {

            descuento:0,
            descuentoMonth:0,
            descuentoPotencia:0,
            descuentoPotenciaMonth:0,
            serviciosAdicionalesMonth: 0,
            serviciosAdicionales: 0,
            alquilerEquipoMedidaMonth: 0,
            alquilerEquipoMedida: 0,
            codigoATR: 0,

            numDaysNew: 0,
            numMonthNew: 0,
            fechaDesdeConsumo: null,
            fechaHastaConsumo:  null,

            sumPotencia: 0,
            sumEnergia: 0,
            iePercent: 0,
            impuestoElectrico: 0,
            iva: 0,
            ivaPorcentaje: 0,
            bi: 0,
            total: 0,

            penalizacionPotencia: 0,
            penalizacionEnergia: 0,

            datasetActual: [],
            datasetLeyenda:[],

            precioTP1: 0,
            precioTP2: 0,
            precioTP3: 0,
            precioTP4: 0,
            precioTP5: 0,
            precioTP6: 0,
            precioTEP1: 0,
            precioTEP2: 0,
            precioTEP3: 0,
            precioTEP4: 0,
            precioTEP5: 0,
            precioTEP6: 0,
        },
        totalComparacion:[],
/*
        {
            ahorroAnual: 0,
            ahorroPercent: 0,
            ahorroPeriodo: 0,
            datosAhorro:[]
        },
  */
        comercialServicios: [],
esNuevoContrato: true,
        rates2X: [
            '001',
            '004',
            '005',
            '006',
            '007',
            '008'
        ],
        rates3X: [
            '011',
            '003',
            '012',
            '013',
            '014',
            '015',
            '016'
        ],
        rates2XP: [
            '018',
        ],
        rates1XP:[
          '001',
          '004',
          '005',
          '006',
          '007',
          '008'
        ],
        rates3XP: [
            '011',
            '003'
        ],
           rates6XP: [
             '012',
             '013',
             '014',
             '015',
             '016',
             '019',
             '020',
             '021',
             '022',
             '023',
             '024',
             '025'
        ],
         rates1XE: [
            '001',
            '005'
        ],
        rates2XE: [
            '004',
            '006'
        ],
        rates3XE: [
            '011',
            '003',
            '007',
            '008',
            '018'
        ],
           rates6XE: [
            '012',
            '013',
            '014',
            '015',
            '016',
            '019',
            '020',
            '021',
            '022',
            '023',
            '024',
            '025'
        ],
        ratesMonth1:[1,2,7,12],
        ratesMonth2:[3,11],
        ratesMonth3:[4,5,10],
        ratesMonth4:[6,8,9],

        idservicio: null,
        idComercializadora: null,
        nombreComercializadora: null,
        nombreServicio: null,
        precioServicio: null,
        daysOperacion: 0,

        adFincas: false,
        agencia: true,
        comercial: false,

        active: false,
        sip: {},
        ubi:{},
        periods: [],
        itemsTabla: [],
        itemsConsumo: [],
		producto: [],
        costeOperativo: 0,
        costeOperativoPotencia:0,
        productSelected: null,
        selectedOption: '',
        cambioPotencia:0,
        penalizacionPotenciaCalculo: 0,
        penalizacionReactivaCalculo: 0,
        activeIndex: 0,
        consumosMaxMin: { max :{
            P1: 0,
            P2: 0,
            P3: 0,
            P4: 0,
            P5: 0,
            P6: 0
          },
          min:{
              P1: 0,
              P2: 0,
              P3: 0,
              P4: 0,
              P5: 0,
              P6: 0
          }
        }

	};

    componentWillReceiveProps( newProps: IComercialProductoMultiProps )
    {

        let inputState: any = { ...newProps };

        if( newProps.sip )
        {
            var state = Object.assign(this.state, {
                formActual: Object.assign(this.state.formActual, {
                    penalizacionPotencia: Number(newProps.sip['penalizacionPotencia'] || 0),
                    penalizacionEnergia: Number(newProps.sip['penalizacionReactiva'] || 0)
                }),
                formMulti: Object.assign(this.state.formMultiProducto, {
                    penalizacionPotencia: Number(newProps.sip['penalizacionPotencia'] || 0),
                    penalizacionEnergia: Number(newProps.sip['penalizacionReactiva'] || 0)
                }),
                formMultiProducto: Object.assign(this.state.formMultiProducto, {
                    penalizacionPotencia: Number(newProps.sip['penalizacionPotencia'] || 0),
                    penalizacionEnergia: Number(newProps.sip['penalizacionReactiva'] || 0)
                })
            });
            this.setState(state);
        }

        this.setState({
          productSelected:null
        });


        if( newProps.productoMulti.productoSeleccionado )
        {

            let newItemsTabla:any[] = [];
            delete inputState.producto;
            this.fetchItems()

            let maxP1 = 0;
            let minP1 = 0;
            let maxP2 = 0;
            let minP2 = 0;
            let maxP3 = 0;
            let minP3 = 0;
            let maxP4 = 0;
            let minP4 = 0;
            let maxP5 = 0;
            let minP5 = 0;
            let maxP6 = 0;
            let minP6 = 0;


            const producto:any[] = { ...newProps.productoMulti.productoSeleccionado };
            const costeOperativo = newProps.productoMulti.costeOperativo;
            const costeOperativoPotencia = newProps.productoMulti.costeOperativoPotencia;
            const esNuevoContrato = newProps.productoMulti.esNuevoContrato
            let itemsConsumo = {...newProps.itemsConsumo}

            if(rates6XPotencia.includes(this.state.sip.codigoTarifaATREnVigor)){

             minP1 = Math.min.apply(null, this.state.itemsConsumo.map(function(item) {
                 return item.potenciaDemandadaEnWP1;
               }));
             maxP1 = Math.max.apply(null, this.state.itemsConsumo.map(function(item) {
                 return item.potenciaDemandadaEnWP1;
               }));

               minP2 = Math.min.apply(null, this.state.itemsConsumo.map(function(item) {
                   return item.potenciaDemandadaEnWP2;
                 }));
               maxP2 = Math.max.apply(null, this.state.itemsConsumo.map(function(item) {
                   return item.potenciaDemandadaEnWP2;
                 }));
                 minP3 = Math.min.apply(null, this.state.itemsConsumo.map(function(item) {
                     return item.potenciaDemandadaEnWP3;
                   }));
                 maxP3 = Math.max.apply(null, this.state.itemsConsumo.map(function(item) {
                     return item.potenciaDemandadaEnWP3;
                   }));
                   minP4 = Math.min.apply(null, this.state.itemsConsumo.map(function(item) {
                       return item.potenciaDemandadaEnWP4;
                     }));
                   maxP4 = Math.max.apply(null, this.state.itemsConsumo.map(function(item) {
                       return item.potenciaDemandadaEnWP4;
                     }));
                     minP5 = Math.min.apply(null, this.state.itemsConsumo.map(function(item) {
                         return item.potenciaDemandadaEnWP5;
                       }));
                     maxP5 = Math.max.apply(null, this.state.itemsConsumo.map(function(item) {
                         return item.potenciaDemandadaEnWP5;
                       }));
                       minP6 = Math.min.apply(null, this.state.itemsConsumo.map(function(item) {
                           return item.potenciaDemandadaEnWP6;
                         }));
                       maxP6 = Math.max.apply(null, this.state.itemsConsumo.map(function(item) {
                           return item.potenciaDemandadaEnWP6;
                         }));

                };

            let periodos:number[] = [];

            // // console.log('VALORES DE producto en componentWillReceiveProps')
            // // console.log(producto)

            newProps.productoMulti.productoSeleccionado.map( row => {

                //// console.log('ESTOY DENTRO producto.map')
              var periodosNuevos = 0
              if (this.state.sip.codigoTarifaATREnVigor == '001' || this.state.sip.codigoTarifaATREnVigor == '004'|| this.state.sip.codigoTarifaATREnVigor == '005'|| this.state.sip.codigoTarifaATREnVigor == '006'|| this.state.sip.codigoTarifaATREnVigor == '007'|| this.state.sip.codigoTarifaATREnVigor == '008'){
                periodosNuevos = 3
              } else if (this.state.sip.codigoTarifaATREnVigor == '018'){
                  periodosNuevos = 3
              } else {periodosNuevos = 6}

 //// console.log('VALOR DE PeriodosNuevos = ', periodosNuevos)

            for( let i=1; i<=periodosNuevos; i++ )
            {


              //  // console.log('ESTOY DENTRO del for en el producto.map')
              //  // console.log(row)

                // // console.log(row['id']);
                let consumoAnual = `ConsumoAnualActivaP${i}`

                if(this.state.sip[consumoAnual] >= 0 && i<=periodosNuevos)
                {
                    let precioPotenciaField = `precioTP${i}`;
                    let precioEnergiaField = `precioTEP${i}`;
                    let periodo = `P${i}`;
                    periodos.push(i);
                    let precioPotencia = 0;
                    let precioEnergia = 0;
                    if (row['coIncluido'] === false) {

                        precioPotencia = row[precioPotenciaField] + costeOperativoPotencia;
                        precioEnergia = row[precioEnergiaField] + costeOperativo;
                    } else {
                        precioPotencia = row[precioPotenciaField];
                        precioEnergia = row[precioEnergiaField];
                    }
                    let id = row['id']

                    /*switch(row['precioTPTipo']) {
                        case 'DIARIO':
                            // // console.log('DENTRO DE DIARIO')
                            if (i===1){
                            precioPotencia = row['precioTPPeriodo'];}
                            else {precioPotencia = null};
                            break;
                        case 'MENSUAL':
                            // // console.log('DENTRO DE MENSUAL')
                            if (i===1){
                            precioPotencia = row['precioTPPeriodo'];}
                            else {precioPotencia = null};
                            break;
                    }
                    */

                    newItemsTabla.push({ id: id, periodo:periodo, precioPotencia: precioPotencia, precioEnergia: precioEnergia });
                    // // console.log(newItemsTabla)
                  }
                }
                      return row;
                  });

                  var consumosMaxMin = {
                    max: {
                      P1: maxP1,
                      P2: maxP2,
                      P3: maxP3,
                      P4: maxP4,
                      P5: maxP5,
                      P6: maxP6},
                  min: {
                      P1: minP1,
                      P2: minP2,
                      P3: minP3,
                      P4: minP4,
                      P5: minP5,
                      P6: minP6}
                  };

                let potenciaContratoP1 = newProps.sip.potenciasContratadasEnWP1
                let potenciaContratoP2 = newProps.sip.potenciasContratadasEnWP2
                let potenciaContratoP3 = newProps.sip.potenciasContratadasEnWP3
                let potenciaContratoP4 = newProps.sip.potenciasContratadasEnWP4
                let potenciaContratoP5 = newProps.sip.potenciasContratadasEnWP5
                let potenciaContratoP6 = newProps.sip.potenciasContratadasEnWP6

                let tarifaATR = newProps.sip.codigoTarifaATREnVigor
                let bie = Number(newProps.sip.potenciaMaximaBIEW)||0;
                var formDatosContrato = {
                      tarifaATR: tarifaATR,
                      bie: bie,
                      potenciaP1 : 0,
                      potenciaP2 : 0,
                      potenciaP3 : 0,
                      potenciaP4 : 0,
                      potenciaP5 : 0,
                      potenciaP6 : 0,
                      potenciaContratoP1 : potenciaContratoP1,

                      potenciaContratoP2 : potenciaContratoP2,
                      potenciaContratoP3 : potenciaContratoP3,
                      potenciaContratoP4 : potenciaContratoP4,
                      potenciaContratoP5 : potenciaContratoP5,
                      potenciaContratoP6 : potenciaContratoP6
                  }

//// console.log('------------------------')
//            // console.log(producto)
//            // console.log(newItemsTabla)
//// console.log('-------------------------')

            inputState.producto = producto;
            inputState.itemsTabla = newItemsTabla;
            inputState.costeOperativo = costeOperativo;
            inputState.costeOperativoPotencia = costeOperativoPotencia;
            inputState.esNuevoContrato = newProps.productoMulti.esNuevoContrato;
            //inputState.active = newProps.producto.active;
            this.setState(  {
                ...this.state,
                ...inputState,
                consumosMaxMin: consumosMaxMin,
                formDatosContrato: formDatosContrato
            }, ()=>{
                this.renderFacturaProducto();
                this.renderFacturaActual();

            })
        }
        else
        {
            this.setState(  {
                ...this.state,
                ...inputState
            })
        }

    }

	componentDidMount() {

        this.formActualRef = React.createRef();
        this.formMultiProductoRef = React.createRef();
        let adFincas = false
        let agencia =  true
        let comercial =  false

        const userFromStorage = localStorage.getItem("user");
        if (userFromStorage) {
          try {
            const parsed = JSON.parse(userFromStorage);

            adFincas = parsed.adFincas;
            agencia = parsed.agencia;
            comercial = parsed.comercial;


          } catch (e) {}
        }

        this.setState({
          productSelected:null,  adFincas, agencia,comercial
        });
        // // console.log("DEFAULT",this.state);
        // this.state.productSelected =  this.state.producto[0].id;
    };

////////////////////////////////////////////////////////////////////////////////////////////////////////

	fetchItems = async () => {

    // aquí empieza la selección de servicios por Comercializadora
/*
    const companyUser = await JSON.parse(await localStorage.getItem("user")!);
    var idComercializadora = this.state.producto.idComercializadora;
    if (idComercializadora == null){
      idComercializadora = '0000'};


   await client.query({
          query: LIST_COMERCIAL_SERVICIOS,
    variables: { idComercializadora: idComercializadora},
      })
      .then((result) => {
          let data = result.data.comercialServicios;

          this.setState({ comercialServicios: data})
      });
      */
    }


    onChangeFieldFormActual= async ( event) => {
      event.persist()
      // console.log('valore de value', event)
        let inputs:any = await this.onChangeField( event );
// console.log('VALORES DE INPUTS ',inputs)
        if( inputs )
        {
            this.setState({
                formActual: {
                    ...this.state.formActual,
                    ...inputs
                }
            }, this.renderFacturaActual );
        }
    }

    onChangeFieldformMultiProducto = async ( event ) => {
        let inputs:any = await this.onChangeField( event );

        let cambioPotencia = this.state.cambioPotencia||0
        if( inputs ){

            if(inputs.potenciaContratoP1 || inputs.potenciaContratoP2 || inputs.potenciaContratoP3 || inputs.potenciaContratoP4 || inputs.potenciaContratoP5 || inputs.potenciaContratoP6 ){
                cambioPotencia = 1
            }
            this.setState({
                formMultiProducto: {
                    ...this.state.formMultiProducto,
                    ...inputs
                },
                cambioPotencia:cambioPotencia
            }, this.renderFacturaProducto);
        }
    }

    onChangeFieldformMultiProductoPotencia = async ( event ) => {
        let inputs:any = await this.onChangeField( event );
        // console.log('VALORES DE INPUTS EN EL CAMBIO DE POTENCIA ----> ', inputs)
        // console.log('VALOR DE formDatosContratos ----> ', this.state.formDatosContrato)
        // console.log('VALORES DE THIS STATE EN INPUTS CHANGE ------> ', this.state)
        let cambioPotencia = this.state.cambioPotencia||0
        let bie = this.state.formDatosContrato.bie||0
        let tarifaATR = this.state.formDatosContrato.tarifaATR||0
        let formDatosContrato = this.state.formDatosContrato

        if( inputs ){

if(this.state.periods.length > 3){
            if(inputs.potenciaContratoP1){
            if(inputs.potenciaContratoP1 <= bie || inputs.potenciaContratoP2 <= bie || inputs.potenciaContratoP3 <= bie || inputs.potenciaContratoP4 <= bie || inputs.potenciaContratoP5 <= bie|| inputs.potenciaContratoP6 <= bie){
                if(inputs.potenciaContratoP1 <= formDatosContrato.potenciaContratoP2 && formDatosContrato.potenciaContratoP2 <= formDatosContrato.potenciaContratoP3 && formDatosContrato.potenciaContratoP3 <= formDatosContrato.potenciaContratoP4 && formDatosContrato.potenciaContratoP4 <= formDatosContrato.potenciaContratoP5 && formDatosContrato.potenciaContratoP5 <= formDatosContrato.potenciaContratoP6){
                cambioPotencia = 1
                this.setState({
                    formDatosContrato: {
                        ...this.state.formDatosContrato,
                        ...inputs
                    },
                    cambioPotencia:cambioPotencia
                }, this.renderFacturaProducto);
            } else {
                cambioPotencia = 1
                this.setState({
                    formDatosContrato: {
                        ...this.state.formDatosContrato
                    },
                    cambioPotencia:cambioPotencia
                }, this.renderFacturaProducto);
                notifyP1Error();

        };}else {
            cambioPotencia = 1
            this.setState({
                formDatosContrato: {
                    ...this.state.formDatosContrato
                },
                cambioPotencia:cambioPotencia
            }, this.renderFacturaProducto);
            notifyBIE();
            }
}

if(inputs.potenciaContratoP2){
if(inputs.potenciaContratoP1 <= bie || inputs.potenciaContratoP2 <= bie || inputs.potenciaContratoP3 <= bie || inputs.potenciaContratoP4 <= bie || inputs.potenciaContratoP5 <= bie|| inputs.potenciaContratoP6 <= bie){
    if(formDatosContrato.potenciaContratoP1 <= inputs.potenciaContratoP2 && inputs.potenciaContratoP2 <= formDatosContrato.potenciaContratoP3 && formDatosContrato.potenciaContratoP3 <= formDatosContrato.potenciaContratoP4 && formDatosContrato.potenciaContratoP4 <= formDatosContrato.potenciaContratoP5 && formDatosContrato.potenciaContratoP5 <= formDatosContrato.potenciaContratoP6){
    cambioPotencia = 1
    this.setState({
        formDatosContrato: {
            ...this.state.formDatosContrato,
            ...inputs
        },
        cambioPotencia:cambioPotencia
    }, this.renderFacturaProducto);
} else {
    cambioPotencia = 1
    this.setState({
        formDatosContrato: {
            ...this.state.formDatosContrato
        },
        cambioPotencia:cambioPotencia
    }, this.renderFacturaProducto);
    notifyPError();

};}else {
cambioPotencia = 1
this.setState({
    formDatosContrato: {
        ...this.state.formDatosContrato
    },
    cambioPotencia:cambioPotencia
}, this.renderFacturaProducto);
notifyBIE();
}
}

if(inputs.potenciaContratoP3){
if(inputs.potenciaContratoP1 <= bie || inputs.potenciaContratoP2 <= bie || inputs.potenciaContratoP3 <= bie || inputs.potenciaContratoP4 <= bie || inputs.potenciaContratoP5 <= bie|| inputs.potenciaContratoP6 <= bie){
    if(formDatosContrato.potenciaContratoP1 <= formDatosContrato.potenciaContratoP2 && formDatosContrato.potenciaContratoP2 <= inputs.potenciaContratoP3 && inputs.potenciaContratoP3 <= formDatosContrato.potenciaContratoP4 && formDatosContrato.potenciaContratoP4 <= formDatosContrato.potenciaContratoP5 && formDatosContrato.potenciaContratoP5 <= formDatosContrato.potenciaContratoP6){
    cambioPotencia = 1
    this.setState({
        formDatosContrato: {
            ...this.state.formDatosContrato,
            ...inputs
        },
        cambioPotencia:cambioPotencia
    }, this.renderFacturaProducto);
} else {
    cambioPotencia = 1
    this.setState({
        formDatosContrato: {
            ...this.state.formDatosContrato
        },
        cambioPotencia:cambioPotencia
    }, this.renderFacturaProducto);
    notifyPError();

};}else {
cambioPotencia = 1
this.setState({
    formDatosContrato: {
        ...this.state.formDatosContrato
    },
    cambioPotencia:cambioPotencia
}, this.renderFacturaProducto);
notifyBIE();
}
}

if(inputs.potenciaContratoP4){
if(inputs.potenciaContratoP1 <= bie || inputs.potenciaContratoP2 <= bie || inputs.potenciaContratoP3 <= bie || inputs.potenciaContratoP4 <= bie || inputs.potenciaContratoP5 <= bie|| inputs.potenciaContratoP6 <= bie){
    if(formDatosContrato.potenciaContratoP1 <= formDatosContrato.potenciaContratoP2 && formDatosContrato.potenciaContratoP2 <= formDatosContrato.potenciaContratoP3 && formDatosContrato.potenciaContratoP3 <= inputs.potenciaContratoP4 && inputs.potenciaContratoP4 <= formDatosContrato.potenciaContratoP5 && formDatosContrato.potenciaContratoP5 <= formDatosContrato.potenciaContratoP6){
    cambioPotencia = 1
    this.setState({
        formDatosContrato: {
            ...this.state.formDatosContrato,
            ...inputs
        },
        cambioPotencia:cambioPotencia
    }, this.renderFacturaProducto);
} else {
    cambioPotencia = 1
    this.setState({
        formDatosContrato: {
            ...this.state.formDatosContrato
        },
        cambioPotencia:cambioPotencia
    }, this.renderFacturaProducto);
    notifyPError();

};}else {
cambioPotencia = 1
this.setState({
    formDatosContrato: {
        ...this.state.formDatosContrato
    },
    cambioPotencia:cambioPotencia
}, this.renderFacturaProducto);
notifyBIE();
}
}

if(inputs.potenciaContratoP5){
if(inputs.potenciaContratoP1 <= bie || inputs.potenciaContratoP2 <= bie || inputs.potenciaContratoP3 <= bie || inputs.potenciaContratoP4 <= bie || inputs.potenciaContratoP5 <= bie|| inputs.potenciaContratoP6 <= bie){
    if(formDatosContrato.potenciaContratoP1 <= formDatosContrato.potenciaContratoP2 && formDatosContrato.potenciaContratoP2 <= formDatosContrato.potenciaContratoP3 && formDatosContrato.potenciaContratoP3 <= formDatosContrato.potenciaContratoP4 && formDatosContrato.potenciaContratoP4 <= inputs.potenciaContratoP5 && inputs.potenciaContratoP5 <= formDatosContrato.potenciaContratoP6){
    cambioPotencia = 1
    this.setState({
        formDatosContrato: {
            ...this.state.formDatosContrato,
            ...inputs
        },
        cambioPotencia:cambioPotencia
    }, this.renderFacturaProducto);
} else {
    cambioPotencia = 1
    this.setState({
        formDatosContrato: {
            ...this.state.formDatosContrato
        },
        cambioPotencia:cambioPotencia
    }, this.renderFacturaProducto);
    notifyPError();

};}else {
cambioPotencia = 1
this.setState({
    formDatosContrato: {
        ...this.state.formDatosContrato
    },
    cambioPotencia:cambioPotencia
}, this.renderFacturaProducto);
notifyBIE();
}
}

if(inputs.potenciaContratoP6){
if(inputs.potenciaContratoP1 <= bie || inputs.potenciaContratoP2 <= bie || inputs.potenciaContratoP3 <= bie || inputs.potenciaContratoP4 <= bie || inputs.potenciaContratoP5 <= bie|| inputs.potenciaContratoP6 <= bie){
    if(formDatosContrato.potenciaContratoP1 <= formDatosContrato.potenciaContratoP2 && formDatosContrato.potenciaContratoP2 <= formDatosContrato.potenciaContratoP3 && formDatosContrato.potenciaContratoP3 <= formDatosContrato.potenciaContratoP4 && formDatosContrato.potenciaContratoP4 <= formDatosContrato.potenciaContratoP5 && formDatosContrato.potenciaContratoP5 <= inputs.potenciaContratoP6){
    cambioPotencia = 1
    this.setState({
        formDatosContrato: {
            ...this.state.formDatosContrato,
            ...inputs
        },
        cambioPotencia:cambioPotencia
    }, this.renderFacturaProducto);
} else {
    cambioPotencia = 1
    this.setState({
        formDatosContrato: {
            ...this.state.formDatosContrato
        },
        cambioPotencia:cambioPotencia
    }, this.renderFacturaProducto);
    notifyPError();

};}else {

cambioPotencia = 1
this.setState({
    formDatosContrato: {
        ...this.state.formDatosContrato
    },
    cambioPotencia:cambioPotencia
}, this.renderFacturaProducto);
notifyBIE();
}
}
}else {

    if(inputs.potenciaContratoP1){
    if(inputs.potenciaContratoP1 <= bie || inputs.potenciaContratoP2 <= bie){
        if(inputs.potenciaContratoP1 <= formDatosContrato.potenciaContratoP2){
        cambioPotencia = 1
        this.setState({
            formDatosContrato: {
                ...this.state.formDatosContrato,
                ...inputs
            },
            cambioPotencia:cambioPotencia
        }, this.renderFacturaProducto);
    } else {
        cambioPotencia = 1
        this.setState({
            formDatosContrato: {
                ...this.state.formDatosContrato
            },
            cambioPotencia:cambioPotencia
        }, this.renderFacturaProducto);
        notifyP1Error();

    };}else {
    cambioPotencia = 1
    this.setState({
        formDatosContrato: {
            ...this.state.formDatosContrato
        },
        cambioPotencia:cambioPotencia
    }, this.renderFacturaProducto);
    notifyBIE();
    }
    }

    if(inputs.potenciaContratoP2){
    if(inputs.potenciaContratoP1 <= bie || inputs.potenciaContratoP2 <= bie){
    if(formDatosContrato.potenciaContratoP1 <= inputs.potenciaContratoP2){
    cambioPotencia = 1
    this.setState({
    formDatosContrato: {
    ...this.state.formDatosContrato,
    ...inputs
    },
    cambioPotencia:cambioPotencia
    }, this.renderFacturaProducto);
    } else {
    cambioPotencia = 1
    this.setState({
    formDatosContrato: {
    ...this.state.formDatosContrato
    },
    cambioPotencia:cambioPotencia
    }, this.renderFacturaProducto);
    notifyPError();

    };}else {
    cambioPotencia = 1
    this.setState({
    formDatosContrato: {
    ...this.state.formDatosContrato
    },
    cambioPotencia:cambioPotencia
    }, this.renderFacturaProducto);
    notifyBIE();
    }
    }


}
        }
}

    onChangeFieldformMultiProductoServicio = async ( event ) => {
        let inputs:any= await this.onChangeField( event );
        let opcion = parseInt(inputs.idServicio)
        let                    idservicio = null;
        let                   idComercializadora = null;
        let                   nombreComercializadora = null;
        let                   nombreServicio = null;
        let                   precioServicio = null;
        // // console.log('VALORES DE onChangeFieldformMultiProductoServicio')
        // // console.log(inputs)
        // // console.log(opcion)

/*
        idservicio: null,
        idComercializadora: null,
        nombreComercializadora: null,
        nombreServicio: null,
        precioServicio: 0,

        id: "09180001"
idComercializadora: "0918"
infoAdicional: null
nombreComercializadora: "ALDRO ENERGIA Y SOLUCIONES S.L"
nombreServicio: "Exprés24"
precio: 5.9
*/

        this.state.comercialServicios.map( row => {
        //   // console.log('ESTOY DENTRO 1')
        //   // console.log(row['id']);
          let fila = parseInt(row['id']);
        //   // console.log(fila)
               if (fila === opcion){
                //    // console.log('ESTOY DENTRO')
                   idservicio = row['id'];
                   idComercializadora = row['idComercializadora'];
                   nombreComercializadora = row['nombreComercializadora'];
                   nombreServicio = row['nombreServicio'];
                   precioServicio = row['precio'];
                //    // console.log(precioServicio);
               }
               return row;
           });


        {
            this.setState({
                    idservicio: idservicio ,
                    idComercializadora: idComercializadora ,
                    nombreComercializadora: nombreComercializadora ,
                    nombreServicio: nombreServicio ,
                    precioServicio: precioServicio

            }, this.renderFacturaProducto);
        }

    }

    private calculateAhorro = ( periodos:number[], facturaActual: any, facturaProducto: any ) => {


        let totalFacturaActual = facturaActual.total;
        let totalFacturaProducto = facturaProducto.total;
        let total:any = facturaProducto
        console.log('VALOR DE calculataAhorro')
        console.log('totalFacturaActual <<<<<<<<<<<<<<<<<<<<<<<< ',facturaActual)
        console.log('totalFacturaProducto <<<<<<<<<<<<<<<<<<<< ', facturaProducto)
        console.log('total <<<<<<<<<<<<<<<<<<<<<<<<<< ', total)


        let ahorroPeriodo = this.roundNumber((totalFacturaActual - totalFacturaProducto));
        let ahorroPercent = this.roundNumber(ahorroPeriodo * 100/totalFacturaActual);
        let datosAhorro= [0];
        datosAhorro.length = 0;
        datosAhorro[datosAhorro.length]=totalFacturaActual;
        datosAhorro[datosAhorro.length]=totalFacturaProducto;
        datosAhorro[datosAhorro.length] =  ahorroPeriodo;

        return {
            ahorroAnual: ahorroPeriodo,
            ahorroPercent: isNaN(ahorroPercent)?0:ahorroPercent,
            ahorroPeriodo: ahorroPeriodo,
            idProducto: facturaProducto.id,
            nombreOferta: facturaProducto.nombreProducto,
            idComercializadora: facturaProducto.idComercializadora,
            nombreComercializadora: facturaProducto.nombreComercializadora,
            precioEnergiaP1: facturaProducto.precioEnergiaP1,
            precioEnergiaP2: facturaProducto.precioEnergiaP2,
            precioEnergiaP3: facturaProducto.precioEnergiaP3,
            precioEnergiaP4: facturaProducto.precioEnergiaP4,
            precioEnergiaP5: facturaProducto.precioEnergiaP5,
            precioEnergiaP6: facturaProducto.precioEnergiaP6,
            precioPotenciaP1: facturaProducto.precioPotenciaP1,
            precioPotenciaP2: facturaProducto.precioPotenciaP2,
            precioPotenciaP3: facturaProducto.precioPotenciaP3,
            precioPotenciaP4: facturaProducto.precioPotenciaP4,
            precioPotenciaP5: facturaProducto.precioPotenciaP5,
            precioPotenciaP6: facturaProducto.precioPotenciaP6,
            datosAhorro: datosAhorro
        }
    }


    private calculateAhorroMulti = ( periodos:number[], facturaActual: any, facturaProducto: any ) => {

console.log(facturaActual)
console.log(facturaProducto)
console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')

        let totalFacturaActual = facturaActual.total;
        let totalFacturaProducto = facturaProducto.total;
        let total:any = facturaProducto
         console.log('VALOR DE totalFActuraActual en Multi actual')
        console.log(totalFacturaActual)
         console.log(totalFacturaProducto)
         console.log(total)


        let ahorroPeriodo = this.roundNumber((totalFacturaActual - totalFacturaProducto));
        let ahorroPercent = this.roundNumber(ahorroPeriodo * 100/totalFacturaActual);
        let datosAhorro= [0];
        datosAhorro.length = 0;
        datosAhorro[datosAhorro.length]=totalFacturaActual;
        datosAhorro[datosAhorro.length]=totalFacturaProducto;
        datosAhorro[datosAhorro.length] =  ahorroPeriodo;

        return {
            ahorroAnual: ahorroPeriodo,
            ahorroPercent: isNaN(ahorroPercent)?0:ahorroPercent,
            ahorroPeriodo: ahorroPeriodo,
            datosAhorro: datosAhorro
        }
    }


    private calculatePrecioProducto = ( periodos: number[], producto: any, factura: any ) => {
        let {
            serviciosAdicionales,
            serviciosAdicionalesMonth,
            alquilerEquipoMedidaMonth,
            alquilerEquipoMedida,
            codigoATR,
            sumPotencia,
            sumEnergia,
            iePercent,
            impuestoElectrico,
            penalizacionPotencia,
            penalizacionEnergia,
            iva,
            ivaPorcentaje,
            bi,
            total,
        numDaysNew,
        numMonthNew,
        fechaDesdeConsumo,
        fechaHastaConsumo,
        Potencia,
        idComercializadora ,
        idServicio,
        nombreComercializadora ,
        nombreServicio ,
        precioServicio,
        idProducto,
        nombreProducto,
        comisionContratoFija,
        comisionContratoConsumoAgente1,
        comisionContratoConsumoAgente2,
        comisionContratoConsumoAdmin
        //datasetProducto
        } = factura;
        const costeOperativo = this.state.costeOperativo;
        const costeOperativoPotencia = this.state.costeOperativoPotencia
        const sip = this.state.sip;
        const ubi = this.state.ubi;
        let consumoPotencia = {};
        let consumoEnergia = {};
        let precioPotencia = {};
        let precioEnergia = {};
        //let numDays;

        fechaDesdeConsumo = null;
        fechaHastaConsumo = null;
        let potenciaP1 = 0;
        let potenciaP2 = 0;
        let potenciaP3 = 0;
        let potenciaP4 = 0;
        let potenciaP5 = 0;
        let potenciaP6 = 0;

        let potenciaContratoP1 = 0;
        let potenciaContratoP2 = 0;
        let potenciaContratoP3 = 0;
        let potenciaContratoP4 = 0;
        let potenciaContratoP5 = 0;
        let potenciaContratoP6 = 0;

        let energiaActivaP1 = 0;
        let energiaActivaP2 = 0;
        let energiaActivaP3 = 0;
        let energiaActivaP4 = 0;
        let energiaActivaP5 = 0;
        let energiaActivaP6 = 0;

        let energiaReactivaP1 = 0;
        let energiaReactivaP2 = 0;
        let energiaReactivaP3 = 0;
        let energiaReactivaP4 = 0;
        let energiaReactivaP5 = 0;
        let energiaReactivaP6 = 0;

        let energiaReactivaExcesoP1 =0;
        let energiaReactivaExcesoP2 =0;
        let energiaReactivaExcesoP3 =0;
        let energiaReactivaExcesoP4 =0;
        let energiaReactivaExcesoP5 =0;
        let energiaReactivaExcesoP6 =0;

        let penalizacionP1 = 0;
        let penalizacionP2 = 0;
        let penalizacionP3 = 0;
        let penalizacionP4 = 0;
        let penalizacionP5 = 0;
        let penalizacionP6 = 0;

        let penalizacionReactivaP1 = 0;
        let penalizacionReactivaP2 = 0;
        let penalizacionReactivaP3 = 0;
        let penalizacionReactivaP4 = 0;
        let penalizacionReactivaP5 = 0;
        let penalizacionReactivaP6 = 0;

        let precioPotenciaP1 = 0;
        let precioPotenciaP2 = 0;
        let precioPotenciaP3 = 0;
        let precioPotenciaP4 = 0;
        let precioPotenciaP5 = 0;
        let precioPotenciaP6 = 0;

        let precioEnergiaP1 = 0
        let precioEnergiaP2 = 0
        let precioEnergiaP3 = 0
        let precioEnergiaP4 = 0
        let precioEnergiaP5 = 0
        let precioEnergiaP6 = 0

        sumEnergia = 0;
        sumPotencia = 0;
        let sumPenalizacionPotencia = 0
        let sumPenalizacionReactiva = 0

        numDaysNew = 0;
        let numDaysNewP = 0;
        let numDaysPotencia = 0;
        let numDaysP1 = 0;
        let numDaysP2 = 0;
        let numDaysP3 = 0;
        let numDaysP4 = 0;
        let numDaysP5 = 0;
        let numDaysP6 = 0;

        let numDaysPE1 = 0;
        let numDaysPE2 = 0;
        let numDaysPE3 = 0;
        let numDaysPE4 = 0;
        let numDaysPE5 = 0;
        let numDaysPE6 = 0;
        let sumatotalPotencia = 0;
        let sumatotalReactiva = 0;

        numMonthNew = 0;
          let monthLectura = 0;
let yearLectura = 0;
        let dayscheck= 0;
        let valorPhi=0;


        //// console.log('VALORS DE THIS.STATE dentro de calculatePrecioProducto')
        //// console.log(this.state);
        //// console.log('VALOR DE PRODUCTO')
        //// console.log(producto)


        this.state.itemsConsumo.map( row => {
        //   // console.log('DEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEENTRO')

                if (fechaDesdeConsumo == null){
                    fechaDesdeConsumo = row['fechaInicioMesConsumo'];
                     numDaysNew += Math.round(moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asDays());
                     numMonthNew += Math.round(moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asMonths());
                } else if ( moment(fechaDesdeConsumo, "DD-MM-YYYY") > moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY")){
                           fechaDesdeConsumo = row['fechaInicioMesConsumo'];
                           numDaysNew += Math.round(moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asDays());

                           numMonthNew += Math.round(moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asMonths());
                           }
                return row;
            });

            this.setState({
                daysOperacion:numDaysNew,
              })

    //// REALIZAMOS LA LOGICA PARA REPRESENTAR LOS DATOS DE Potencia


    let numberRowConsumo = Object.keys(this.state.itemsConsumo).length;

    if (this.state.cambioPotencia === 1){

    if(this.state.rates1XP.includes(this.state.sip.codigoTarifaATREnVigor) && producto.tarifaATR === "018"){
         // console.log('ENTRA EN IF ----> 1')
           potenciaContratoP1 += (Number(this.state.formDatosContrato.potenciaContratoP1) || 0);
           potenciaContratoP2 += (Number(this.state.formDatosContrato.potenciaContratoP2) || 0);
         } else if (this.state.rates1XP.includes(this.state.sip.codigoTarifaATREnVigor) && this.state.rates1XP.includes(producto.tarifaATR)){
                // console.log('ENTRA EN IF ----> 2')
                potenciaContratoP1 += (Number(this.state.formDatosContrato.potenciaContratoP1) || 0);
           } else if (this.state.rates2XP.includes(this.state.sip.codigoTarifaATREnVigor)){
                // console.log('ENTRA EN IF ----> 3')
            potenciaContratoP1 += (Number(this.state.formDatosContrato.potenciaContratoP1) || 0);
             potenciaContratoP2 += (Number(this.state.formDatosContrato.potenciaContratoP2) || 0);
           } else if (this.state.rates3XP.includes(this.state.sip.codigoTarifaATREnVigor) && (producto.tarifaATR === "019" || producto.tarifaATR === "020" )){
                // console.log('ENTRA EN IF ----> 4')
                potenciaContratoP1 += (Number(this.state.formDatosContrato.potenciaContratoP1) || 0);
                 potenciaContratoP2 += (Number(this.state.formDatosContrato.potenciaContratoP2) || 0);
                 potenciaContratoP3 += (Number(this.state.formDatosContrato.potenciaContratoP3) || 0);
                 potenciaContratoP4 += (Number(this.state.formDatosContrato.potenciaContratoP4) || 0);
                 potenciaContratoP5 += (Number(this.state.formDatosContrato.potenciaContratoP5) || 0);
                 potenciaContratoP6 += (Number(this.state.formDatosContrato.potenciaContratoP6) || 0);
               } else if (this.state.rates3XP.includes(this.state.sip.codigoTarifaATREnVigor) && this.state.rates3XP.includes(producto.tarifaATR)){
                    // console.log('ENTRA EN IF ----> 5')
                    potenciaContratoP1 += (Number(this.state.formDatosContrato.potenciaContratoP1) || 0);
                   potenciaContratoP2 += (Number(this.state.formDatosContrato.potenciaContratoP2) || 0);
                   potenciaContratoP3 += (Number(this.state.formDatosContrato.potenciaContratoP3) || 0);

                 } else if(rates6XPotencia.includes(this.state.sip.codigoTarifaATREnVigor) &&rates6XPotencia.includes(producto.tarifaATR) ){

                    // console.log('ENTRA EN IF ----> 6')
                        potenciaContratoP1 += (Number(this.state.formDatosContrato.potenciaContratoP1) || 0);
                       potenciaContratoP2 += (Number(this.state.formDatosContrato.potenciaContratoP2) || 0);
                       potenciaContratoP3 += (Number(this.state.formDatosContrato.potenciaContratoP3) || 0);
                       potenciaContratoP4 += (Number(this.state.formDatosContrato.potenciaContratoP4) || 0);
                       potenciaContratoP5 += (Number(this.state.formDatosContrato.potenciaContratoP5) || 0);
                       potenciaContratoP6 += (Number(this.state.formDatosContrato.potenciaContratoP6) || 0);
    }
                    else{
    // console.log('ENTRA EN IF ----> 7')
    // console.log('TARIFA EN VIGOR ',this.state.sip.codigoTarifaATREnVigor)
    // console.log('TARIFA PRODUCTO ', producto.tarifaATR )

                        }

    } else {

        // console.log('TARIFA EN VIGOR en 0 ',sip.codigoTarifaATREnVigor)
        // console.log('TARIFA PRODUCTO en 0 ', producto.tarifaATR )
        if(this.state.rates1XP.includes(sip.codigoTarifaATREnVigor) && producto.tarifaATR === "018"){
            // console.log('ENTRA EN IF ----> 1 a')
           potenciaContratoP1 = (Number(sip.potenciasContratadasEnWP1) || 0);
           potenciaContratoP2 = (Number(sip.potenciasContratadasEnWP1) || 0);
         } else if (this.state.rates1XP.includes(sip.codigoTarifaATREnVigor) && this.state.rates1XP.includes(producto.tarifaATR)){
             potenciaContratoP1 = (Number(sip.potenciasContratadasEnWP1) || 0);
            // console.log('ENTRA EN IF ----> 2 a')
           } else if (this.state.rates2XP.includes(sip.codigoTarifaATREnVigor)){
            // console.log('ENTRA EN IF ----> 3 a')
             potenciaContratoP1 = (Number(sip.potenciasContratadasEnWP1) || 0);
             potenciaContratoP2 = (Number(sip.potenciasContratadasEnWP2) || 0);
           } else if (this.state.rates3XP.includes(sip.codigoTarifaATREnVigor) && (producto.tarifaATR === "019" || producto.tarifaATR === "020" )){
                // console.log('ENTRA EN IF ----> 4 a')
                potenciaContratoP1 = (Number(sip.potenciasContratadasEnWP1) || 0);
                 potenciaContratoP2 = (Number(sip.potenciasContratadasEnWP2) || 0);
                 potenciaContratoP3 = (Number(sip.potenciasContratadasEnWP2) || 0);
                 potenciaContratoP4 = (Number(sip.potenciasContratadasEnWP2) || 0);
                 potenciaContratoP5 = (Number(sip.potenciasContratadasEnWP2) || 0);
                 potenciaContratoP6 = (Number(sip.potenciasContratadasEnWP3) || 0);
               } else if (this.state.rates3XP.includes(sip.codigoTarifaATREnVigor) && this.state.rates3XP.includes(producto.tarifaATR)){

                    // console.log('ENTRA EN IF ----> 5 a')
                    potenciaContratoP1 = (Number(sip.potenciasContratadasEnWP1) || 0);
                   potenciaContratoP2 = (Number(sip.potenciasContratadasEnWP2) || 0);
                   potenciaContratoP3 = (Number(sip.potenciasContratadasEnWP3) || 0);
                 } else {
                // console.log('ENTRA EN IF ----> 6 a')
                       potenciaContratoP1 = (Number(sip.potenciasContratadasEnWP1) || 0);
                       potenciaContratoP2 = (Number(sip.potenciasContratadasEnWP2) || 0);
                       potenciaContratoP3 = (Number(sip.potenciasContratadasEnWP3) || 0);
                       potenciaContratoP4 = (Number(sip.potenciasContratadasEnWP4) || 0);
                       potenciaContratoP5 = (Number(sip.potenciasContratadasEnWP5) || 0);
                       potenciaContratoP6 = (Number(sip.potenciasContratadasEnWP6) || 0);}


    }






   //// HACEMOS LOS CALCULOS DE TOTALES DE POTENCIA //

if (this.state.cambioPotencia === 1){

sumatotalPotencia = 0;

    this.state.itemsConsumo.map( row => {

        if(this.state.rates1XP.includes(sip.codigoTarifaATREnVigor) && producto.tarifaATR === "018"){
           potenciaP1 = (Number(this.state.formDatosContrato.potenciaContratoP1) || 0);
           potenciaP2 = (Number(this.state.formDatosContrato.potenciaContratoP2) || 0);
         } else if (this.state.rates1XP.includes(sip.codigoTarifaATREnVigor) && this.state.rates1XP.includes(producto.tarifaATR)){
             potenciaP1 = (Number(this.state.formDatosContrato.potenciaContratoP1) || 0);
           } else if (this.state.rates2XP.includes(sip.codigoTarifaATREnVigor)){
             potenciaP1 = (Number(this.state.formDatosContrato.potenciaContratoP1) || 0);
             potenciaP2 = (Number(this.state.formDatosContrato.potenciaContratoP2) || 0);
           } else if (this.state.rates3XP.includes(sip.codigoTarifaATREnVigor) && (producto.tarifaATR === "019" || producto.tarifaATR === "020" )){
                 potenciaP1 = (Number(this.state.formDatosContrato.potenciaContratoP1) || 0);
                 potenciaP2 = (Number(this.state.formDatosContrato.potenciaContratoP2) || 0);
                 potenciaP3 = (Number(this.state.formDatosContrato.potenciaContratoP3) || 0);
                 potenciaP4 = (Number(this.state.formDatosContrato.potenciaContratoP4) || 0);
                 potenciaP5 = (Number(this.state.formDatosContrato.potenciaContratoP5) || 0);
                 potenciaP6 = (Number(this.state.formDatosContrato.potenciaContratoP6) || 0);
               } else if (this.state.rates3XP.includes(sip.codigoTarifaATREnVigor) && this.state.rates3XP.includes(producto.tarifaATR)){
                   potenciaP1 = (Number(this.state.formDatosContrato.potenciaContratoP1) || 0);
                   potenciaP2 = (Number(this.state.formDatosContrato.potenciaContratoP2) || 0);
                   potenciaP3 = (Number(this.state.formDatosContrato.potenciaContratoP3) || 0);
                 } else {
                     potenciaP1 = (Number(this.state.formDatosContrato.potenciaContratoP1) || 0);
                     potenciaP2 = (Number(this.state.formDatosContrato.potenciaContratoP2) || 0);
                     potenciaP3 = (Number(this.state.formDatosContrato.potenciaContratoP3) || 0);
                     potenciaP4 = (Number(this.state.formDatosContrato.potenciaContratoP4) || 0);
                     potenciaP5 = (Number(this.state.formDatosContrato.potenciaContratoP5) || 0);
                     potenciaP6 = (Number(this.state.formDatosContrato.potenciaContratoP6) || 0);}

                     if(rates6XPotencia.includes(this.state.sip.codigoTarifaATREnVigor) && (sip.potenciasContratadasEnWP1 >= 15 && sip.potenciasContratadasEnWP6 <= 50 && sip.potenciasContratadasEnWP6 >= 15) ){


                             if((Number(row['potenciaDemandadaEnWP1']) || 0) > Number(this.state.formDatosContrato.potenciaContratoP1) ){
                                 penalizacionP1 = ((2*((Number(row['potenciaDemandadaEnWP1'])) - Number(this.state.formDatosContrato.potenciaContratoP1))*1.406400 ))
                             }

                             if((Number(row['potenciaDemandadaEnWP2']) || 0) >Number(this.state.formDatosContrato.potenciaContratoP2) ){
                                 penalizacionP2 = ((2*((Number(row['potenciaDemandadaEnWP2'])) - Number(this.state.formDatosContrato.potenciaContratoP2))*1.406400 ) )
                                 // console.log('VALORES DE penalizacionP2 ', penalizacionP2)

                             }

                             if((Number(row['potenciaDemandadaEnWP3']) || 0) > Number(this.state.formDatosContrato.potenciaContratoP3) ){
                                 penalizacionP3 = ((2*((Number(row['potenciaDemandadaEnWP3'])) - Number(this.state.formDatosContrato.potenciaContratoP3))*1.406400 ) )
                                 // console.log('VALORES DE penalizacionP3 ', penalizacionP3)

                             }

                             if((Number(row['potenciaDemandadaEnWP4']) || 0) > Number(this.state.formDatosContrato.potenciaContratoP4) ){
                                 penalizacionP4 = ((2*((Number(row['potenciaDemandadaEnWP4'])) - Number(this.state.formDatosContrato.potenciaContratoP4))*1.406400 ) )
                             }

                             if((Number(row['potenciaDemandadaEnWP5']) || 0) > Number(this.state.formDatosContrato.potenciaContratoP5) ){
                                 penalizacionP5 = ((2*((Number(row['potenciaDemandadaEnWP5'])) - Number(this.state.formDatosContrato.potenciaContratoP5))*1.406400 ) )
                             }

                             if((Number(row['potenciaDemandadaEnWP6']) || 0) > Number(this.state.formDatosContrato.potenciaContratoP6) ){
                                 penalizacionP6 = ((2*((Number(row['potenciaDemandadaEnWP6'])) - Number(this.state.formDatosContrato.potenciaContratoP6))*1.406400 ) )
                             }

                     }
                  sumatotalPotencia += penalizacionP1+penalizacionP2+penalizacionP3+penalizacionP4+penalizacionP5+penalizacionP6

    return row;
        });

} else {

                this.state.itemsConsumo.map( row => {
                     if(this.state.rates1XP.includes(sip.codigoTarifaATREnVigor) && producto.tarifaATR === "018"){
                        potenciaP1 += (Number(sip.potenciasContratadasEnWP1) || 0);
                        potenciaP2 += (Number(sip.potenciasContratadasEnWP1) || 0);
                      } else if (this.state.rates1XP.includes(sip.codigoTarifaATREnVigor) && this.state.rates1XP.includes(producto.tarifaATR)){
                          potenciaP1 += (Number(sip.potenciasContratadasEnWP1) || 0);
                        } else if (this.state.rates2XP.includes(sip.codigoTarifaATREnVigor)){
                          potenciaP1 += (Number(sip.potenciasContratadasEnWP1) || 0);
                          potenciaP2 += (Number(sip.potenciasContratadasEnWP2) || 0);
                        } else if (this.state.rates3XP.includes(sip.codigoTarifaATREnVigor) && (producto.tarifaATR === "019" || producto.tarifaATR === "020" )){
                              potenciaP1 += (Number(sip.potenciasContratadasEnWP1) || 0);
                              potenciaP2 += (Number(sip.potenciasContratadasEnWP2) || 0);
                              potenciaP3 += (Number(sip.potenciasContratadasEnWP2) || 0);
                              potenciaP4 += (Number(sip.potenciasContratadasEnWP2) || 0);
                              potenciaP5 += (Number(sip.potenciasContratadasEnWP2) || 0);
                              potenciaP6 += (Number(sip.potenciasContratadasEnWP3) || 0);
                            } else if (this.state.rates3XP.includes(sip.codigoTarifaATREnVigor) && this.state.rates3XP.includes(producto.tarifaATR)){
                                potenciaP1 += (Number(sip.potenciasContratadasEnWP1) || 0);
                                potenciaP2 += (Number(sip.potenciasContratadasEnWP2) || 0);
                                potenciaP3 += (Number(sip.potenciasContratadasEnWP3) || 0);
                              } else if(rates6XPotencia.includes(this.state.sip.codigoTarifaATREnVigor || sip.codigoTarifaATREnVigor === "003"||sip.codigoTarifaATREnVigor === "019") && rates6XPotencia.includes(producto.tarifaATR) ){

                                potenciaP1 += (Number(sip.potenciasContratadasEnWP1) || 0);
                                potenciaP2 += (Number(sip.potenciasContratadasEnWP2) || 0);
                                potenciaP3 += (Number(sip.potenciasContratadasEnWP2) || 0);
                                potenciaP4 += (Number(sip.potenciasContratadasEnWP2) || 0);
                                potenciaP5 += (Number(sip.potenciasContratadasEnWP2) || 0);
                                potenciaP6 += (Number(sip.potenciasContratadasEnWP3) || 0);
                 } else {
                                    /*potenciaP1 += (Number(row['potenciaDemandadaEnWP1']) || 0);
                                    potenciaP2 += (Number(row['potenciaDemandadaEnWP2']) || 0);
                                    potenciaP3 += (Number(row['potenciaDemandadaEnWP3']) || 0);
                                    potenciaP4 += (Number(row['potenciaDemandadaEnWP4']) || 0);
                                    potenciaP5 += (Number(row['potenciaDemandadaEnWP5']) || 0);
                                    potenciaP6 += (Number(row['potenciaDemandadaEnWP6']) || 0);*/

                        // console.log('ENTRA EN NO CONTROLADO EN POTENCIA ', this.state.sip.codigoTarifaATREnVigor, '   ', sip.codigoTarifaATREnVigor, '   ', producto.tarifaATR)
                        }



if(rates6XPotencia.includes(this.state.sip.codigoTarifaATREnVigor) && (sip.potenciasContratadasEnWP1 >= 15 && sip.potenciasContratadasEnWP6 <= 50 && sip.potenciasContratadasEnWP6 >= 15) ){

        if((Number(row['potenciaDemandadaEnWP1']) || 0) > (Number(sip.potenciasContratadasEnWP1) || 0)){
            penalizacionP1 = (((2*((Number(row['potenciaDemandadaEnWP1'])) - (Number(sip.potenciasContratadasEnWP1) || 0)))*1.406400 ))
                    }

        if((Number(row['potenciaDemandadaEnWP2']) || 0) >(Number(sip.potenciasContratadasEnWP2) || 0)){
            penalizacionP2 = (((2*((Number(row['potenciaDemandadaEnWP2'])) - (Number(sip.potenciasContratadasEnWP2) || 0)))*1.406400 ) )
                }

        if((Number(row['potenciaDemandadaEnWP3']) || 0) > (Number(sip.potenciasContratadasEnWP3) || 0)){
            penalizacionP3 = (((2*((Number(row['potenciaDemandadaEnWP3'])) - (Number(sip.potenciasContratadasEnWP3) || 0)))*1.406400 ) )
        }

        if((Number(row['potenciaDemandadaEnWP4']) || 0) > (Number(sip.potenciasContratadasEnWP4) || 0)){
            penalizacionP4 = (((2*((Number(row['potenciaDemandadaEnWP4'])) -(Number(sip.potenciasContratadasEnWP4) || 0)))*1.406400 ) )
        }

        if((Number(row['potenciaDemandadaEnWP5']) || 0) > (Number(sip.potenciasContratadasEnWP5) || 0)){
            penalizacionP5 = (((2*((Number(row['potenciaDemandadaEnWP5'])) -(Number(sip.potenciasContratadasEnWP5) || 0)))*1.406400 ) )
        }

        if((Number(row['potenciaDemandadaEnWP6']) || 0) > (Number(sip.potenciasContratadasEnWP6) || 0)){
            penalizacionP6 = (((2*((Number(row['potenciaDemandadaEnWP6'])) - (Number(sip.potenciasContratadasEnWP6) || 0)))*1.406400 ) )
        }

}
sumatotalPotencia += penalizacionP1+penalizacionP2+penalizacionP3+penalizacionP4+penalizacionP5+penalizacionP6
                     return row;
         });


}


                    this.state.itemsConsumo.map( row => {
                      if (this.state.rates1XE.includes(sip.codigoTarifaATREnVigor) && producto.tarifaATR === "018"){
                         energiaActivaP1  += this.roundNumber((Number(row['consumoEnergiaActivaEnWhP1']) || 0)*0.289);
                         energiaActivaP2  += this.roundNumber((Number(row['consumoEnergiaActivaEnWhP1']) || 0)*0.264);
                         energiaActivaP3  += this.roundNumber((Number(row['consumoEnergiaActivaEnWhP1']) || 0)*0.447);
                       }  else if (this.state.rates1XE.includes(sip.codigoTarifaATREnVigor) && this.state.rates1XE.includes(producto.tarifaATR)){
                         energiaActivaP1  += (Number(row['consumoEnergiaActivaEnWhP1']) || 0);
                       } else  if (this.state.rates2XE.includes(sip.codigoTarifaATREnVigor) && producto.tarifaATR === "018"){

                                   energiaActivaP1  += this.roundNumber(((Number(row['consumoEnergiaActivaEnWhP1']) || 0) + (Number(row['consumoEnergiaActivaEnWhP2']) || 0) +(Number(row['consumoEnergiaActivaEnWhP2']) || 0))*0.289);
                                   energiaActivaP2 += this.roundNumber(((Number(row['consumoEnergiaActivaEnWhP1']) || 0) + (Number(row['consumoEnergiaActivaEnWhP2']) || 0) +(Number(row['consumoEnergiaActivaEnWhP2']) || 0))*0.264);
                                   energiaActivaP3 += this.roundNumber(((Number(row['consumoEnergiaActivaEnWhP1']) || 0) + (Number(row['consumoEnergiaActivaEnWhP2']) || 0) +(Number(row['consumoEnergiaActivaEnWhP2']) || 0))*0.447);
                                 } else  if (this.state.rates2XE.includes(sip.codigoTarifaATREnVigor)  && this.state.rates2XE.includes(producto.tarifaATR)){
                                          energiaActivaP1  += (Number(row['consumoEnergiaActivaEnWhP1']) || 0);
                                          energiaActivaP2 += (Number(row['consumoEnergiaActivaEnWhP2']) || 0);
                                        }  else  if ((sip.codigoTarifaATREnVigor === "003"||sip.codigoTarifaATREnVigor === "019") && producto.tarifaATR === "019"){
                                                    var check = (moment(row['fechaFinMesConsumo'], "DD-MM-YYYY"));
                                                    dayscheck = (moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").daysInMonth());
                                                    monthLectura = Number(check.format('M'));
                                                    yearLectura = Number(check.format('Y'));
                                                    numDaysPE1 += dayscheck;
                                                    numDaysPE2 += dayscheck;
                                                    numDaysPE3 += dayscheck;
                                                    numDaysPE4 += dayscheck;
                                                    numDaysPE5 += dayscheck;
                                                    numDaysPE6 += dayscheck;

                                                  /*  ratesMonth1:[1,2,7,12],
                                                    ratesMonth2:[3,11],
                                                    ratesMonth3:[4,5,10],
                                                    ratesMonth4:[6,8,9],*/
                                                    if (monthLectura <6 && yearLectura <= 2021){
                                                    if (this.state.ratesMonth1.includes(monthLectura)){
                                                      energiaActivaP1  += (Number(row['consumoEnergiaActivaEnWhP1']) || 0);
                                                      energiaActivaP2 += (Number(row['consumoEnergiaActivaEnWhP2']) || 0);
                                                      energiaActivaP3 += 0;
                                                      energiaActivaP4  += 0 ;
                                                      energiaActivaP5 += 0;
                                                      energiaActivaP6 += (Number(row['consumoEnergiaActivaEnWhP3']) || 0) ;

                                                    } else if (this.state.ratesMonth2.includes(monthLectura)){
                                                      energiaActivaP1  += 0;
                                                      energiaActivaP2 +=  (Number(row['consumoEnergiaActivaEnWhP1']) || 0)
                                                      energiaActivaP3 += (Number(row['consumoEnergiaActivaEnWhP2']) || 0);
                                                      energiaActivaP4  += 0;
                                                      energiaActivaP5 += 0;
                                                      energiaActivaP6 += (Number(row['consumoEnergiaActivaEnWhP3']) || 0) ;

                                                          } else if (this.state.ratesMonth3.includes(monthLectura)){
                                                             energiaActivaP1  += 0;
                                                              energiaActivaP2 +=  0;
                                                              energiaActivaP3 += 0;
                                                              energiaActivaP4  += (Number(row['consumoEnergiaActivaEnWhP1']) || 0);
                                                              energiaActivaP5 += (Number(row['consumoEnergiaActivaEnWhP2']) || 0);
                                                              energiaActivaP6 += (Number(row['consumoEnergiaActivaEnWhP3']) || 0) ;

                                                          }else {
                                                             energiaActivaP1  += 0;
                                                             energiaActivaP2 +=  0;
                                                             energiaActivaP3 += (Number(row['consumoEnergiaActivaEnWhP1']) || 0);
                                                             energiaActivaP4  += (Number(row['consumoEnergiaActivaEnWhP2']) || 0);
                                                             energiaActivaP5 += 0
                                                             energiaActivaP6 += (Number(row['consumoEnergiaActivaEnWhP3']) || 0) ;

                                                                };

                                                            } else {
                                                                energiaActivaP1  += (Number(row['consumoEnergiaActivaEnWhP1']) || 0);
                                                                energiaActivaP2 += (Number(row['consumoEnergiaActivaEnWhP2']) || 0);
                                                                energiaActivaP3 += (Number(row['consumoEnergiaActivaEnWhP3']) || 0);
                                                                energiaActivaP4  += (Number(row['consumoEnergiaActivaEnWhP4']) || 0);
                                                                energiaActivaP5 += (Number(row['consumoEnergiaActivaEnWhP5']) || 0);
                                                                energiaActivaP6 += (Number(row['consumoEnergiaActivaEnWhP3']) || 0) ;


                                                                    }

                                                }  else  if (sip.codigoTarifaATREnVigor === "011" && producto.tarifaATR === "020"){
                                                    var check = (moment(row['fechaFinMesConsumo'], "DD-MM-YYYY"));
                                                    dayscheck = (moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").daysInMonth());
                                                    monthLectura = Number(check.format('M'));

                                                  /*  ratesMonth1:[1,2,7,12],
                                                    ratesMonth2:[3,11],
                                                    ratesMonth3:[4,5,10],
                                                    ratesMonth4:[6,8,9],*/

                                                    if (this.state.ratesMonth1.includes(monthLectura)){
                                                      energiaActivaP1  += (Number(row['consumoEnergiaActivaEnWhP1']) || 0);
                                                      energiaActivaP2 += (Number(row['consumoEnergiaActivaEnWhP2']) || 0);
                                                      energiaActivaP3 += 0;
                                                      energiaActivaP4  += 0 ;
                                                      energiaActivaP5 += 0;
                                                      energiaActivaP6 += (Number(row['consumoEnergiaActivaEnWhP3']) || 0) ;




                                                    } else if (this.state.ratesMonth2.includes(monthLectura)){
                                                      energiaActivaP1  += 0;
                                                      energiaActivaP2 +=  (Number(row['consumoEnergiaActivaEnWhP1']) || 0)
                                                      energiaActivaP3 += (Number(row['consumoEnergiaActivaEnWhP2']) || 0);
                                                      energiaActivaP4  += 0;
                                                      energiaActivaP5 += 0;
                                                      energiaActivaP6 += (Number(row['consumoEnergiaActivaEnWhP3']) || 0) ;


                                                          } else if (this.state.ratesMonth3.includes(monthLectura)){
                                                             energiaActivaP1  += 0;
                                                              energiaActivaP2 +=  0;
                                                              energiaActivaP3 += 0;
                                                              energiaActivaP4  += (Number(row['consumoEnergiaActivaEnWhP1']) || 0);
                                                              energiaActivaP5 += (Number(row['consumoEnergiaActivaEnWhP2']) || 0);
                                                              energiaActivaP6 += (Number(row['consumoEnergiaActivaEnWhP3']) || 0) ;

                                                          }else {
                                                             energiaActivaP1  += 0;
                                                             energiaActivaP2 +=  0;
                                                             energiaActivaP3 += (Number(row['consumoEnergiaActivaEnWhP1']) || 0);
                                                             energiaActivaP4  += (Number(row['consumoEnergiaActivaEnWhP2']) || 0);
                                                             energiaActivaP5 += 0
                                                             energiaActivaP6 += (Number(row['consumoEnergiaActivaEnWhP3']) || 0) ;

                                                            };

                                                        }  else  if (this.state.rates3XE.includes(sip.codigoTarifaATREnVigor) && this.state.rates3XE.includes(producto.tarifaATR) && producto.tarifaATR !== "019" && producto.tarifaATR !== "020" ){

                                   energiaActivaP1  += (Number(row['consumoEnergiaActivaEnWhP1']) || 0) + (Number(row['consumoEnergiaActivaEnWhP4']) || 0);
                                   energiaActivaP2 += (Number(row['consumoEnergiaActivaEnWhP2']) || 0) + (Number(row['consumoEnergiaActivaEnWhP5']) || 0);
                                   energiaActivaP3 += (Number(row['consumoEnergiaActivaEnWhP3']) || 0) + (Number(row['consumoEnergiaActivaEnWhP6']) || 0);

                                 }   else if (sip.codigoTarifaATREnVigor === '018'){
                                   energiaActivaP1  += (Number(row['consumoEnergiaActivaEnWhP1']) || 0);
                                    energiaActivaP2 += (Number(row['consumoEnergiaActivaEnWhP2']) || 0);
                                    energiaActivaP3 += (Number(row['consumoEnergiaActivaEnWhP3']) || 0);

                                 } else {
                                     var check = (moment(row['fechaFinMesConsumo'], "DD-MM-YYYY"));
                                     dayscheck = (moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").daysInMonth());
                                     monthLectura = Number(check.format('M'));


                                    energiaActivaP1  += (Number(row['consumoEnergiaActivaEnWhP1']) || 0);
                                    energiaActivaP2 += (Number(row['consumoEnergiaActivaEnWhP2']) || 0);
                                    energiaActivaP3 += (Number(row['consumoEnergiaActivaEnWhP3']) || 0);
                                    energiaActivaP4  += (Number(row['consumoEnergiaActivaEnWhP4']) || 0);
                                    energiaActivaP5 += (Number(row['consumoEnergiaActivaEnWhP5']) || 0);
                                    energiaActivaP6 += (Number(row['consumoEnergiaActivaEnWhP6']) || 0) ;


                                             };


                      return row;
                    });

                    this.state.itemsConsumo.map( row => {
                         if (this.state.rates3XE.includes(sip.codigoTarifaATREnVigor)){
                              energiaReactivaP1  += (Number(row['consumoEnergiaReactivaEnVArhP1']) || 0) + (Number(row['consumoEnergiaReactivaEnVArhP3']) || 0);
                              energiaReactivaP2 += (Number(row['consumoEnergiaReactivaEnVArhP2']) || 0) + (Number(row['consumoEnergiaReactivaEnVArhP4']) || 0);
                              //energiaReactivaP3 += (Number(row['consumoEnergiaReactivaEnWhP3']) || 0) + (Number(row['consumoEnergiaReactivaEnWhP4']) || 0);
                                    } else if(this.state.rates6XE.includes(this.state.sip.codigoTarifaATREnVigor) && (sip.potenciasContratadasEnWP1 >= 15 && sip.potenciasContratadasEnWP6 <= 50 && sip.potenciasContratadasEnWP6 >= 15) ){

                                        energiaReactivaP1  += (Number(row['consumoEnergiaReactivaEnVArhP1']) || 0);
                                        energiaReactivaP2 += (Number(row['consumoEnergiaReactivaEnVArhP2']) || 0);
                                        energiaReactivaP3 += (Number(row['consumoEnergiaReactivaEnVArhP3']) || 0);
                                        energiaReactivaP4 += (Number(row['consumoEnergiaReactivaEnVArhP4']) || 0);
                                        energiaReactivaP5 += (Number(row['consumoEnergiaReactivaEnVArhP5']) || 0);
                                        energiaReactivaP6 += (Number(row['consumoEnergiaReactivaEnVArhP6']) || 0);

                                        energiaReactivaExcesoP1 = ((Number(row['consumoEnergiaReactivaEnVArhP1']) || 0)- ((Number(row['consumoEnergiaActivaEnWhP1'])||0)*0,33))
                                        energiaReactivaExcesoP2 = ((Number(row['consumoEnergiaReactivaEnVArhP2']) || 0)- ((Number(row['consumoEnergiaActivaEnWhP2'])||0)*0,33))
                                        energiaReactivaExcesoP3 = ((Number(row['consumoEnergiaReactivaEnVArhP3']) || 0)- ((Number(row['consumoEnergiaActivaEnWhP3'])||0)*0,33))
                                        energiaReactivaExcesoP4 = ((Number(row['consumoEnergiaReactivaEnVArhP4']) || 0)- ((Number(row['consumoEnergiaActivaEnWhP4'])||0)*0,33))
                                        energiaReactivaExcesoP5 = ((Number(row['consumoEnergiaReactivaEnVArhP5']) || 0)- ((Number(row['consumoEnergiaActivaEnWhP6'])||0)*0,33))
                                        energiaReactivaExcesoP6 = ((Number(row['consumoEnergiaReactivaEnVArhP6']) || 0)- ((Number(row['consumoEnergiaActivaEnWhP6'])||0)*0,33))


                                        //let sumPenalizacionReactiva = 0

                                    if(energiaReactivaExcesoP1>0){
                                            valorPhi = (Number(row['consumoEnergiaActivaEnWhP1'])) / Math.sqrt(((Number(row['consumoEnergiaActivaEnWhP1']))**2)+(((Number(row['consumoEnergiaReactivaEnVArhP1']))**2)))

                                            if(valorPhi >= 0.80 && valorPhi <= 0.95){

                                                penalizacionReactivaP1 = energiaReactivaExcesoP1 *  0.041554
                                                } else if(valorPhi < 0.80){
                                                penalizacionReactivaP1 = energiaReactivaExcesoP1 *  0.062332
                                                } else {
                                                penalizacionReactivaP1 = 0
                                                }
                                            }

                                            if(energiaReactivaExcesoP2>0){
                                                valorPhi = (Number(row['consumoEnergiaActivaEnWhP2'])) / Math.sqrt(((Number(row['consumoEnergiaActivaEnWhP2']))**2)+(((Number(row['consumoEnergiaReactivaEnVArhP2']))**2)))

                                                if(valorPhi >= 0.80 && valorPhi <= 0.95){

                                                    penalizacionReactivaP2 = energiaReactivaExcesoP2 *  0.041554
                                                    } else if(valorPhi < 0.80){
                                                    penalizacionReactivaP2 = energiaReactivaExcesoP2 *  0.062332
                                                    } else {
                                                    penalizacionReactivaP2 = 0
                                                    }
                                                }

                                                if(energiaReactivaExcesoP3>0){
                                                    valorPhi = (Number(row['consumoEnergiaActivaEnWhP3'])) / Math.sqrt(((Number(row['consumoEnergiaActivaEnWhP3']))**2)+(((Number(row['consumoEnergiaReactivaEnVArhP3']))**2)))

                                                    if(valorPhi >= 0.80 && valorPhi <= 0.95){

                                                        penalizacionReactivaP3 = energiaReactivaExcesoP3 *  0.041554
                                                        } else if(valorPhi < 0.80){
                                                        penalizacionReactivaP3 = energiaReactivaExcesoP3 *  0.062332
                                                        } else {
                                                        penalizacionReactivaP3 = 0
                                                        }
                                                    }
                                                    if(energiaReactivaExcesoP4>0){
                                                        valorPhi = (Number(row['consumoEnergiaActivaEnWhP4'])) / Math.sqrt(((Number(row['consumoEnergiaActivaEnWhP4']))**2)+(((Number(row['consumoEnergiaReactivaEnVArhP4']))**2)))

                                                        if(valorPhi >= 0.80 && valorPhi <= 0.95){

                                                            penalizacionReactivaP4 = energiaReactivaExcesoP4 *  0.041554
                                                            } else if(valorPhi < 0.80){
                                                            penalizacionReactivaP4 = energiaReactivaExcesoP4 *  0.062332
                                                            } else {
                                                            penalizacionReactivaP4 = 0
                                                            }
                                                        }
                                                        if(energiaReactivaExcesoP5>0){
                                                            valorPhi = (Number(row['consumoEnergiaActivaEnWhP5'])) / Math.sqrt(((Number(row['consumoEnergiaActivaEnWhP5']))**2)+(((Number(row['consumoEnergiaReactivaEnVArhP5']))**2)))

                                                            if(valorPhi >= 0.80 && valorPhi <= 0.95){

                                                                penalizacionReactivaP5 = energiaReactivaExcesoP5 *  0.041554
                                                                } else if(valorPhi < 0.80){
                                                                penalizacionReactivaP5 = energiaReactivaExcesoP5 *  0.062332
                                                                } else {
                                                                penalizacionReactivaP5 = 0
                                                                }
                                                            }
                                                            if(energiaReactivaExcesoP6>0){
                                                                valorPhi = (Number(row['consumoEnergiaActivaEnWhP6'])) / Math.sqrt(((Number(row['consumoEnergiaActivaEnWhP6']))**2)+(((Number(row['consumoEnergiaReactivaEnVArhP6']))**2)))

                                                                if(valorPhi >= 0.80 && valorPhi <= 0.95){

                                                                    penalizacionReactivaP6 = energiaReactivaExcesoP6 *  0.041554
                                                                    } else if(valorPhi < 0.80){
                                                                    penalizacionReactivaP6 = energiaReactivaExcesoP6 *  0.062332
                                                                    } else {
                                                                    penalizacionReactivaP6 = 0
                                                                    }
                                                                }


                                    }  else {
                                   energiaReactivaP1  += (Number(row['consumoEnergiaReactivaEnVArhP1']) || 0);
                                   energiaReactivaP2 += (Number(row['consumoEnergiaReactivaEnVArhP2']) || 0);
                                   energiaReactivaP3 += (Number(row['consumoEnergiaReactivaEnVArhP3']) || 0);
                                   energiaReactivaP4 += (Number(row['consumoEnergiaReactivaEnVArhP4']) || 0);
                                   energiaReactivaP5 += (Number(row['consumoEnergiaReactivaEnVArhP5']) || 0);
                                   energiaReactivaP6 += (Number(row['consumoEnergiaReactivaEnVArhP6']) || 0);}

                sumatotalReactiva += penalizacionReactivaP1+penalizacionReactivaP2+penalizacionReactivaP3+penalizacionReactivaP4+penalizacionReactivaP5+penalizacionReactivaP6
                    return row;
                    });





         this.state.itemsConsumo.map( row => {
                if (fechaHastaConsumo == null){
                    fechaHastaConsumo = row['fechaFinMesConsumo'];
                } else if ( moment(fechaHastaConsumo, "DD-MM-YYYY") < moment(row['fechaFinMesConsumo'], "DD-MM-YYYY")){
                           fechaDesdeConsumo = row['fechaFinMesConsumo'];
                           }

                return row;
            });


        periodos.map( periodo => {

            precioEnergia[`P${periodo}`] = 0;
            precioPotencia[`P${periodo}`] = 0;
            consumoPotencia[`P${periodo}`] = 0;
            consumoEnergia[`P${periodo}`] = 0;
            //potencia[`P${periodo}`] = 0;
            //numDays = 0;

            Object.keys(producto).map( key => {
                //Producto Energia
                if(key === `precioTEP${periodo}`){
                    if (producto['coIncluido'] === false) {
                        precioEnergia[`P${periodo}`] = Number(producto[key]) + costeOperativo;
                    } else {
                        precioEnergia[`P${periodo}`] = Number(producto[key]);
                    }

                    if(periodo == 1){

                            if (producto['coIncluido'] === false) {
                                precioEnergiaP1 = Number(producto[key])+costeOperativo;
                            } else {
                                precioEnergiaP1 = Number(producto[key]);
                            }

                        } else if (periodo == 2){
                            if (producto['coIncluido'] === false) {
                                precioEnergiaP2 = Number(producto[key])+costeOperativo;
                            } else {
                                precioEnergiaP2 = Number(producto[key]);
                            }
                            } else if (periodo == 3){
                                if (producto['coIncluido'] === false) {
                                    precioEnergiaP3 = Number(producto[key])+costeOperativo;
                                } else {
                                    precioEnergiaP3 = Number(producto[key]);
                                }
                                } else if (periodo ==4 ){
                                    if(Number(producto[key])>0){
                                        if (producto['coIncluido'] === false) {
                                            precioEnergiaP4 = Number(producto[key])+costeOperativo;
                                        } else {
                                            precioEnergiaP4 = Number(producto[key]);
                                        }
                                    } else {
                                        precioEnergiaP4 = Number(producto[key]);
                                        }
                                } else if (periodo ==5 ){
                                    if(Number(producto[key])>0){
                                        if (producto['coIncluido'] === false) {
                                            precioEnergiaP5 = Number(producto[key])+costeOperativo;
                                        } else {
                                            precioEnergiaP5 = Number(producto[key]);
                                        }
                                    } else {
                                        precioEnergiaP5 = Number(producto[key]);
                                        }
                                } else if (periodo ==6 ){
                                    if(Number(producto[key])>0){
                                        if (producto['coIncluido'] === false) {
                                            precioEnergiaP6 = Number(producto[key])+costeOperativo;
                                        } else {
                                            precioEnergiaP6 = Number(producto[key]);
                                        }
                                    } else {
                                        precioEnergiaP6 = Number(producto[key]);
                                        }
                                }

                                if(periodo == 1){

                                        if (producto['coIncluido'] === false) {
                                            precioPotenciaP1 = Number(producto[key])+costeOperativo;
                                        } else {
                                            precioPotenciaP1 = Number(producto[key]);
                                        }

                                    } else if (periodo == 2){
                                        if (producto['coIncluido'] === false) {
                                            precioPotenciaP2 = Number(producto[key])+costeOperativo;
                                        } else {
                                            precioPotenciaP2 = Number(producto[key]);
                                        }
                                        } else if (periodo == 3){
                                            if (producto['coIncluido'] === false) {
                                                precioPotenciaP3 = Number(producto[key])+costeOperativo;
                                            } else {
                                                precioPotenciaP3 = Number(producto[key]);
                                            }
                                            } else if (periodo ==4 ){
                                                if(Number(producto[key])>0){
                                                    if (producto['coIncluido'] === false) {
                                                        precioPotenciaP4 = Number(producto[key])+costeOperativo;
                                                    } else {
                                                        precioPotenciaP4 = Number(producto[key]);
                                                    }
                                                } else {
                                                    precioPotenciaP4 = Number(producto[key]);
                                                    }
                                            } else if (periodo ==5 ){
                                                if(Number(producto[key])>0){
                                                    if (producto['coIncluido'] === false) {
                                                        precioPotenciaP5 = Number(producto[key])+costeOperativo;
                                                    } else {
                                                        precioPotenciaP5 = Number(producto[key]);
                                                    }
                                                } else {
                                                    precioPotenciaP5 = Number(producto[key]);
                                                    }
                                            } else if (periodo ==6 ){
                                                if(Number(producto[key])>0){
                                                    if (producto['coIncluido'] === false) {
                                                        precioPotenciaP6 = Number(producto[key])+costeOperativo;
                                                    } else {
                                                        precioPotenciaP6 = Number(producto[key]);
                                                    }
                                                } else {
                                                    precioPotenciaP6 = Number(producto[key]);
                                                    }
                                            }




                }
                //Producto Potencia
                if(key === `precioTP${periodo}`){
                    if (producto['coIncluido'] === false) {
                        precioPotencia[`P${periodo}`] = Number(producto[key]) + costeOperativoPotencia;
                    } else {
                        precioPotencia[`P${periodo}`] = Number(producto[key]);
                        }



}
                return key;
            });


            this.state.itemsConsumo.map( row => {

                consumoPotencia[`P${periodo}`] += Number(row[`potenciaDemandadaEnWP${periodo}`]) || 0;
                consumoEnergia[`P${periodo}`] += (Number(row[`consumoEnergiaActivaEnWhP${periodo}`]) || 0);

                return row;

            });


            //datasetProducto.push(this.roundNumber((consumoEnergia[`P${periodo}`] * precioEnergia[`P${periodo}`])));

            sumEnergia += (consumoEnergia[`P${periodo}`] * precioEnergia[`P${periodo}`]);


            if(  producto.tarifaATR === '018' || producto.tarifaATR === '019' || producto.tarifaATR ==='020'){

            //// console.log('ENTRA EN POTENCIA Producto para suma de potencia')

              if (this.state.rates2XP.includes(sip.codigoTarifaATREnVigor) ||this.state.rates1XP.includes(sip.codigoTarifaATREnVigor)  ){
                      if (periodo === 1){
                    //const potenciaContratada = sip[`potenciasContratadasEnWP${periodo}`];(periodo)
                    const potenciaContratada = potenciaContratoP1;
                    sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;}

                    if (periodo === 2){
                  //const potenciaContratada = sip[`potenciasContratadasEnWP${periodo}`];(periodo)
                  const potenciaContratada = potenciaContratoP2;
                  sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;}

                  if (periodo === 3){
                //const potenciaContratada = sip[`potenciasContratadasEnWP${periodo}`];(periodo)
                const potenciaContratada = potenciaContratoP3;
                sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;}

              }else if (sip.codigoTarifaATREnVigor === '003'){

                if (periodo === 1){
              //const potenciaContratada = sip[`potenciasContratadasEnWP${periodo}`];(periodo)
              const potenciaContratada = potenciaContratoP1;
              sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;}

                if (periodo === 2){
              //const potenciaContratada = sip[`potenciasContratadasEnWP${periodo}`];(periodo)
              const potenciaContratada = potenciaContratoP2;
              sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;}

              if (periodo === 3){
                //const potenciaContratada = sip[`potenciasContratadasEnWP${periodo}`];(periodo)
              const potenciaContratada = potenciaContratoP3;
              sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;}
              if (periodo === 4){
              //const potenciaContratada = sip[`potenciasContratadasEnWP${periodo}`];(periodo)
              const potenciaContratada = potenciaContratoP4;
              sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;}
              if (periodo === 5){
              //const potenciaContratada = sip[`potenciasContratadasEnWP${periodo}`];(periodo)
              const potenciaContratada = potenciaContratoP5;
              sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;}
              if (periodo === 6){
            //const potenciaContratada = sip[`potenciasContratadasEnWP${periodo}`];(periodo)
              const potenciaContratada = potenciaContratoP6;
              sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;}

              }
            }

            if( producto.tarifaATR === '019'|| producto.tarifaATR === '021' || producto.tarifaATR === '022' || producto.tarifaATR === '023' || producto.tarifaATR === '024' || producto.tarifaATR === '025'){

                  //sumPotencia += (consumoPotencia[`P${periodo}`] * precioPotencia[`P${periodo}`])* numDaysP[`${periodo}`];

                console.log('ENTRA EN POTENCIA P6 2')

                  if (periodo === 1){
                  //const potenciaContratada = sip[`potenciasContratadasEnWP${periodo}`];(periodo)
                  const potenciaContratada = potenciaContratoP1;
                  //// console.log('VALOR DE POTENCIA CONTRATADA -----> ', potenciaContratada)
                  sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;}

                if (periodo === 2){
                  //const potenciaContratada = sip[`potenciasContratadasEnWP${periodo}`];(periodo)
                  const potenciaContratada = potenciaContratoP2;
                  sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;}

                  if (periodo === 3){
                    //const potenciaContratada = sip[`potenciasContratadasEnWP${periodo}`];(periodo)
                  const potenciaContratada = potenciaContratoP3;
                  sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;}
                  if (periodo === 4){
                  //const potenciaContratada = sip[`potenciasContratadasEnWP${periodo}`];(periodo)
                  const potenciaContratada = potenciaContratoP4;
                  sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;}
                  if (periodo === 5){
                  //const potenciaContratada = sip[`potenciasContratadasEnWP${periodo}`];(periodo)
                  const potenciaContratada = potenciaContratoP5;
                  sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;}
                  if (periodo === 6){
                //const potenciaContratada = sip[`potenciasContratadasEnWP${periodo}`];(periodo)
                  const potenciaContratada = potenciaContratoP6;
                  sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;}

            }



            return periodo;
        });





        serviciosAdicionalesMonth = 0;
        penalizacionPotencia = this.roundNumber(Number(sumatotalPotencia||0));
        //sumPenalizacionPotencia = this.roundNumber(penalizacionP1 + penalizacionP2 +penalizacionP3 +penalizacionP4 +penalizacionP5+penalizacionP6);
        penalizacionEnergia = this.roundNumber(Number(sumatotalReactiva||0));

        sumEnergia =  this.roundNumber(sumEnergia);
        sumPotencia =  this.roundNumber(sumPotencia);

        iePercent = this.roundNumber((sumEnergia + sumPotencia) * 0.0511);
        impuestoElectrico = this.roundNumber(( sumEnergia + sumPotencia ));
        serviciosAdicionales = this.roundNumber(this.state.precioServicio||0*numMonthNew);
        alquilerEquipoMedida = this.roundNumber(alquilerEquipoMedidaMonth||0*numMonthNew);
        bi = this.roundNumber((penalizacionPotencia + penalizacionEnergia + serviciosAdicionales + alquilerEquipoMedida + impuestoElectrico + iePercent));

        var isbefore = moment().isBefore('20220101')


      if(isbefore && (parseInt(this.state.sip.potenciasContratadasEnWP1) < 10 && parseInt(this.state.sip.potenciasContratadasEnWP2) < 10 && parseInt(this.state.sip.potenciasContratadasEnWP3) < 10 &&  parseInt(this.state.sip.potenciasContratadasEnWP4) < 10 &&  parseInt(this.state.sip.potenciasContratadasEnWP5) < 10 &&  parseInt(this.state.sip.potenciasContratadasEnWP6) < 10)){
      //     // console.log('ENTRA EN IVA 10 - 1')
        //  // console.log('VALOR DE THIS STATE EN COMERCIALPRODUCTO ____________--------> ',this.state)
iva = this.roundNumber((bi * 0.10));
ivaPorcentaje = 10;

        } else if (isbefore && (parseInt(this.state.sip.potenciasContratadasEnWP1) < 15 && parseInt(this.state.sip.potenciasContratadasEnWP2) < 15 && parseInt(this.state.sip.potenciasContratadasEnWP3) < 15 &&  parseInt(this.state.sip.potenciasContratadasEnWP4) < 15 &&  parseInt(this.state.sip.potenciasContratadasEnWP5) < 15 &&  parseInt(this.state.sip.potenciasContratadasEnWP6) < 15) && sip.perfilPersona == 'J'){
  //        // console.log('ENTRA EN ELSE EN COMERCIAL PRODUCTO')
    //       // console.log('ENTRA EN IVA 10 - 2')
          iva = this.roundNumber((bi * 0.10));
        ivaPorcentaje = 10;
        } else if (isbefore && this.state.sip.aplicacionBonoSocial == "1"){
 //// console.log('ENTRA EN IVA 10 - 3')
          iva = this.roundNumber((bi * 0.10));
          ivaPorcentaje = 10;
        } else  {
 //// console.log('ENTRA EN IVA 21 - 1')
          iva = this.roundNumber((bi * 0.21));
          ivaPorcentaje = 21;
        }

        if(producto.coIncluido){
            if(this.state.esNuevoContrato){
            comisionContratoFija = producto.comisionContratoNuevo
            } else {
                comisionContratoFija = producto.comisionContratoRenovacion
            };
            comisionContratoConsumoAgente1 = parseFloat(sip.ConsumoAnualTotalActiva) * producto.comisionConsumoAgente1
            comisionContratoConsumoAgente2 = this.roundNumber(parseFloat(sip.ConsumoAnualTotalActiva) * producto.comisionConsumoAgente2)
            comisionContratoConsumoAdmin = parseFloat(sip.ConsumoAnualTotalActiva) * producto.comisionConsumoAdmin


        } else if (!producto.coIncluido){

            if(this.state.esNuevoContrato){
            comisionContratoFija = producto.comisionContratoNuevo
            } else {
            comisionContratoFija = producto.comisionContratoRenovacion
            };

            comisionContratoConsumoAgente1 = (this.state.costeOperativo * parseFloat(sip.ConsumoAnualTotalActiva)) * (producto.comisionGOAgente1/100)
            comisionContratoConsumoAgente2 = 0
            comisionContratoConsumoAdmin = comisionContratoConsumoAgente1*(producto.comisionGOAgente2/100)

        }




        total = this.roundNumber((bi + iva));
        codigoATR = this.state.sip.codigoTarifaATREnVigor;
        idServicio = this.state.idservicio;
        idComercializadora = producto.idComercializadora;
        nombreComercializadora = producto.nombreComercializadora;
        nombreServicio =this.state.nombreServicio;

        precioServicio = this.roundNumber(this.state.precioServicio);
        idProducto = producto.id;
        nombreProducto = producto.nombreOferta


    this.setState({penalizacionPotenciaCalculo:penalizacionPotencia,
                    penalizacionReactivaCalculo: penalizacionEnergia})


        return {
            serviciosAdicionalesMonth: serviciosAdicionalesMonth,
            serviciosAdicionales: serviciosAdicionales,
            alquilerEquipoMedidaMonth: alquilerEquipoMedidaMonth,
            alquilerEquipoMedida: alquilerEquipoMedida,
            codigoATR: codigoATR,
            sumPotencia: sumPotencia,
            sumEnergia: sumEnergia,
            iePercent: iePercent,
            impuestoElectrico: impuestoElectrico,
            iva: iva,
            ivaPorcentaje: ivaPorcentaje,
            bi: bi,
            total: total,
            penalizacionPotencia: penalizacionPotencia,
            penalizacionEnergia: penalizacionEnergia,
        numDaysNew: numDaysNew,
        numMonthNew: numMonthNew,
        fechaDesdeConsumo: fechaDesdeConsumo,
        fechaHastaConsumo: fechaHastaConsumo,
        EnergiaActiva: ["0"],
        Potencia: ["0"],
        potenciaP1: potenciaP1,
        potenciaP2: potenciaP2,
        potenciaP3: potenciaP3,
        potenciaP4: potenciaP4,
        potenciaP5: potenciaP5,
        potenciaP6: potenciaP6,
        potenciaContratoP1: potenciaContratoP1,
        potenciaContratoP2: potenciaContratoP2,
        potenciaContratoP3: potenciaContratoP3,
        potenciaContratoP4: potenciaContratoP4,
        potenciaContratoP5: potenciaContratoP5,
        potenciaContratoP6: potenciaContratoP6,
        energiaActivaP1 : energiaActivaP1,
        energiaActivaP2 : energiaActivaP2,
        energiaActivaP3  : energiaActivaP3,
        energiaActivaP4  : energiaActivaP4,
        energiaActivaP5  : energiaActivaP5,
        energiaActivaP6  : energiaActivaP6,

        energiaReactivaP1 : energiaReactivaP1,
        energiaReactivaP2  : energiaReactivaP2,
        energiaReactivaP3  : energiaReactivaP3,
        energiaReactivaP4  : energiaReactivaP4,
        energiaReactivaP5  : energiaReactivaP5,
        energiaReactivaP6  : energiaReactivaP6,

        precioPotenciaP1 : precioPotenciaP1,
        precioPotenciaP2 : precioPotenciaP2,
        precioPotenciaP3 : precioPotenciaP3,
        precioPotenciaP4 : precioPotenciaP4,
        precioPotenciaP5 : precioPotenciaP5,
        precioPotenciaP6 : precioPotenciaP6,

        precioEnergiaP1 : precioEnergiaP1,
        precioEnergiaP2 :  precioEnergiaP2,
        precioEnergiaP3 : precioEnergiaP3,
        precioEnergiaP4 : precioEnergiaP4,
        precioEnergiaP5 : precioEnergiaP5,
         precioEnergiaP6 : precioEnergiaP6,
        idServicio : idServicio,
        idComercializadora: idComercializadora,
        //nombreComercializadora: nombreComercializadora,
        nombreServicio:         nombreServicio,
        precioServicio: precioServicio,
        id: idProducto,
        nombreProducto: nombreProducto,
        nombreComercializadora: nombreComercializadora,
        comisionContratoFija:this.roundNumber(comisionContratoFija),
        comisionContratoConsumoAgente1:this.roundNumber(comisionContratoConsumoAgente1),
        comisionContratoConsumoAgente2:comisionContratoConsumoAgente2,
        comisionContratoConsumoAdmin:this.roundNumber(comisionContratoConsumoAdmin)


        }

    }


    private calculatePrecioActual = ( periodos: number[], factura: any ) => {

        let {
            serviciosAdicionalesMonth,
            serviciosAdicionales,
            alquilerEquipoMedidaMonth,
            alquilerEquipoMedida,
            codigoATR,
            sumPotencia,
            sumEnergia,
            iePercent,
            impuestoElectrico,
            penalizacionPotencia,
            penalizacionEnergia,
            iva,
            ivaPorcentaje,
            bi,
            total,
            numDaysNew,
        numMonthNew,
        fechaDesdeConsumo,
        fechaHastaConsumo,
        descuento,
        descuentoMonth,
        descuentoPotencia,
        descuentoPotenciaMonth

        } = factura;

        const sip = this.state.sip;
        let consumoPotencia = {};
        let consumoEnergia = {};
        let precioPotencia = {};
        let precioEnergia = {};
        //let numDays;
        fechaDesdeConsumo = null;
        fechaHastaConsumo = null;


        sumEnergia = 0;
        sumPotencia = 0;


        numDaysNew = 0;
        numMonthNew = 0;



        this.state.itemsConsumo.map( row => {
                if (fechaDesdeConsumo == null){
                    fechaDesdeConsumo = row['fechaInicioMesConsumo'];
                     numDaysNew += Math.round(moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asDays());
                     numMonthNew += Math.round(moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asMonths());
                } else if ( moment(fechaDesdeConsumo, "DD-MM-YYYY") > moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY")){
                           fechaDesdeConsumo = row['fechaInicioMesConsumo'];
                           numDaysNew += Math.round(moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asDays());

                           numMonthNew += Math.round(moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asMonths());
                           }
                return row;
            });










         this.state.itemsConsumo.map( row => {
                if (fechaHastaConsumo == null){
                    fechaHastaConsumo = row['fechaFinMesConsumo'];
                } else if ( moment(fechaHastaConsumo, "DD-MM-YYYY") < moment(row['fechaFinMesConsumo'], "DD-MM-YYYY")){
                           fechaDesdeConsumo = row['fechaFinMesConsumo'];
                           }

                return row;
            });


        periodos.map( periodo => {

            precioEnergia[`P${periodo}`] = 0;
            precioPotencia[`P${periodo}`] = 0;
            consumoPotencia[`P${periodo}`] = 0;
            consumoEnergia[`P${periodo}`] = 0;
            //numDays = 0;








            this.state.itemsConsumo.map( row => {
                consumoPotencia[`P${periodo}`] += Number(row[`potenciaDemandadaEnWP${periodo}`]) || 0;
                //consumoEnergia[`P${periodo}`] += (Number(row[`consumoEnergiaActivaEnWhP${periodo}`]) || 0) + (Number(row[`consumoEnergiaReactivaEnVArhP${periodo}`]) || 0);
                consumoEnergia[`P${periodo}`] += (Number(row[`consumoEnergiaActivaEnWhP${periodo}`]) || 0)
                //numDays += moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asDays();



                return row;

            });

            sumEnergia +=  (consumoEnergia[`P${periodo}`] * this.state.formActual[`precioTEP${periodo}`]);

            if (consumoPotencia[`P${periodo}`] === 0  && this.state.rates2X.includes(sip.codigoTarifaATREnVigor)) {

                const potenciaContratada = sip[`potenciasContratadasEnWP${periodo}`];
                sumPotencia += (potenciaContratada * this.state.formActual[`precioTP${periodo}`])*numDaysNew;
                //sumPotencia += (potenciaContratada * this.state.formActual[`precioTP${periodo}`]);

            }   else if (consumoPotencia[`P${periodo}`] === 0  && sip.codigoTarifaATREnVigor === '018') {
              const potenciaContratada = sip[`potenciasContratadasEnWP${periodo}`];
              sumPotencia += (potenciaContratada * this.state.formActual[`precioTP${periodo}`])*numDaysNew;
              //sumPotencia += (potenciaContratada * this.state.formActual[`precioTP${periodo}`]);

            } else if (sip.codigoTarifaATREnVigor === '019' || sip.codigoTarifaATREnVigor === '020' || sip.codigoTarifaATREnVigor === '021' || sip.codigoTarifaATREnVigor === '022' || sip.codigoTarifaATREnVigor === '023' || sip.codigoTarifaATREnVigor === '024' || sip.codigoTarifaATREnVigor === '025'){
                 const potenciaContratada = sip[`potenciasContratadasEnWP${periodo}`];
                sumPotencia += (potenciaContratada * this.state.formActual[`precioTP${periodo}`])* numDaysNew;
            } else {
                if (this.state.rates3X.includes(sip.codigoTarifaATREnVigor)){
                        if(sip.codigoTarifaATREnVigor === '003'  || sip.codigoTarifaATREnVigor === '018') {
                            const potenciaContratada = sip[`potenciasContratadasEnWP${periodo}`];
                            sumPotencia += (potenciaContratada * this.state.formActual[`precioTP${periodo}`]) * numDaysNew;

                            }else {
                            sumPotencia += (consumoPotencia[`P${periodo}`] * this.state.formActual[`precioTP${periodo}`])* numDaysNew;
              }

          }}

            return periodo;
        });
        penalizacionPotencia = this.roundNumber(Number(penalizacionPotencia));
        penalizacionEnergia = this.roundNumber(Number(penalizacionEnergia));
        descuento = this.roundNumber(this.percentage(sumEnergia, descuentoMonth)*numMonthNew);
        descuentoPotencia = this.roundNumber(this.percentage(sumPotencia, descuentoPotenciaMonth)*numMonthNew);
        sumEnergia =  this.roundNumber(this.roundNumber(sumEnergia)-descuento);
        sumPotencia =  this.roundNumber(sumPotencia);
        serviciosAdicionalesMonth = 0;

        iePercent = this.roundNumber((sumEnergia + sumPotencia) * 0.0511);
        impuestoElectrico = this.roundNumber(( sumEnergia + sumPotencia ));
        serviciosAdicionales = this.roundNumber(serviciosAdicionalesMonth*numMonthNew);
        alquilerEquipoMedida = this.roundNumber(alquilerEquipoMedidaMonth*numMonthNew);
        bi = this.roundNumber((penalizacionPotencia + penalizacionEnergia + serviciosAdicionales + alquilerEquipoMedida + impuestoElectrico + iePercent));
        iva = this.roundNumber((bi * this.state.formActual.ivaPorcentaje));
        ivaPorcentaje = this.state.formActual.ivaPorcentaje;
        total = this.roundNumber((bi + iva));
        codigoATR = this.state.sip.codigoTarifaATREnVigor;

        return {
            serviciosAdicionalesMonth: serviciosAdicionalesMonth,
            serviciosAdicionales: serviciosAdicionales,
            alquilerEquipoMedidaMonth: alquilerEquipoMedidaMonth,
            alquilerEquipoMedida: alquilerEquipoMedida,
            codigoATR: codigoATR,
            sumPotencia: sumPotencia,
            sumEnergia: sumEnergia,
            iePercent: iePercent,
            impuestoElectrico: impuestoElectrico,
            iva: iva,
            ivaPorcentaje: ivaPorcentaje,
            bi: bi,
            total: total,
            penalizacionPotencia: penalizacionPotencia,
            penalizacionEnergia: penalizacionEnergia,
            numDaysNew: numDaysNew,
            numMonthNew: numMonthNew,
            fechaDesdeConsumo: fechaDesdeConsumo,
            fechaHastaConsumo: fechaHastaConsumo,
            descuento: descuento,
            descuentoMonth: descuentoMonth,
            descuentoPotencia: descuentoPotencia,
            descuentoPotenciaMonth: descuentoPotenciaMonth
        }

    }


    private roundNumber( number: number )
    {
        return (Math.round(number * 100) / 100)
    }


    private percentage(num, per)
    {
      return (num/100)*per;
    }

    public renderFacturaProducto = () => {

       //const producto:any = this.state.producto
       let valorlongitud= Object.keys(this.state.producto).length
       let newfacturaTabla:any[] = [];
       let newAhorroTabla:any[] = [];
       if (this.state && valorlongitud > 0){

       Object.keys(this.state.producto).map ( (item, i) => {

        let factura: any = this.calculatePrecioProducto( this.state.periods, this.state.producto[item], this.state.formMulti );
      // console.log('FACTURA en RENDERFACTURAPRODUCTO')
        // console.log(factura)
        newfacturaTabla.push(factura)
        let ahorro: any = this.calculateAhorro( this.state.periods, this.state.formActual, factura );
        newAhorroTabla.push(ahorro)
        return item;
    });

    //formMultiTabla = newFacturaTabla;
    //inputState.itemsTabla = newItemsTabla;

    this.setState({
        formMulti:newfacturaTabla,
        totalComparacion:newAhorroTabla
        }
    ,this.stateOutput)
  }

}

    public stateOutput()
    {
       let { formActual,
        formMultiProducto, producto, itemsTabla, costeOperativo, costeOperativoPotencia, totalComparacion, periods, esNuevoContrato } = this.state;
        // console.log("map",this.state);
        let totalComp;
        let periodos;
        let save: boolean = false;
        if(this.state.productSelected !== null){
            // console.log("map dentro del this.state.productselect ",this.state);
            //if para asegurar de pasar un solo producto: el seleccionado
            producto = Object.values(this.state.producto).find((item) => item.id === this.state.productSelected[0]);
            // console.log("map valor de producto", producto)
            if(producto !== undefined) {
            // console.log('Entra en prodducto')
            let index:string = Object.keys(this.state.producto).find(key => this.state.producto[key] === producto)!;
            totalComp = this.state.totalComparacion[parseInt(index)];
            //delete totalComp.datosAhorro;
            save = true;
          }
            //periodos
            periodos = itemsTabla.filter(item => {
                if(item.id === this.state.productSelected[0]){
                    return item
                }
            });
            formMultiProducto = Object.values(this.state.formMulti).find((item) => item.id === this.state.productSelected[0]);
        }

        this.props.output(
            {
                actual: {
                  datos: formActual,
                  datosAdicionales:{
                        serviciosAdicionalesMonth: formActual.serviciosAdicionalesMonth,
                        serviciosAdicionales: formActual.serviciosAdicionales,
                        alquilerEquipoMedida:formActual.alquilerEquipoMedida,
                        codigoATR:formActual.codigoATR,
                        sumPotencia:formActual.sumPotencia,
                        sumEnergia:formActual.sumEnergia,
                        iePercent:formActual.iePercent,
                        impuestoElectrico:formActual.impuestoElectrico,
                        iva:formActual.iva,
                        ivaPorcentaje: formActual.ivaPorcentaje,
                        bi:formActual.bi,
                        total:formActual.total,
                        penalizacionPotencia:formActual.penalizacionPotencia,
                        penalizacionEnergia:formActual.penalizacionEnergia,
                    }
              },
                producto: {
                    datos: formMultiProducto,
                    producto: producto,//Object.values(this.state.producto).find((item) => item.id === this.state.productSelected[0]),
                    itemsPeriodos: periodos,
                    periodos: periods,
                    costeOperativo: costeOperativo,
                    costeOperativoPotencia: costeOperativoPotencia,
                    esNuevoContrato: esNuevoContrato,
                    totalComparacion: totalComp,
              },
               comparativaGlobal:{...this.state.totalComparacion},
              enabledSave: save
            }
        );
    }

    public renderFacturaActual = ( ) => {

        let factura: any = this.calculatePrecioActual( this.state.periods, this.state.formActual );

        let valorlongitud= Object.keys(this.state.producto).length
        let valorlongitudMulti = Object.keys(this.state.formMulti).length
         console.log('VALOR LONGITUD en renderFacturaActual----> ', valorlongitud)
         console.log('VALOR LONGITUDMulti en renderFacturaActual----> ', valorlongitudMulti)
        console.log('VALOR DE this.state.formMulti ---------->', this.state.formMulti)
        let newAhorroTabla:any[] = [];
         console.log("prova",this.state,valorlongitud,valorlongitudMulti);
        if (this.state && valorlongitud > 0 && valorlongitudMulti !== 68){
            console.log('renderFacturaActual ----> dentro de this.state.producto.length > 0')
            Object.keys(this.state.formMulti).map ( (item, i) => {
                console.log('VALOR DE ROW en renderFacturaActual---->', this.state.formMulti[item])

                let ahorro: any = this.calculateAhorroMulti( this.state.periods, this.state.formActual, this.state.formMulti[item] );
                newAhorroTabla.push(ahorro)
          return item;
     });

      let codigoATR:any = this.state.sip.codigoTarifaATREnVigor;
        // // console.log('ESTOY DENTRO DE RENDERFACTURALACTUAL')
        // // console.log(this.stateOutput)
        // // console.log(this.props)

        this.setState({
            formActual: {
                ...this.state.formActual,
                serviciosAdicionales: factura.serviciosAdicionales,
                serviciosAdicionalesMonth: factura.serviciosAdicionalesMonth,
                alquilerEquipoMedida: factura.alquilerEquipoMedida,
                alquilerEquipoMedidaMonth: factura.alquilerEquipoMedidaMonth,
                codigoATR: codigoATR,
                sumPotencia: factura.sumPotencia,
                sumEnergia: factura.sumEnergia,
                iePercent: factura.iePercent,
                impuestoElectrico: factura.impuestoElectrico,
                iva: factura.iva,
                ivaPorcentaje: factura.ivaPorcentaje,
                bi: factura.bi,
                total: factura.total,
                penalizacionPotencia: factura.penalizacionPotencia,
                penalizacionEnergia: factura.penalizacionEnergia,
                numDaysNew: factura.numDaysNew,
                numMonthNew: factura.numMonthNew,
                fechaDesdeConsumo: factura.fechaDesdeConsumo,
                fechaHastaConsumo: factura.fechaHastaConsumo,
                descuento: factura.descuento,
                descuentoMonth: factura.descuentoMonth,
                descuentoPotencia: factura.descuentoPotencia,
                descuentoPotenciaMonth: factura.descuentoPotenciaMonth
            },
           totalComparacion:newAhorroTabla
//                ...ahorro
//            }
        },this.stateOutput);

    }
  }

  onPieEnter = (_, index) => {
     // console.log('Entra en onPieEnter ---> ', index)
     this.setState({
       activeIndex: index,
     });
   };

    private onChangeField = async ( event ) => {
event.persist()
        const name = event.target.name;
        let value = event.target.value;

        let inputs = {};
        if ( value !== "" || value !== null)
        {
            inputs[name] = Number(value);
            return inputs;

        }
        return false;
    }

    selectedItem = async (event) => {
        // console.log("itemSelected",event);
        /*this.setState({
          productSelected: event,
        });
        let outputData = { ...this.state.form };

*/
        let outputData = { ...this.stateOutput};
        outputData['enabledSave'] = true;

/*        this.setState(
        ({ productSelected }) => ({
          productSelected:event
        }), () => {
          this.setState(({ productSelected }) => ({
            productSelected:event
          }))
        },this.stateOutput  )
*/
        this.setState({ productSelected:event},this.stateOutput  )


      };


      //handleChange = (selectedOption) => {
      handleChange = (e) => {
    const valor = e.value;
    this.setState({ selectedOption: valor  }, () =>
       console.log(`Option selected:`, this.state.selectedOption)
    );
    this.setState({
        formActual: {
            ...this.state.formActual,
            ...{ivaPorcentaje:valor}
        }
    }, this.renderFacturaActual );
}

  //      this.setState({ selectedOption }, () =>
    //      // console.log(`Option selected:`, this.state.selectedOption)
    //    );
  //    };



  tipoContrato= (props) => {
      const tipoTarifa = props.tipoTarifa;
      var form: any = this.state.formDatosContrato;
      const consumosMaxMin = this.state.consumosMaxMin

      let arr = Object.values(consumosMaxMin.min);
      let maxOfMin = Math.max(...arr);

      let arr2 = Object.values(consumosMaxMin.max);
      let maxOfMax = Math.max(...arr2);

      const getBackgroundColor = (value) => {

          let color;
          if (value >= maxOfMax) {
              color = '#82be4e'}
          else{
              color = '';
          }
          return color;
      };

      const getBackgroundColorMin = (value) => {

          let color;
          if (value >= maxOfMin) {
              color = '#82be4e'}
          else{
              color = '';
          }
          return color;
      };


      if (tipoTarifa === '018') {
          return (
              <div>
                  <Row>
                      <Col md="2">
                          <FormGroup>

                              <Label className="form-control-label" htmlFor="pp1">
                                  POTENCIA P1
                              </Label>
                              <Input
                                  id="potenciaContratoP1"
                                  placeholder=""
                                  step="0.5"
                                  name="potenciaContratoP1"
                                  onChange={this.onChangeFieldformMultiProductoPotencia}
                                  value={form.potenciaContratoP1||''}
                                  type="number"
                                  required
                              />
                          </FormGroup>
                      </Col>
              <Col md="2">
                      <FormGroup>

                          <Label className="form-control-label" htmlFor="pp2">
                              POTENCIA P2
                          </Label>
                          <Input
                              id="potenciaContratoP2"
                              placeholder=""
                              step="0.5"
                              name="potenciaContratoP2"
                              onChange={this.onChangeFieldformMultiProductoPotencia}
                              value={form.potenciaContratoP2||''}
                              type="number"
                              required
                          />
                      </FormGroup>
                  </Col>


                  </Row>
              </div>);
          } else {
          return (
              <div>
                  <Row>
                      <Col md="2">
                          <FormGroup>

                              <Label className="form-control-label" htmlFor="pp1">
                                  POTENCIA P1
                              </Label>
                              <Input
                                  id="potenciaContratoP1"
                                  placeholder=""
                                  name="potenciaContratoP1"
                                  step="0.5"
                                  onChange={this.onChangeFieldformMultiProductoPotencia}
                                  value={form.potenciaContratoP1||''}
                                  type="number"
                                  required
                              />

                              <Label className="form-control-label" htmlFor="ppc1">
                                  POT. MAX CONSUMO P1
                              </Label>

                              <div className="p-2 bg-secondary">
                                  <h5 style={{textAlign: "center", backgroundColor: getBackgroundColor(consumosMaxMin.max.P1)}} className="form-control-alternative">{consumosMaxMin.max.P1||0}</h5>
                              </div>


                              <Label className="form-control-label" htmlFor="ppcmin1">
                                  POT. MIN CONSUMO P1
                              </Label>

                              <div className="p-2 bg-secondary">
                                  <h5 style={{textAlign: "center", backgroundColor: getBackgroundColorMin(consumosMaxMin.min.P1)}} className="form-control-alternative">{consumosMaxMin.min.P1||0}</h5>
                              </div>
                          </FormGroup>
                      </Col>

                  <Col md="2">
                  <FormGroup>
                          <Label className="form-control-label" htmlFor="pp2">
                              POTENCIA P2
                          </Label>
                          <Input
                              id="potenciaContratoP2"
                              placeholder=""
                              step="0.5"
                              name="potenciaContratoP2"
                              onChange={this.onChangeFieldformMultiProductoPotencia}
                              value={form.potenciaContratoP2||''}
                              type="number"
                              required
                          />

                          <Label className="form-control-label" htmlFor="ppc2">
                              POT. MAX CONSUMO P2
                          </Label>
                          <div className="p-2 bg-secondary">
                              <h5 style={{textAlign: "center", backgroundColor: getBackgroundColor(consumosMaxMin.max.P2)}} className="form-control-alternative">{consumosMaxMin.max.P2||0}</h5>
                          </div>


                          <Label className="form-control-label" htmlFor="ppcmin2">
                              POT. MIN CONSUMO P2
                          </Label>

                          <div className="p-2 bg-secondary">
                              <h5 style={{textAlign: "center", backgroundColor: getBackgroundColorMin(consumosMaxMin.min.P2)}} className="form-control-alternative">{consumosMaxMin.min.P2||0}</h5>
                          </div>


                      </FormGroup>
                  </Col>

  <Col md="2">
                  <FormGroup>

                      <Label className="form-control-label" htmlFor="pp3">
                          POTENCIA P3
                      </Label>
                      <Input
                          id="potenciaContratoP3"
                          placeholder=""
                          step="0.5"
                          name="potenciaContratoP3"
                          onChange={this.onChangeFieldformMultiProductoPotencia}
                          value={form.potenciaContratoP3||''}
                          type="number"
                          required
                      />

                      <Label className="form-control-label" htmlFor="ppc3">
                          POT. MAX CONSUMO P3
                      </Label>
                      <div className="p-2 bg-secondary">
                          <h5 style={{textAlign: "center", backgroundColor: getBackgroundColor(consumosMaxMin.max.P3)}} className="form-control-alternative">{consumosMaxMin.max.P3||0}</h5>
                      </div>


                      <Label className="form-control-label" htmlFor="ppcmin3">
                          POT. MIN CONSUMO P3
                      </Label>

                      <div className="p-2 bg-secondary">
                          <h5 style={{textAlign: "center", backgroundColor: getBackgroundColorMin(consumosMaxMin.min.P3)}} className="form-control-alternative">{consumosMaxMin.min.P3||0}</h5>
                      </div>

                  </FormGroup>
              </Col>
  <Col md="2">
              <FormGroup>

                  <Label className="form-control-label" htmlFor="pp4">
                      POTENCIA P4
                  </Label>
                  <Input
                      id="potenciaContratoP4"
                      placeholder=""
                      step="0.5"
                      name="potenciaContratoP4"
                      onChange={this.onChangeFieldformMultiProductoPotencia}
                      value={form.potenciaContratoP4||''}
                      type="number"
                      required
                  />

                  <Label className="form-control-label" htmlFor="ppc4">
                      POT. MAX CONSUMO P4
                  </Label>
                  <div className="p-2 bg-secondary">
                      <h5 style={{textAlign: "center", backgroundColor: getBackgroundColor(consumosMaxMin.max.P4)}} className="form-control-alternative">{consumosMaxMin.max.P4||0}</h5>
                  </div>


                  <Label className="form-control-label" htmlFor="ppcmin4">
                      POT. MIN CONSUMO P4
                  </Label>

                  <div className="p-2 bg-secondary">
                      <h5 style={{textAlign: "center",  backgroundColor: getBackgroundColorMin(consumosMaxMin.min.P4)}} className="form-control-alternative">{consumosMaxMin.min.P4||0}</h5>
                  </div>

              </FormGroup>
          </Col>
          <Col md="2">
                      <FormGroup>

                          <Label className="form-control-label" htmlFor="pp5">
                              POTENCIA P5
                          </Label>
                          <Input
                              id="potenciaContratoP5"
                              placeholder=""
                              step="0.5"
                              name="potenciaContratoP5"
                              onChange={this.onChangeFieldformMultiProductoPotencia}
                              value={form.potenciaContratoP5||''}
                              type="number"
                              required
                          />

                          <Label className="form-control-label" htmlFor="ppc5">
                              POT. MAX CONSUMO P5
                          </Label>
                          <div className="p-2 bg-secondary">
                              <h5 style={{textAlign: "center", backgroundColor: getBackgroundColor(consumosMaxMin.max.P5)}} className="form-control-alternative">{consumosMaxMin.max.P5||0}</h5>
                          </div>


                          <Label className="form-control-label" htmlFor="ppcmin5">
                              POT. MIN CONSUMO P5
                          </Label>

                          <div className="p-2 bg-secondary">
                              <h5 style={{textAlign: "center",  backgroundColor: getBackgroundColorMin(consumosMaxMin.min.P5)}} className="form-control-alternative">{consumosMaxMin.min.P5||0}</h5>
                          </div>

                      </FormGroup>
                  </Col>

                  <Col md="2">
                              <FormGroup>

                                  <Label className="form-control-label" htmlFor="pp6">
                                      POTENCIA P6
                                  </Label>
                                  <Input
                                      id="potenciaContratoP6"
                                      placeholder=""
                                      step="0.5"
                                      name="potenciaContratoP6"
                                      onChange={this.onChangeFieldformMultiProductoPotencia}
                                      value={form.potenciaContratoP6||''}
                                      type="number"
                                      required
                                  />

                                  <Label className="form-control-label" htmlFor="ppc6">
                                      POT. MAX CONSUMO P6
                                  </Label>
                                  <div className="p-2 bg-secondary">
                                      <h5 style={{textAlign: "center", backgroundColor: getBackgroundColor(consumosMaxMin.max.P6)}} className="form-control-alternative">{consumosMaxMin.max.P6||0}</h5>
                                  </div>


                                  <Label className="form-control-label" htmlFor="ppcmin6">
                                      POT. MIN CONSUMO P6
                                  </Label>

                                  <div className="p-2 bg-secondary">
                                      <h5 style={{textAlign: "center", backgroundColor: getBackgroundColorMin(consumosMaxMin.min.P6)}} className="form-control-alternative">{consumosMaxMin.min.P6||0}</h5>
                                  </div>

                              </FormGroup>
                          </Col>

                  </Row>

              </div>)
          }
          }





	render() {

        const itemsTabla = this.state.itemsTabla;
        //const precioServicio = this.state.precioServicio;
        const totalComparacion = this.state.totalComparacion;
        const producto = this.state.producto;
        const formActual = this.state.formActual;
        const bie = this.state.formDatosContrato.bie
        var tarifaATRProducto ='';
        //// console.log("STATE",this.state);
        let penalizacionPotenciaActual = this.state.penalizacionPotenciaCalculo||0;
        let penalizacionReactivaCalculo = this.state.penalizacionReactivaCalculo||0;


let valorlongitud= Object.keys(this.state.periods).length


var multiFields:any={}
if (valorlongitud <6){
  //// console.log('entra en multifields de 3P')
         multiFields = {
            oferta:'Oferta',
            comisionFirma: 'Comisión Firma',
            comisionConsumo: 'Comisión Consumo',
            P1potencia: 'P1 Potencia',
            P2potencia: 'P2 Potencia',
            sumPotencia: 'Suma potencia',
            P1energia: 'P1 Energia',
            P2energia: 'P2 Energia',
            P3energia: 'P3 Energia',
            sumEnergia: 'Suma Energia',
            ahorroAnual:'Ahorro Anual',
            ahorroPercent:'Ahorro Percent',
            ahorroPeriodo:'Ahorro Periodo'
        }
      }else{
//// console.log('entra en multifield de 6P')
         multiFields = {
            oferta:'Oferta',
            comisionFirma: 'Comisión Firma',
            comisionConsumo: 'Comisión Consumo',
            P1potencia: 'P1 Potencia',
            P2potencia: 'P2 Potencia',
            P3potencia: 'P3 Potencia',
            P4potencia: 'P4 Potencia',
            P5potencia: 'P5 Potencia',
            P6potencia: 'P6 Potencia',
            sumPotencia: 'Suma potencia',
            P1energia: 'P1 Energia',
            P2energia: 'P2 Energia',
            P3energia: 'P3 Energia',
            P4energia: 'P4 Energia',
            P5energia: 'P5 Energia',
            P6energia: 'P6 Energia',
            sumEnergia: 'Suma Energia',
            ahorroAnual:'Ahorro Anual',
            ahorroPercent:'Ahorro Percent',
            ahorroPeriodo:'Ahorro Periodo'
        }
      }

        ///// console.log("DAYS",this.state.formMulti);
        //// console.log('VALOR DE valorlongitud ---------->',valorlongitud)

var multiTableData:any =[];
        if (valorlongitud <6){

        multiTableData = this.state.totalComparacion.map((row, index) => {
            return {
                comercializadora: this.state.producto[index].idComercializadora,
                oferta: this.state.producto[index].nombreOferta,
                comisionFirma: this.state.formMulti[index]? this.state.formMulti[index].comisionContratoFija+' €/Año' : 0,
                comisionConsumo: this.state.formMulti[index]? this.state.formMulti[index].comisionContratoConsumoAgente1+' €/Año'  : 0,
                id: this.state.producto[index].id,
                P1potencia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P1').precioPotencia,
                P1energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P1').precioEnergia,
                P2potencia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P2').precioPotencia,
                P2energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P2').precioEnergia,
                P3energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P3').precioEnergia,
                ahorroAnualNum:row['ahorroAnual'],
                ahorroAnual: row['ahorroAnual']+' €',
                ahorroPercent: row['ahorroPercent'] + ' %',
                ahorroPeriodo: this.state.formActual? (row['ahorroPeriodo']*(this.state.formActual.numDaysNew/365)).toFixed(2)+' €' : null,
                sumPotencia: this.state.formMulti[index]? this.state.formMulti[index].sumPotencia : null,
                sumEnergia: this.state.formMulti[index]? this.state.formMulti[index].sumEnergia : null,
            }
        });
      }else{
        //// console.log('entra en multitabla de 6P')
         multiTableData = this.state.totalComparacion.map((row, index) => {
            return {
                comercializadora: this.state.producto[index].idComercializadora,
                oferta: this.state.producto[index].nombreOferta,
                comisionFirma: this.state.formMulti[index]? this.state.formMulti[index].comisionContratoFija+' €/Año' : 0,
                comisionConsumo: this.state.formMulti[index]? this.state.formMulti[index].comisionContratoConsumoAgente1+' €/Año'  : 0,
                id: this.state.producto[index].id,
                P1potencia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P1').precioPotencia,
                P1energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P1').precioEnergia,
                P2potencia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P2').precioPotencia,
                P2energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P2').precioEnergia,
                P3potencia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P3').precioPotencia,
                P3energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P3').precioEnergia,
                P4potencia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P4').precioPotencia,
                P4energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P4').precioEnergia,
                P5potencia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P5').precioPotencia,
                P5energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P5').precioEnergia,
                P6potencia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P6').precioPotencia,
                P6energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P6').precioEnergia,
                ahorroAnualNum:row['ahorroAnual'],
                ahorroAnual: row['ahorroAnual']+' €',
                ahorroPercent: row['ahorroPercent'] + ' %',
                ahorroPeriodo: this.state.formActual? (row['ahorroPeriodo']*(this.state.formActual.numDaysNew/365)).toFixed(2)+' €' : null,
                sumPotencia: this.state.formMulti[index]? this.state.formMulti[index].sumPotencia : null,
                sumEnergia: this.state.formMulti[index]? this.state.formMulti[index].sumEnergia : null,
            }
        });

      }
        console.log("multiTableData",multiTableData);
        //multiTableData.sort((a, b) => parseFloat(b.ahorroAnual) - parseFloat(a.ahorroAnual));
        multiTableData.sort((a, b) => parseFloat(b.comisionConsumo) - parseFloat(a.comisionConsumo));
        var datosConsumosPotencia:any=[]
        var datosConsumosEnergia:any=[]

    for( let i=1; i<=valorlongitud; i++ ){
    let consumoAnual = `ConsumoAnualActivaP${i}`
    if(this.state.sip[consumoAnual] >= 0){
        let periodoP = `P${i}`;
        let valor = 0;
        if(i==1){
        valor = this.state.sip.potenciasContratadasEnWP1
        } else if (i==2){
            valor = this.state.sip.potenciasContratadasEnWP2
            } else if (i==3){
                valor = this.state.sip.potenciasContratadasEnWP3
                } else if (i==4){
                    valor = this.state.sip.potenciasContratadasEnWP4
                    } else if (i==5){
                        valor = this.state.sip.potenciasContratadasEnWP5
                        } else if (i==6){
                            valor = this.state.sip.potenciasContratadasEnWP6
                            }


        datosConsumosPotencia.push({ periodo:periodoP, potencia: valor});
        // // console.log(newItemsTabla)
      }
    }




    for( let i=1; i<=valorlongitud; i++ ){

        let periodoPE = `P${i}`;
        let valor = 0;

        this.state.itemsConsumo.map( row => {
            //consumoPotencia[`P${periodo}`] += Number(row[`potenciaDemandadaEnWP${periodo}`]) || 0;
            //consumoEnergia[`P${periodo}`] += (Number(row[`consumoEnergiaActivaEnWhP${periodo}`]) || 0) + (Number(row[`consumoEnergiaReactivaEnVArhP${periodo}`]) || 0);
            valor += (Number(row[`consumoEnergiaActivaEnWhP${i}`]) || 0)

            return row;

        });

        datosConsumosEnergia.push({ periodo:periodoPE, energia: valor});

};

        //if (this.state.sip.NumeroPeriodos==="3"){
        //var periodos = [1,2,3]} else {
        //// console.log('VALOR POTENCIA 1 ',datosConsumosPotencia)
        //// console.log('VALOR ENERGIA 2 ',datosConsumosEnergia)
        var periodos = this.state.periods;
      //};
const { selectedOption } = this.state;
		return (
			<>
            <Row className="margin-reset w-100">
                    <Card className="mt-3 mb-3 shadow card-billing-template">
                    <CardBody>
                        <Row className="">
                            <h2 className="col item-header">
                                Propuesta Contrato.
                            </h2>
                        </Row>


                        <this.tipoContrato tipoTarifa={this.state.formDatosContrato.tarifaATR} />


                        <Row className="">


                                <Col md="2">
                                            <FormGroup>


                                                <Label className="form-control-label" htmlFor="cp1">
                                                    Penalización Potencia
                                                </Label>
                                                <div className="p-2 bg-secondary">
                                                    <h5 style={{textAlign: "center", backgroundColor: "white"}} className="form-control-alternative">{penalizacionPotenciaActual||0} €</h5>
                                                </div>


                                            </FormGroup>
                                        </Col>

                                        <Col md="2">
                                                    <FormGroup>


                                                        <Label className="form-control-label" htmlFor="cp2">
                                                            Penalización Reactiva
                                                        </Label>
                                                        <div className="p-2 bg-secondary">
                                                            <h5 style={{textAlign: "center", backgroundColor: "white"}} className="form-control-alternative">{penalizacionReactivaCalculo||0} €</h5>
                                                        </div>


                                                    </FormGroup>
                                                </Col>

                                                <Col md="2">
                                                            <FormGroup>


                                                                <Label className="form-control-label" htmlFor="cp2">
                                                                    Valor Max. BIE
                                                                </Label>
                                                                <div className="p-2 bg-secondary">
                                                                    <h5 style={{textAlign: "center", backgroundColor: "white"}} className="form-control-alternative">{bie||0}</h5>
                                                                </div>


                                                            </FormGroup>
                                                        </Col>


                        </Row>

                        <Row className="">
                            <h2 className="col item-header">
                                Ahorros por cada uno de los productos seleccionados
                            </h2>
                        </Row>

                        <SipsTable
                            checkBoxButton={true}
                            hideViewMoreButton={true}
                            items={multiTableData}
                            fields={multiFields}
                            selectedItem={this.selectedItem}
                            expandableFields={false}
                            showFreeMarketButton={true}
                        ></SipsTable>
                    </CardBody>
                    </Card>

                </Row>

                <Row className="margin-reset w-100">
                    <Card className="mt-3 mb-3 shadow card-billing-template">
<ResponsiveContainer width="100%" height="100%">

                        <BarChart
                          width={700}
                          height={300}
                          data={multiTableData}
                          margin={{
                            top:40,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="oferta"  />
                           <YAxis label={{ value: 'Importe en €', angle: -90, position: 'insideLeft', textAnchor: 'middle' }} />
                          <Tooltip />
                          <Legend />

                        <ReferenceLine y={0} stroke="#000" />
                        <Bar dataKey="sumEnergia" fill="#0088fe" legendType = "none" name = 'Importe Energía' />
                          <Bar dataKey="sumPotencia" fill="#f65cb5" legendType = "none" name = 'Importe Potencia' />
                            <Bar dataKey="ahorroAnualNum" fill="#ff8042" legendType = "none" name = 'Importe Ahorro Anual'  /></BarChart>
</ResponsiveContainer>

</Card>

    <Card className="mt-3 mb-3 shadow card-billing-template">
<ResponsiveContainer aspect= {2}>
                <PieChart width={400} height={400}>
                         <Pie
                           activeIndex={this.state.activeIndex}
                           activeShape={renderActiveShape}
                           data={datosConsumosEnergia}
                           cx="50%"
                           cy="50%"
                           innerRadius={60}
                           outerRadius={80}
                           fill="#8884d8"
                           dataKey="energia"
                           onMouseEnter={this.onPieEnter}
                         />
                       </PieChart>


</ResponsiveContainer>




</Card>
</Row>


                <Row className="margin-reset w-100">
                    <Card className="mt-3 mb-3 shadow card-billing-template">
                        <Form innerRef={this.formActualRef}>
                        <CardBody>
                            <Row className="">
                                <h2 className="col item-header">
                                    Simulación factura comercializadora actual
                                </h2>
                            </Row>
                            <Row>
                            <Table className="table-actual align-items-center table-flush" responsive key="table-data">
                                <thead>
                                    <th>Periodo Lectura</th>
                                    <th>Precio potencia €/kW día</th>
                                    <th>Precio Energía €/kWh</th>
                                </thead>
                                <tbody>
                                    {periodos.includes(1)?
                                    <tr>
                                        <td>P1</td>
                                        <td>
                                            <div className="input-control">
                                                <Input type="number" step="0.01" id="precioTP1" name="precioTP1" onChange={this.onChangeFieldFormActual} value={formActual.precioTP1}/>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="input-control">
                                                <Input type="number" step="0.01" id="precioTEP1" name="precioTEP1" onChange={this.onChangeFieldFormActual} value={formActual.precioTEP1}/>
                                            </div>
                                        </td>
                                    </tr>
                                    :''}
                                    {periodos.includes(2)?
                                    <tr>
                                        <td>P2</td>
                                        <td>
                                            <div className="input-control">
                                                <Input type="number" step="0.01" id="precioTP2" name="precioTP2" onChange={this.onChangeFieldFormActual} value={formActual.precioTP2}/>

                                            </div>
                                        </td>
                                        <td>
                                            <div className="input-control">
                                                <Input type="number" step="0.01" id="precioTEP2" name="precioTEP2" onChange={this.onChangeFieldFormActual} value={formActual.precioTEP2}/>

                                            </div>
                                        </td>
                                    </tr>
                                    :''}
                                    {periodos.includes(3)?
                                    <tr>
                                        <td>P3</td>
                                        <td>
                                            <div className="input-control">
                                                <Input type="number" step="0.01" id="precioTP3" name="precioTP3" onChange={this.onChangeFieldFormActual} value={formActual.precioTP3}/>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="input-control">
                                                <Input type="number" step="0.01" id="precioTEP3" name="precioTEP3" onChange={this.onChangeFieldFormActual} value={formActual.precioTEP3}/>

                                            </div>
                                        </td>
                                    </tr>
                                    :''}
                                    {periodos.includes(4)?
                                    <tr>
                                        <td>P4</td>
                                        <td>
                                            <div className="input-control">
                                                <Input type="number" step="0.01" id="precioTP4" name="precioTP4" onChange={this.onChangeFieldFormActual} value={formActual.precioTP4}/>

                                            </div>
                                        </td>
                                        <td>
                                            <div className="input-control">
                                                <Input type="number" step="0.01" id="precioTEP4" name="precioTEP4" onChange={this.onChangeFieldFormActual} value={formActual.precioTEP4}/>

                                            </div>
                                        </td>
                                    </tr>
                                    :''}
                                    {periodos.includes(5)?
                                    <tr>
                                        <td>P5</td>
                                        <td>
                                            <div className="input-control">
                                                <Input type="number" step="0.01" id="precioTP5" name="precioTP5" onChange={this.onChangeFieldFormActual} value={formActual.precioTP5}/>

                                            </div>
                                        </td>
                                        <td>
                                            <div className="input-control">
                                                <Input type="number" step="0.01" id="precioTEP5" name="precioTEP5" onChange={this.onChangeFieldFormActual} value={formActual.precioTEP5}/>

                                            </div>
                                        </td>
                                    </tr>
                                    :''}
                                    {periodos.includes(6)?
                                    <tr>
                                        <td>P6</td>
                                        <td>
                                            <div className="input-control">
                                                <Input type="number" step="0.01" id="precioTP6" name="precioTP6" onChange={this.onChangeFieldFormActual} value={formActual.precioTP6}/>

                                            </div>
                                        </td>
                                        <td>
                                            <div className="input-control">
                                                <Input type="number" step="0.01" id="precioTEP6" name="precioTEP6" onChange={this.onChangeFieldFormActual} value={formActual.precioTEP6}/>

                                            </div>
                                        </td>
                                    </tr>
                                    :''}
                                </tbody>
                            </Table>
                            </Row>
                            <Row className="panel-data-resolve">
                                <div className="col">
                                    <div className="item-data">
                                        <h5 className="">Término Potencia {this.state.daysOperacion} días</h5>
                                        <div className="">{formActual.sumPotencia}€</div>
                                    </div>

                                    <div className="item-data">
                                        <h5 className="">Descuento sobre consumo Potencia</h5>
                                        <div className="">
                                            <input type="number" step="1.0" name="descuentoPotenciaMonth" onChange={this.onChangeFieldFormActual} value={formActual.descuentoPotenciaMonth}></input>€

                                          </div>
                                         <h5 className="">Total</h5>
                                        <div>{formActual.descuentoPotencia}%</div>
                                        </div>


                                    <div className="item-data">
                                        <h5 className="">Descuento sobre consumo Energía</h5>
                                        <div className="">
                                            <input type="number" step="1.0" name="descuentoMonth" onChange={this.onChangeFieldFormActual} value={formActual.descuentoMonth}></input>€

                                          </div>
                                         <h5 className="">Total</h5>
                                        <div>{formActual.descuento}%</div>
                                        </div>


                                    <div className="item-data">
                                        <h5 className="">Penalización Potencia</h5>
                                        <div className="">{formActual.penalizacionPotencia}€</div>
                                    </div>
                                    <div className="item-data">
                                        <h5 className="">I.E. 5,11%</h5>
                                        <div className="">{formActual.iePercent}€</div>
                                    </div>
                                    <div className="item-data">
                                        <h5 className="">Servicios Adicionales por mes</h5>
                                        <div className="">
                                            <input type="number" step="0.01" name="serviciosAdicionalesMonth" onChange={this.onChangeFieldFormActual} value={formActual.serviciosAdicionalesMonth}></input>€

                                          </div>
                                         <h5 className="">Total Servicios</h5>
                                        <div>{formActual.serviciosAdicionales}€</div>
                                        </div>


                                <div className="item-data">
                                    <h5 className="">Impuestos </h5>
                                    <div style={{"width": '190px', "height": '20px'}}>
                                    <Select
                                      styles={customStyles}
                                      placeholder="Selecciona Imp."

                                      value={options.filter(obj => obj.value === selectedOption)}
                                      onChange={this.handleChange}
                                      options={options}

                                    />
                                  </div>
                                      <h5 className="">IVA</h5>
                                      <div className="">{formActual.iva}€</div>
                                    </div>
                                      </div>




                                <div className="col">
                                    <div className="item-data">
                                        <h5>Término Energía {this.state.daysOperacion} días</h5>
                                        <div className="">{formActual.sumEnergia}€</div>
                                    </div>

                                    <div className="item-data">
                                        <h5>Penalización Energía</h5>
                                        <div className="">{formActual.penalizacionEnergia}€</div>
                                    </div>
                                    <div className="item-data">
                                        <h5 className="">Base Impuesto Eléctrico</h5>
                                        <div className="">{formActual.impuestoElectrico}€</div>
                                    </div>
                                    <div className="item-data">
                                        <h5 className="">Alquiler del Equipo a Medida por mes</h5>
                                        <div className="">
                                        <input type="number" step="0.01" name="alquilerEquipoMedidaMonth" onChange={this.onChangeFieldFormActual} value={formActual.alquilerEquipoMedidaMonth}></input>€
                                        </div>
                                           <h5 className="">Total Alquiler</h5>
                                        <div>{formActual.alquilerEquipoMedida}€</div>
                                    </div>


                                    <div className="item-data">
                                        <h5 className="">BASE IMPONIBLE</h5>
                                        <div className="">{formActual.bi}€</div>
                                    </div>
                                </div>
                            </Row>
                            <Row className="">
                                <div className="col item-total">
                                    <h2>
                                        TOTAL
                                    </h2>
                                    <div>
                                        {formActual.total}€
                                    </div>
                            </div>
                            </Row>







                        </CardBody>
                        </Form>
                    </Card>

                </Row>



			</>
		);
	}
}
