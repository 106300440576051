import gql from 'graphql-tag';

export const LIST_DICMUNICIPIOS = gql`
query {
    listDicMunicipios{
      Cmunicipio,
      Nombre
      CPro
DescProvincia
CodAuto
DescAutonomia
    }
  }
`
